import { createFileRoute, Navigate } from '@tanstack/react-router'

export const Route = createFileRoute('/(redirects)/partner/$organization/contracts/$subscriptionId')({
  component: () => (
    <Navigate
      replace
      search
      from="/partner/$organization/contracts/$subscriptionId"
      to="/bo/contracts/$subscriptionId"
    />
  ),
})
