import type { Skin } from '../skin.js'
import avatarUrl from './orus-face-512.webp'
import { OrusHorizontalLogoImage } from './orus-horizontal-logo-image.js'
import quoteHelpBannerImageUrlEs from './quote-help-banner-image-es.webp'
import quoteHelpBannerImageUrl from './quote-help-banner-image.webp'
import { TrustpilotScore } from './trustpilot-score.js'

export const interFontFamily = 'inter'
export const interFontUrl = 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap'

export const defaultSkin: Skin = {
  avatarUrl,
  avatarBackgroundColor: 'var(--skin-jasmine-dark)',
  quoteHelpBannerImageUrl: {
    fr: quoteHelpBannerImageUrl,
    es: quoteHelpBannerImageUrlEs,
  },
  showIllustrations: true,
  horizontalLogo: {
    aspectRatio: 143 / 44,
    ImageComponent: OrusHorizontalLogoImage,
  },
  showByOrusInHeader: false,
  TrustComponent: TrustpilotScore,
  forcedColors: null,
  stripe: {
    fontFamily: interFontFamily,
    fontFamilyUrl: interFontUrl,
  },
  additionalStylesheets: [],
}

export const jsonTreeTheme = {
  scheme: 'Github',
  author: 'Defman21',
  base00: '#ffffff',
  base01: '#f5f5f5',
  base02: '#c8c8fa',
  base03: '#969896',
  base04: '#e8e8e8',
  base05: '#333333',
  base06: '#ffffff',
  base07: '#ffffff',
  base08: '#ed6a43',
  base09: '#0086b3',
  base0A: '#795da3',
  base0B: '#183691',
  base0C: '#183691',
  base0D: '#795da3',
  base0E: '#a71d5d',
  base0F: '#333333',
}
