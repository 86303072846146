import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { createLink, Link } from '@tanstack/react-router'
import { memo, type MouseEvent, type ReactNode } from 'react'
import { mobileMediaQuery } from '../../../code-only/hooks'
import { Avatar } from '../../../components'
import { borderRadius, borderStroke, spacing } from '../../../foundations'
import { colorTokens } from '../../../foundations/color-tokens.js'
import { Text } from '../../../foundations/text'

export const RowContainerV2 = styled.div<{ variant?: 'mobile' | 'desktop'; noContainedBorder?: boolean }>`
  ${({ noContainedBorder }) =>
    noContainedBorder === true
      ? `
        `
      : `
        border: ${borderStroke[20]} solid ${colorTokens['color-stroke-base-main']};
  border-radius: ${borderRadius['20']};
        `}

  display: flex;
  flex-direction: column;
  background-color: ${colorTokens['color-bg-base-normal']};

  /* prevent hover effect on first and last lines to overflow outside the rounded corner */
  overflow: hidden;

  ${({ variant }) =>
    variant === 'mobile'
      ? `
      ${mobileMediaQuery} {
        border-top: ${borderStroke[20]} solid ${colorTokens['color-stroke-base-main']};
        border-bottom: ${borderStroke[20]} solid ${colorTokens['color-stroke-base-main']};
        border-radius: 0px;
        padding-left: ${spacing[60]};
        padding-right: ${spacing[60]};
        display: flex;
        flex-direction: column;
        background-color: ${colorTokens['color-bg-base-normal']};
        /* prevent hover effect on first and last lines to overflow outside the rounded corner */
        overflow: hidden;
      }
        `
      : ``}
`

type RowButtonV2Props = {
  primaryText?: string | undefined
  secondaryText?: string | undefined
  tertiaryText?: string | undefined
  onClick?: ((event: MouseEvent<HTMLButtonElement>) => void) | undefined
  value?: string | undefined
  avatarLeft?: ReactNode | undefined
  avatarRight?: ReactNode | undefined
  isSelected?: boolean | undefined
  href?: string | undefined
  chip?: ReactNode | undefined
  ariaLabel?: string | undefined
}

export const RowButtonV2 = memo<RowButtonV2Props>(function RowButtonV2(props) {
  const {
    primaryText,
    secondaryText,
    tertiaryText,
    onClick,
    value,
    avatarLeft,
    avatarRight,
    isSelected = false,
    chip,
    ariaLabel,
  } = props

  const content = (
    <div
      css={css`
        display: grid;
        align-items: center;
        gap: ${spacing[50]};
        color: ${colorTokens['color-text-base-main']};
        grid-template-columns: ${(avatarLeft ? 'min-content' : '') +
        ' auto ' +
        (chip ? 'min-content ' : '') +
        (avatarRight ? 'min-content ' : '') +
        'min-content'};
      `}
    >
      {avatarLeft ?? undefined}
      <span
        css={css`
          align-items: center;
        `}
      >
        {primaryText ? (
          <Text className="mostImportantText" variant="body2Medium">
            {primaryText}
          </Text>
        ) : (
          <></>
        )}
        {secondaryText ? (
          <Text className={!primaryText ? 'mostImportantText' : undefined} variant="body2">
            {secondaryText}
          </Text>
        ) : (
          <></>
        )}
        {tertiaryText ? (
          <Text className={!primaryText && !secondaryText ? 'mostImportantText' : undefined} variant="body2">
            {tertiaryText}
          </Text>
        ) : (
          <></>
        )}
      </span>
      {chip ?? undefined}
      {avatarRight ?? undefined}
      <Avatar icon="angle-right-regular" size="30" />
    </div>
  )

  const cssToApply = css`
    text-decoration: none;
    ${BASE_ROW_CSS};
    cursor: pointer;
    background-color: ${isSelected
      ? colorTokens['color-bg-info-secondary-active']
      : colorTokens['color-bg-base-normal']};

    &:hover {
      background-color: ${colorTokens['color-bg-base-focus']};
    }

    &:focus-visible {
      background-color: ${colorTokens['color-bg-base-focus']};
      border: ${borderStroke[20]} solid ${colorTokens['color-stroke-base-main-selected']};
      outline: none;
    }
  `

  return 'href' in props ? (
    <Link {...(props.href ? { to: props.href } : {})} css={cssToApply} key={value}>
      {content}
    </Link>
  ) : (
    <button type="button" onClick={onClick} value={value} css={cssToApply} key={value} aria-label={ariaLabel}>
      {content}
    </button>
  )
})

export const RowButtonLinkV2 = createLink(RowButtonV2)

export type RowStaticV2Props = {
  label: string
  rightNode: ReactNode
}

type RowV2Props = {
  children: ReactNode
}

export const RowV2 = memo<RowV2Props>(function RowV2({ children }) {
  return (
    <div
      css={css`
        ${BASE_ROW_CSS};
      `}
    >
      {children}
    </div>
  )
})

export const RowStaticV2 = memo<RowStaticV2Props>(function RowStaticV2({ label, rightNode }) {
  return (
    <div
      css={css`
        ${BASE_ROW_CSS};

        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <Text variant="body2Medium">{label}</Text>
      <div>{rightNode}</div>
    </div>
  )
})

const BASE_ROW_CSS = css`
  text-align: left;
  border: none;
  background: none;
  padding: ${spacing[40]} ${spacing[50]} ${spacing[40]} ${spacing[50]};

  :not(:first-child) {
    border-top: ${borderStroke[20]} solid ${colorTokens['color-stroke-base-main']};
  }
`
