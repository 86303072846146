import { type CustomContentProps } from 'notistack'

import styled from '@emotion/styled'
import { forwardRef, memo, type ReactNode } from 'react'
import { borderRadius, colorTokens, spacing } from '../../foundations'
import type { CompoundIconName } from '../../foundations/icon/names.js'
import { Avatar } from '../avatar/avatar.js'
import { colorsPerVariant, type NotificationVariant } from './notification/util'

export type ToastVariant = NotificationVariant | 'neutral'

export type ToastOrusProps = {
  orusVariant?: ToastVariant
  extra?: ReactNode | undefined
}

type ToastProps = CustomContentProps & ToastOrusProps

// notistack expects custom alerts to:
// - forward the ref
// - be a single element (no fragment)
export const Toast = memo(
  forwardRef<HTMLDivElement, ToastProps>(function Alert(props, ref) {
    // Props passed by notistack
    const { message, orusVariant = 'neutral' } = props

    return (
      <ToastContainer ref={ref}>
        {orusVariant !== 'neutral' ? (
          <Avatar
            size="30"
            icon={avatarPerVariant[orusVariant].icon}
            secondaryColor={avatarPerVariant[orusVariant].iconSecondaryColor}
            noTransparency
            color={avatarPerVariant[orusVariant].iconColor}
          />
        ) : null}
        {message}
        {props.extra}
      </ToastContainer>
    )
  }),
)

const ToastContainer = styled.div`
  display: flex;
  padding: ${spacing['50']};
  align-items: center;
  gap: ${spacing['40']};
  border-radius: ${borderRadius['20']};
  background-color: ${colorTokens['color-bg-base-inverse']};
  color: ${colorTokens['color-text-base-inverse']};
  width: max-content;
`

const avatarPerVariant: Record<
  ToastVariant,
  { icon?: CompoundIconName; iconColor?: string; iconSecondaryColor?: string }
> = {
  neutral: {},
  info: {
    icon: 'circle-info-duotone',
    iconColor: colorsPerVariant['info'].dark.accentColor,
    iconSecondaryColor: colorsPerVariant['info'].light.accentColor,
  },
  success: {
    icon: 'circle-check-duotone',
    iconColor: colorsPerVariant['success'].dark.accentColor,
    iconSecondaryColor: colorsPerVariant['success'].light.accentColor,
  },
  warning: {
    icon: 'triangle-exclamation-duotone',
    iconColor: colorsPerVariant['warning'].dark.accentColor,
    iconSecondaryColor: colorsPerVariant['warning'].light.accentColor,
  },
  danger: {
    icon: 'diamond-exclamation-duotone',
    iconColor: colorsPerVariant['danger'].dark.accentColor,
    iconSecondaryColor: colorsPerVariant['danger'].light.accentColor,
  },
}
