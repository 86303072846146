import { createFileRoute } from '@tanstack/react-router'

import PlatformInvoicingPage from '../../../../../../components/pages/backoffice/platform/platform-contract-invoicing-page'
import { PermissionChecker } from '../../../../../../components/templates/permission-checker'

export const Route = createFileRoute('/bo/contracts/$subscriptionId/invoices/debug')({
  component: () => (
    <PermissionChecker requiredPermissions={['invoices.read']}>
      <PlatformInvoicingPage />
    </PermissionChecker>
  ),
})
