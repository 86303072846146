import { createFileRoute } from '@tanstack/react-router'
import PlatformPendingRenewalPage from '../../../../../../components/pages/backoffice/platform/platform-endorsement-page/PlatformPendingRenewalPage'
import { PermissionChecker } from '../../../../../../components/templates/permission-checker'

const BackofficeRenewalPage = () => {
  const { subscriptionId } = Route.useParams()

  return (
    <PermissionChecker requiredPermissions={['contracts.sign']}>
      <PlatformPendingRenewalPage key={subscriptionId} />
    </PermissionChecker>
  )
}

export const Route = createFileRoute('/bo/contracts/$subscriptionId/renewals/$endorsementId')({
  component: () => <BackofficeRenewalPage />,
})
