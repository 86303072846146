import { css } from '@emotion/react'
import { forwardRef, memo, useContext, useMemo } from 'react'

import { useSkin } from '../../code-only/skin/hooks.js'
import { colorTokens } from '../../foundations/color-tokens.js'
import { Icon } from '../../foundations/icon/icon.js'
import { PIXELS_PER_ICON_SIZE } from '../../foundations/icon/sizes.js'
import { Illustration } from '../../foundations/illustration/illustration.js'
import { Text } from '../../foundations/index.js'
import { AvatarContext } from './context.js'
import { generateHexaColorFromName } from './generate-hexa-color.js'
import type { AvatarProps } from './props.js'

export const Avatar = memo(
  forwardRef<HTMLDivElement, AvatarProps>(function Avatar(props, ref) {
    const contextValue = useContext(AvatarContext)
    const skin = useSkin()
    let bgColor = undefined

    const {
      icon = 'circle-question-solid',
      illustration,
      secondaryColor,
      src,
      variant = 'full',
      className,
      noTransparency = false,
      overrideContextSize = false,
      name,
      emoji,
    } = props

    let {
      size,
      color,
      containerColor = colorTokens['color-bg-base-secondary-active'],
      imageBackgroundColor = 'initial',
    } = props

    size = overrideContextSize ? (size ?? contextValue.size) : (contextValue.size ?? size)

    color = contextValue.color ?? color

    if (variant === 'contained') {
      color = skin.forcedColors?.color ?? color
      imageBackgroundColor = skin.forcedColors?.backgroundColor ?? imageBackgroundColor
      containerColor = skin.forcedColors?.backgroundColor ?? containerColor
    }

    if (name) {
      bgColor = generateHexaColorFromName(name)
    }

    const cssSize = size ? PIXELS_PER_ICON_SIZE[size] : '100%'
    const shouldBeRound = !!src && variant === 'round'
    const resolvedImageNode = useMemo(() => {
      if (src) {
        return (
          <img
            src={src}
            alt=""
            css={css`
              width: 100%;
              height: 100%;

              background-color: ${imageBackgroundColor};
              border-radius: ${shouldBeRound ? '50%' : '0%'};
            `}
          />
        )
      }

      if (illustration) {
        return <Illustration name={illustration} size={size} />
      }

      return (
        <Icon
          icon={icon}
          color={color}
          secondaryColor={secondaryColor}
          noTransparency={noTransparency}
          css={css`
            width: 100%;
            height: 100%;
            display: flex;
          `}
        />
      )
    }, [src, icon, illustration, color, secondaryColor, noTransparency, imageBackgroundColor, shouldBeRound, size])

    return (
      <div
        ref={ref}
        className={className}
        css={css`
          flex-shrink: 0;
          width: ${cssSize};
          height: ${cssSize};
          display: flex;
          justify-content: center;
          align-items: center;
          ${variant === 'contained' ? `background: ${name ? bgColor : containerColor}; border-radius: 50%;` : ''}
        `}
      >
        {name ? (
          <Text variant="caption" color={colorTokens['color-text-base-main']}>
            {name.charAt(0).toUpperCase()}
          </Text>
        ) : emoji ? (
          <Text>{emoji}</Text>
        ) : (
          <div
            css={css`
              display: flex;

              ${variant === 'contained' ? `width: 50%; height: 50%;` : `width: 100%; height: 100%;`}
            `}
          >
            {resolvedImageNode}
          </div>
        )}
      </div>
    )
  }),
)
