import { createFileRoute } from '@tanstack/react-router'

import { TechnicalError } from '@orus.eu/error'
import PartnerPendingSubscriptionsPage from '../../../components/pages/backoffice/partners/partners-pending-subscriptions-page'
import PlatformPendingSubscriptionsPage from '../../../components/pages/backoffice/platform/platform-pending-subscriptions-page'
import { PermissionChecker } from '../../../components/templates/permission-checker'
import { useBackofficeUser } from '../../../lib/use-permissions'

export const Route = createFileRoute('/bo/subscriptions/')({
  component: function Component() {
    const backofficeUser = useBackofficeUser()

    if (!backofficeUser) {
      throw new TechnicalError('There should have been a backoffice user at this point')
    }

    return (
      <PermissionChecker requiredPermissions={['subscription.read']}>
        {backofficeUser.type === 'platform' ? (
          <PlatformPendingSubscriptionsPage />
        ) : (
          <PartnerPendingSubscriptionsPage organization={backofficeUser.organization} />
        )}
      </PermissionChecker>
    )
  },
})
