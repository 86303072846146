import { createFileRoute, useParams } from '@tanstack/react-router'
import PlatformPendingUserGeneratedEndorsementPage from '../../../../../../components/pages/backoffice/platform/platform-endorsement-page/PlatformPendingUserGeneratedEndorsementPage'
import { PermissionChecker } from '../../../../../../components/templates/permission-checker'

const PendingUserGeneratedEndorsementRoute = () => {
  const { subscriptionId } = useParams({
    strict: false,
  })

  return (
    <PermissionChecker requiredPermissions={['endorsement.create']}>
      <PlatformPendingUserGeneratedEndorsementPage key={subscriptionId} />
    </PermissionChecker>
  )
}

export const Route = createFileRoute('/bo/contracts/$subscriptionId/endorsements/$endorsementId/')({
  component: () => <PendingUserGeneratedEndorsementRoute />,
})
