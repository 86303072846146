import { createFileRoute } from '@tanstack/react-router'

import PlatformAdminJobsPage from '../../../../components/pages/backoffice/platform/platform-admin-jobs-page'
import { PermissionChecker } from '../../../../components/templates/permission-checker'

export const Route = createFileRoute('/bo/admin/jobs')({
  component: () => (
    <PermissionChecker requiredPermissions={['tech.configurations']}>
      <PlatformAdminJobsPage />
    </PermissionChecker>
  ),
})
