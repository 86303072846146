import { createFileRoute } from '@tanstack/react-router'
import InsuranceCertificateGeneratorRealEstateAgentsPage from '../../../../components/pages/insurance-certificate-generator/InsuranceCertificateGeneratorRealEstateAgentsPage'
import { PermissionChecker } from '../../../../components/templates/permission-checker'

export const Route = createFileRoute('/_customer/insurance-certificate-generator-real-estate-agents/$subscriptionId')({
  component: () => (
    <PermissionChecker requiredPermissions={['contracts.read']}>
      <InsuranceCertificateGeneratorRealEstateAgentsPage />
    </PermissionChecker>
  ),
})
