import { createFileRoute } from '@tanstack/react-router'

import { TechnicalError } from '@orus.eu/error'
import PartnerContractPage from '../../../../components/pages/backoffice/partners/partners-contract-page/partners-contract-page'
import PlatformContractPage from '../../../../components/pages/backoffice/platform/platform-contract-page/platform-contract-page'
import { PermissionChecker } from '../../../../components/templates/permission-checker'
import { useBackofficeUser } from '../../../../lib/use-permissions'

export const Route = createFileRoute('/bo/contracts/$subscriptionId/')({
  component: function Component() {
    const { subscriptionId } = Route.useParams()
    const backofficeUser = useBackofficeUser()

    if (!backofficeUser) {
      throw new TechnicalError('There should have been a backoffice user at this point')
    }

    return (
      <PermissionChecker requiredPermissions={['contracts.read']}>
        {backofficeUser.type === 'platform' ? (
          <PlatformContractPage />
        ) : (
          <PartnerContractPage subscriptionId={subscriptionId} organization={backofficeUser.organization} />
        )}
      </PermissionChecker>
    )
  },
})
