import { createFileRoute, Navigate } from '@tanstack/react-router'

export const Route = createFileRoute('/(redirects)/bak/contracts/$subscriptionId/renewal/$endorsementId')({
  component: () => (
    <Navigate
      replace
      search
      from="/bak/contracts/$subscriptionId/renewal/$endorsementId"
      to="/bo/contracts/$subscriptionId/renewals/$endorsementId"
    />
  ),
})
