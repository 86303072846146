import { css } from '@emotion/react'
import { forwardRef, memo, useMemo, type ReactNode, type RefAttributes } from 'react'

import styled from '@emotion/styled'
import { useUiContext } from '../../../code-only/hooks/use-screen-variant.js'
import { borderRadius, shadow, spacing } from '../../../foundations'
import { Text, TextContainer } from '../../../foundations/text'
import { Avatar } from '../../avatar/avatar.js'
import { AvatarContext } from '../../avatar/context.js'
import { Button } from '../../button'
export type ActionButton = {
  label: string
  onClick: () => void
  withAvatar?: boolean | undefined
}

export type CalloutNotificationTemplateProps = {
  leftAvatar: ReactNode
  leftAvatarSize: '30' | '40'
  rightAvatar?: ReactNode | undefined

  actionButton?: ActionButton | undefined

  backgroundColor: string
  accentColor: string
  textColor: string
  shadow?: boolean | undefined
  indented?: boolean | undefined

  title?: string | undefined

  onRightAvatarClick?: (() => void) | undefined

  children?: ReactNode | undefined
  className?: string | undefined
  // The 'forwardRef' is mandatory to use 'TemporaryNotification' as a custom component for notistack 'Snackbar'
} & RefAttributes<HTMLDivElement>

export const CalloutNotificationTemplate = memo<CalloutNotificationTemplateProps>(
  forwardRef<HTMLDivElement, CalloutNotificationTemplateProps>(function CalloutNotificationTemplate(props, ref) {
    const {
      leftAvatar,
      leftAvatarSize,
      rightAvatar,
      actionButton,
      backgroundColor,
      accentColor,
      textColor,
      shadow,
      indented,
      title,
      onRightAvatarClick,
      children,
      className,
    } = props

    const screenType = useUiContext()
    const leftAvatarContext = useMemo(
      () => ({ color: accentColor, size: leftAvatarSize }) as const,
      [accentColor, leftAvatarSize],
    )
    const rightAvatarContext = useMemo(() => ({ color: textColor, size: '30' }) as const, [textColor])

    const titleWrapper = title ? (
      <Text
        variant="body2Medium"
        css={css`
          word-wrap: break-word;
          hyphens: auto;
        `}
      >
        {title}
      </Text>
    ) : undefined

    const childrenWrapper = children ? (
      <TextContainer
        css={css`
          word-wrap: ${typeof children === 'string' ? 'break-word' : 'normal'};
          hyphens: ${typeof children === 'string' ? 'auto' : 'none'};
        `}
        variant="body2"
      >
        {typeof children === 'string' ? <Text>{children}</Text> : children}
      </TextContainer>
    ) : undefined

    return (
      <Container
        backgroundColor={backgroundColor}
        textColor={textColor}
        shadow={shadow}
        className={className}
        ref={ref}
      >
        <div
          css={css`
            display: flex;
            align-items: ${screenType === 'mobile' && !title ? undefined : 'center'};
          `}
        >
          {leftAvatar ? (
            <div
              css={css`
                flex-shrink: 0;
                padding-top: ${screenType === 'mobile' && !title ? spacing[10] : undefined};
                padding-bottom: ${screenType === 'mobile' && !title ? spacing[10] : undefined};
                margin-right: ${spacing[40]};
              `}
            >
              <AvatarContext.Provider value={leftAvatarContext}>{leftAvatar}</AvatarContext.Provider>
            </div>
          ) : null}

          <div
            css={css`
              min-width: 0;
              flex-grow: 1;
            `}
          >
            {title ? titleWrapper : childrenWrapper}
          </div>

          {rightAvatar ? (
            <div
              css={css`
                flex-shrink: 0;

                margin-left: ${spacing[40]};

                cursor: pointer;
              `}
              onClick={onRightAvatarClick}
            >
              <AvatarContext.Provider value={rightAvatarContext}>{rightAvatar}</AvatarContext.Provider>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;

            margin-left: ${indented ? '28px' : '0px'};
          `}
        >
          {title && children ? (
            <div
              css={css`
                margin-top: ${spacing[40]};
              `}
            >
              {childrenWrapper}
            </div>
          ) : (
            // when there is no title, the children are in the first row, right to the icon
            <></>
          )}

          {actionButton ? (
            <div
              css={css`
                margin-top: ${spacing[40]};
              `}
            >
              <ActionButton params={actionButton} accentColor={accentColor} />
            </div>
          ) : (
            <></>
          )}
        </div>
      </Container>
    )
  }),
)

const Container = styled.div<{ shadow?: boolean | undefined; textColor: string; backgroundColor: string }>`
  display: flex;
  flex-direction: column;

  padding: ${spacing[50]};

  color: ${(props) => props.textColor};

  background-color: ${(props) => props.backgroundColor};
  border-radius: ${borderRadius['20']};

  box-shadow: ${(props) => (props.shadow ? shadow.bottom[20] : '')};
`

const ActionButton = memo<{ params: ActionButton; accentColor: string; withAvatar?: boolean }>(
  function ActionButton(props) {
    const {
      params: { label, onClick, withAvatar = true },
      accentColor,
    } = props

    return (
      <Button
        css={css`
          color: ${accentColor};
        `}
        variant="text"
        style="decorative"
        onClick={onClick}
        avatar={withAvatar ? <Avatar icon="arrow-right-regular" size="30" color={accentColor} /> : undefined}
      >
        {label}
      </Button>
    )
  },
)
