import { createFileRoute, Navigate } from '@tanstack/react-router'

export const Route = createFileRoute('/(redirects)/bak/pending-subscriptions/$subscriptionId/history')({
  component: () => (
    <Navigate
      replace
      search
      from="/bak/pending-subscriptions/$subscriptionId/history"
      to="/bo/subscriptions/$subscriptionId/history"
    />
  ),
})
