import { createFileRoute, Navigate } from '@tanstack/react-router'

export const Route = createFileRoute('/(redirects)/bak/contracts/$subscriptionId/invoicing')({
  component: () => (
    <Navigate
      replace
      search
      from="/bak/contracts/$subscriptionId/invoicing"
      to="/bo/contracts/$subscriptionId/invoices/debug"
    />
  ),
})
