import { shadow as shadowTokensThemed } from './shadow-tokens.json'

export const shadow = Object.keys(shadowTokensThemed['light']).reduce(
  (styles, category) => ({
    ...styles,
    [category]: Object.keys(shadowTokensThemed['light']['bottom']).reduce(
      (innerStyles, variant) => ({
        ...innerStyles,
        [variant]: `var(--shadow-${category}-${variant})`,
      }),
      {},
    ),
  }),
  {} as Record<
    keyof (typeof shadowTokensThemed)['light'],
    Record<keyof (typeof shadowTokensThemed)['light']['bottom'], string>
  >,
)
