import { useCallback, useEffect, useState } from 'react'

import { useRouter } from '@tanstack/react-router'

export function useWindowLocationHash(): { hash: string; updateHash: (hash: string) => void } {
  const router = useRouter()
  const [hash, setHash] = useState(decodeURIComponent(router.state.location.hash || ''))

  const updateHash = useCallback(
    (newHash: string) => {
      const { pathname, searchStr } = router.state.location

      const formattedHash = newHash.startsWith('#') ? newHash.substring(1) : newHash

      const path = `${pathname}${searchStr}#${formattedHash}`

      router.history.replace(path)

      setHash(formattedHash)
    },
    [router],
  )

  useEffect(() => {
    setHash(decodeURIComponent(router.state.location.hash || ''))
  }, [router.state.location.hash])

  return { hash, updateHash }
}
