/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as BoImport } from './routes/bo'
import { Route as CustomerImport } from './routes/_customer'
import { Route as UnsubscribeRouteImport } from './routes/unsubscribe/route'
import { Route as SubscribeNoPossibleStepRouteImport } from './routes/subscribe-no-possible-step/route'
import { Route as SearchRouteImport } from './routes/search/route'
import { Route as ReceiveQuoteRouteImport } from './routes/receive-quote/route'
import { Route as PaymentMethodUpdateRouteImport } from './routes/payment-method-update/route'
import { Route as LoginRouteImport } from './routes/login/route'
import { Route as ImpersonateRouteImport } from './routes/impersonate/route'
import { Route as IndexImport } from './routes/index'
import { Route as BoIndexImport } from './routes/bo/index'
import { Route as SubscribeLandingRouteImport } from './routes/subscribe/landing/route'
import { Route as SubscribeExclusionSubscriptionIdRouteImport } from './routes/subscribe-exclusion/$subscriptionId/route'
import { Route as PaymentAuthenticationRouteImport } from './routes/payment/authentication/route'
import { Route as BoTrackingDocRouteImport } from './routes/bo/tracking-doc/route'
import { Route as BoSearchV2RouteImport } from './routes/bo/searchV2/route'
import { Route as BoSearchRouteImport } from './routes/bo/search/route'
import { Route as BoOrganizationsRouteImport } from './routes/bo/organizations/route'
import { Route as BoMigrationRouteImport } from './routes/bo/migration/route'
import { Route as BoMembershipsRouteImport } from './routes/bo/memberships/route'
import { Route as BoEphemeralJobsRouteImport } from './routes/bo/ephemeral-jobs/route'
import { Route as BoActivitiesRouteImport } from './routes/bo/activities/route'
import { Route as CustomerReportClaimRouteImport } from './routes/_customer/report-claim/route'
import { Route as CustomerInvoicesRouteImport } from './routes/_customer/invoices/route'
import { Route as CustomerHomeRouteImport } from './routes/_customer/home/route'
import { Route as CustomerContractsRouteImport } from './routes/_customer/contracts/route'
import { Route as CustomerContractGuaranteesRouteImport } from './routes/_customer/contract-guarantees/route'
import { Route as CustomerAccountRouteImport } from './routes/_customer/account/route'
import { Route as BoUsersIndexImport } from './routes/bo/users/index'
import { Route as BoSubscriptionsIndexImport } from './routes/bo/subscriptions/index'
import { Route as BoInvoicingIndexImport } from './routes/bo/invoicing/index'
import { Route as BoDocumentationIndexImport } from './routes/bo/documentation/index'
import { Route as BoAdminIndexImport } from './routes/bo/admin/index'
import { Route as CustomerHealthInsuranceIndexImport } from './routes/_customer/health-insurance/index'
import { Route as SubscribeRestaurantsV2IdentifyRouteImport } from './routes/subscribe/restaurants-v2/identify/route'
import { Route as SQTokenRouteImport } from './routes/s/q/$token/route'
import { Route as SEV2TokenRouteImport } from './routes/s/e-v2/$token/route'
import { Route as SCTokenRouteImport } from './routes/s/c/$token/route'
import { Route as BoUsersUserIdRouteImport } from './routes/bo/users/$userId/route'
import { Route as BoSubscriptionsNewRouteImport } from './routes/bo/subscriptions/new/route'
import { Route as BoInvoicingNegativeInvoicesRouteImport } from './routes/bo/invoicing/negative-invoices/route'
import { Route as BoInvoicingInvoiceIdRouteImport } from './routes/bo/invoicing/$invoiceId/route'
import { Route as BoDocumentationSubscriptionFunnelTextsRouteImport } from './routes/bo/documentation/subscription-funnel-texts/route'
import { Route as BoDocumentationRolesRouteImport } from './routes/bo/documentation/roles/route'
import { Route as BoDocumentationGeneralTermsRouteImport } from './routes/bo/documentation/general-terms/route'
import { Route as BoDocumentationExclusionsRouteImport } from './routes/bo/documentation/exclusions/route'
import { Route as BoDocumentationActivitySpecificQuestionsRouteImport } from './routes/bo/documentation/activity-specific-questions/route'
import { Route as BoAdminTimeRouteImport } from './routes/bo/admin/time/route'
import { Route as BoAdminJobsRouteImport } from './routes/bo/admin/jobs/route'
import { Route as BoAdminImaRouteImport } from './routes/bo/admin/ima/route'
import { Route as BoAdminCpmsRouteImport } from './routes/bo/admin/cpms/route'
import { Route as CustomerPaymentMethodsUpdateRouteImport } from './routes/_customer/payment-methods/update/route'
import { Route as CustomerPaymentMethodsNewRouteImport } from './routes/_customer/payment-methods/new/route'
import { Route as CustomerInsuranceCertificateGeneratorRealEstateSalesAgentsSubscriptionIdRouteImport } from './routes/_customer/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId/route'
import { Route as CustomerInsuranceCertificateGeneratorRealEstateAgentsSubscriptionIdRouteImport } from './routes/_customer/insurance-certificate-generator-real-estate-agents/$subscriptionId/route'
import { Route as CustomerHealthInsuranceSanteclairRouteImport } from './routes/_customer/health-insurance/santeclair/route'
import { Route as CustomerHealthInsuranceCpmsRouteImport } from './routes/_customer/health-insurance/cpms/route'
import { Route as CustomerHealthInsuranceBeneficiariesRouteImport } from './routes/_customer/health-insurance/beneficiaries/route'
import { Route as CustomerContractSubscriptionIdRouteImport } from './routes/_customer/contract/$subscriptionId/route'
import { Route as redirectsBakTrackingDocRouteImport } from './routes/(redirects)/bak/tracking-doc/route'
import { Route as redirectsBakSearchRouteImport } from './routes/(redirects)/bak/search/route'
import { Route as redirectsBakOrganizationsRouteImport } from './routes/(redirects)/bak/organizations/route'
import { Route as redirectsBakNewSubscriptionRouteImport } from './routes/(redirects)/bak/new-subscription/route'
import { Route as redirectsBakMigrationRouteImport } from './routes/(redirects)/bak/migration/route'
import { Route as redirectsBakMembershipsRouteImport } from './routes/(redirects)/bak/memberships/route'
import { Route as redirectsBakHomeRouteImport } from './routes/(redirects)/bak/home/route'
import { Route as redirectsBakEphemeralJobsRouteImport } from './routes/(redirects)/bak/ephemeral-jobs/route'
import { Route as redirectsBakActivitiesRouteImport } from './routes/(redirects)/bak/activities/route'
import { Route as SubscribeSubscriptionIdStepIdIndexImport } from './routes/subscribe/$subscriptionId/$stepId/index'
import { Route as BoSubscriptionsSubscriptionIdIndexImport } from './routes/bo/subscriptions/$subscriptionId/index'
import { Route as BoContractsSubscriptionIdIndexImport } from './routes/bo/contracts/$subscriptionId/index'
import { Route as BoClaimsClaimIdIndexImport } from './routes/bo/claims/$claimId/index'
import { Route as redirectsBakUsersIndexImport } from './routes/(redirects)/bak/users/index'
import { Route as redirectsBakPendingSubscriptionsIndexImport } from './routes/(redirects)/bak/pending-subscriptions/index'
import { Route as redirectsBakInvoicingIndexImport } from './routes/(redirects)/bak/invoicing/index'
import { Route as redirectsBakDocumentationIndexImport } from './routes/(redirects)/bak/documentation/index'
import { Route as redirectsBakAdminIndexImport } from './routes/(redirects)/bak/admin/index'
import { Route as BoTroubleshootingSessionsSessionIdRouteImport } from './routes/bo/troubleshooting/sessions/$sessionId/route'
import { Route as BoSubscriptionsSubscriptionIdPaymentRouteImport } from './routes/bo/subscriptions/$subscriptionId/payment/route'
import { Route as BoSubscriptionsSubscriptionIdHistoryRouteImport } from './routes/bo/subscriptions/$subscriptionId/history/route'
import { Route as BoSubscriptionsSubscriptionIdDetailedSheetRouteImport } from './routes/bo/subscriptions/$subscriptionId/detailed-sheet/route'
import { Route as BoContractsSubscriptionIdStatusRouteImport } from './routes/bo/contracts/$subscriptionId/status/route'
import { Route as CustomerSRTokenRouteImport } from './routes/_customer/s/r/$token/route'
import { Route as CustomerProductGuaranteesSubscriptionIdProductNameRouteImport } from './routes/_customer/product-guarantees/$subscriptionId/$productName/route'
import { Route as redirectsPartnerOrganizationSearchRouteImport } from './routes/(redirects)/partner/$organization/search/route'
import { Route as redirectsPartnerOrganizationNewSubscriptionRouteImport } from './routes/(redirects)/partner/$organization/new-subscription/route'
import { Route as redirectsPartnerOrganizationHomeRouteImport } from './routes/(redirects)/partner/$organization/home/route'
import { Route as redirectsBakUsersUserIdRouteImport } from './routes/(redirects)/bak/users/$userId/route'
import { Route as redirectsBakInvoicingNegativeInvoicesListRouteImport } from './routes/(redirects)/bak/invoicing/negative-invoices-list/route'
import { Route as redirectsBakDocumentationSubscriptionFunnelTextsRouteImport } from './routes/(redirects)/bak/documentation/subscription-funnel-texts/route'
import { Route as redirectsBakDocumentationRolesRouteImport } from './routes/(redirects)/bak/documentation/roles/route'
import { Route as redirectsBakDocumentationGeneralTermsRouteImport } from './routes/(redirects)/bak/documentation/general-terms/route'
import { Route as redirectsBakDocumentationExclusionsRouteImport } from './routes/(redirects)/bak/documentation/exclusions/route'
import { Route as redirectsBakDocumentationActivitySpecificQuestionsRouteImport } from './routes/(redirects)/bak/documentation/activity-specific-questions/route'
import { Route as redirectsBakAdminJobsRouteImport } from './routes/(redirects)/bak/admin/jobs/route'
import { Route as redirectsBakAdminImaRouteImport } from './routes/(redirects)/bak/admin/ima/route'
import { Route as redirectsBakAdminCpmsRouteImport } from './routes/(redirects)/bak/admin/cpms/route'
import { Route as redirectsPartnerOrganizationUsersIndexImport } from './routes/(redirects)/partner/$organization/users/index'
import { Route as redirectsPartnerOrganizationPendingSubscriptionsIndexImport } from './routes/(redirects)/partner/$organization/pending-subscriptions/index'
import { Route as redirectsBakV2PendingSubscriptionsSubscriptionIdIndexImport } from './routes/(redirects)/bak/v2-pending-subscriptions/$subscriptionId/index'
import { Route as redirectsBakContractsSubscriptionIdIndexImport } from './routes/(redirects)/bak/contracts/$subscriptionId/index'
import { Route as redirectsBakClaimsClaimIdIndexImport } from './routes/(redirects)/bak/claims/$claimId/index'
import { Route as SubscribeSubscriptionIdStepIdDetailTypeDetailIdRouteImport } from './routes/subscribe/$subscriptionId/$stepId/$detailType/$detailId/route'
import { Route as BoContractsSubscriptionIdRenewalsEndorsementIdRouteImport } from './routes/bo/contracts/$subscriptionId/renewals/$endorsementId/route'
import { Route as BoContractsSubscriptionIdInvoicesNewRouteImport } from './routes/bo/contracts/$subscriptionId/invoices/new/route'
import { Route as BoContractsSubscriptionIdInvoicesDebugRouteImport } from './routes/bo/contracts/$subscriptionId/invoices/debug/route'
import { Route as redirectsPartnerOrganizationUsersUserIdRouteImport } from './routes/(redirects)/partner/$organization/users/$userId/route'
import { Route as redirectsPartnerOrganizationContractsSubscriptionIdRouteImport } from './routes/(redirects)/partner/$organization/contracts/$subscriptionId/route'
import { Route as redirectsPartnerOrganizationClaimsClaimIdRouteImport } from './routes/(redirects)/partner/$organization/claims/$claimId/route'
import { Route as redirectsBakV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteImport } from './routes/(redirects)/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet/route'
import { Route as redirectsBakTroubleshootingSessionSessionIdRouteImport } from './routes/(redirects)/bak/troubleshooting/session/$sessionId/route'
import { Route as redirectsBakPendingSubscriptionsSubscriptionIdPaymentRouteImport } from './routes/(redirects)/bak/pending-subscriptions/$subscriptionId/payment/route'
import { Route as redirectsBakPendingSubscriptionsSubscriptionIdHistoryRouteImport } from './routes/(redirects)/bak/pending-subscriptions/$subscriptionId/history/route'
import { Route as redirectsBakContractsSubscriptionIdStatusRouteImport } from './routes/(redirects)/bak/contracts/$subscriptionId/status/route'
import { Route as redirectsBakContractsSubscriptionIdNewInvoiceRouteImport } from './routes/(redirects)/bak/contracts/$subscriptionId/new-invoice/route'
import { Route as redirectsBakContractsSubscriptionIdInvoicingRouteImport } from './routes/(redirects)/bak/contracts/$subscriptionId/invoicing/route'
import { Route as BoContractsSubscriptionIdEndorsementsEndorsementIdIndexImport } from './routes/bo/contracts/$subscriptionId/endorsements/$endorsementId/index'
import { Route as redirectsPartnerOrganizationV2PendingSubscriptionsSubscriptionIdIndexImport } from './routes/(redirects)/partner/$organization/v2-pending-subscriptions/$subscriptionId/index'
import { Route as BoContractsSubscriptionIdEndorsementsEndorsementIdDetailedSheetRouteImport } from './routes/bo/contracts/$subscriptionId/endorsements/$endorsementId/detailed-sheet/route'
import { Route as BoContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteImport } from './routes/bo/contracts/$subscriptionId/detailed-sheet/versions/$versionId/route'
import { Route as redirectsPartnerOrganizationV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteImport } from './routes/(redirects)/partner/$organization/v2-pending-subscriptions/$subscriptionId/detailed-sheet/route'
import { Route as redirectsPartnerOrganizationPendingSubscriptionsSubscriptionIdPaymentRouteImport } from './routes/(redirects)/partner/$organization/pending-subscriptions/$subscriptionId/payment/route'
import { Route as redirectsBakContractsSubscriptionIdRenewalEndorsementIdRouteImport } from './routes/(redirects)/bak/contracts/$subscriptionId/renewal/$endorsementId/route'
import { Route as redirectsBakContractsSubscriptionIdEndorsementEndorsementIdIndexImport } from './routes/(redirects)/bak/contracts/$subscriptionId/endorsement/$endorsementId/index'
import { Route as redirectsBakContractsSubscriptionIdEndorsementEndorsementIdDetailedSheetRouteImport } from './routes/(redirects)/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet/route'
import { Route as redirectsBakContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteImport } from './routes/(redirects)/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId/route'

// Create/Update Routes

const BoRoute = BoImport.update({
  id: '/bo',
  path: '/bo',
  getParentRoute: () => rootRoute,
} as any)

const CustomerRoute = CustomerImport.update({
  id: '/_customer',
  getParentRoute: () => rootRoute,
} as any)

const UnsubscribeRouteRoute = UnsubscribeRouteImport.update({
  id: '/unsubscribe',
  path: '/unsubscribe',
  getParentRoute: () => rootRoute,
} as any)

const SubscribeNoPossibleStepRouteRoute =
  SubscribeNoPossibleStepRouteImport.update({
    id: '/subscribe-no-possible-step',
    path: '/subscribe-no-possible-step',
    getParentRoute: () => rootRoute,
  } as any)

const SearchRouteRoute = SearchRouteImport.update({
  id: '/search',
  path: '/search',
  getParentRoute: () => rootRoute,
} as any)

const ReceiveQuoteRouteRoute = ReceiveQuoteRouteImport.update({
  id: '/receive-quote',
  path: '/receive-quote',
  getParentRoute: () => rootRoute,
} as any)

const PaymentMethodUpdateRouteRoute = PaymentMethodUpdateRouteImport.update({
  id: '/payment-method-update',
  path: '/payment-method-update',
  getParentRoute: () => rootRoute,
} as any)

const LoginRouteRoute = LoginRouteImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const ImpersonateRouteRoute = ImpersonateRouteImport.update({
  id: '/impersonate',
  path: '/impersonate',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const BoIndexRoute = BoIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => BoRoute,
} as any)

const SubscribeLandingRouteRoute = SubscribeLandingRouteImport.update({
  id: '/subscribe/landing',
  path: '/subscribe/landing',
  getParentRoute: () => rootRoute,
} as any)

const SubscribeExclusionSubscriptionIdRouteRoute =
  SubscribeExclusionSubscriptionIdRouteImport.update({
    id: '/subscribe-exclusion/$subscriptionId',
    path: '/subscribe-exclusion/$subscriptionId',
    getParentRoute: () => rootRoute,
  } as any)

const PaymentAuthenticationRouteRoute = PaymentAuthenticationRouteImport.update(
  {
    id: '/payment/authentication',
    path: '/payment/authentication',
    getParentRoute: () => rootRoute,
  } as any,
)

const BoTrackingDocRouteRoute = BoTrackingDocRouteImport.update({
  id: '/tracking-doc',
  path: '/tracking-doc',
  getParentRoute: () => BoRoute,
} as any)

const BoSearchV2RouteRoute = BoSearchV2RouteImport.update({
  id: '/searchV2',
  path: '/searchV2',
  getParentRoute: () => BoRoute,
} as any)

const BoSearchRouteRoute = BoSearchRouteImport.update({
  id: '/search',
  path: '/search',
  getParentRoute: () => BoRoute,
} as any)

const BoOrganizationsRouteRoute = BoOrganizationsRouteImport.update({
  id: '/organizations',
  path: '/organizations',
  getParentRoute: () => BoRoute,
} as any)

const BoMigrationRouteRoute = BoMigrationRouteImport.update({
  id: '/migration',
  path: '/migration',
  getParentRoute: () => BoRoute,
} as any)

const BoMembershipsRouteRoute = BoMembershipsRouteImport.update({
  id: '/memberships',
  path: '/memberships',
  getParentRoute: () => BoRoute,
} as any)

const BoEphemeralJobsRouteRoute = BoEphemeralJobsRouteImport.update({
  id: '/ephemeral-jobs',
  path: '/ephemeral-jobs',
  getParentRoute: () => BoRoute,
} as any)

const BoActivitiesRouteRoute = BoActivitiesRouteImport.update({
  id: '/activities',
  path: '/activities',
  getParentRoute: () => BoRoute,
} as any)

const CustomerReportClaimRouteRoute = CustomerReportClaimRouteImport.update({
  id: '/report-claim',
  path: '/report-claim',
  getParentRoute: () => CustomerRoute,
} as any)

const CustomerInvoicesRouteRoute = CustomerInvoicesRouteImport.update({
  id: '/invoices',
  path: '/invoices',
  getParentRoute: () => CustomerRoute,
} as any)

const CustomerHomeRouteRoute = CustomerHomeRouteImport.update({
  id: '/home',
  path: '/home',
  getParentRoute: () => CustomerRoute,
} as any)

const CustomerContractsRouteRoute = CustomerContractsRouteImport.update({
  id: '/contracts',
  path: '/contracts',
  getParentRoute: () => CustomerRoute,
} as any)

const CustomerContractGuaranteesRouteRoute =
  CustomerContractGuaranteesRouteImport.update({
    id: '/contract-guarantees',
    path: '/contract-guarantees',
    getParentRoute: () => CustomerRoute,
  } as any)

const CustomerAccountRouteRoute = CustomerAccountRouteImport.update({
  id: '/account',
  path: '/account',
  getParentRoute: () => CustomerRoute,
} as any)

const BoUsersIndexRoute = BoUsersIndexImport.update({
  id: '/users/',
  path: '/users/',
  getParentRoute: () => BoRoute,
} as any)

const BoSubscriptionsIndexRoute = BoSubscriptionsIndexImport.update({
  id: '/subscriptions/',
  path: '/subscriptions/',
  getParentRoute: () => BoRoute,
} as any)

const BoInvoicingIndexRoute = BoInvoicingIndexImport.update({
  id: '/invoicing/',
  path: '/invoicing/',
  getParentRoute: () => BoRoute,
} as any)

const BoDocumentationIndexRoute = BoDocumentationIndexImport.update({
  id: '/documentation/',
  path: '/documentation/',
  getParentRoute: () => BoRoute,
} as any)

const BoAdminIndexRoute = BoAdminIndexImport.update({
  id: '/admin/',
  path: '/admin/',
  getParentRoute: () => BoRoute,
} as any)

const CustomerHealthInsuranceIndexRoute =
  CustomerHealthInsuranceIndexImport.update({
    id: '/health-insurance/',
    path: '/health-insurance/',
    getParentRoute: () => CustomerRoute,
  } as any)

const SubscribeRestaurantsV2IdentifyRouteRoute =
  SubscribeRestaurantsV2IdentifyRouteImport.update({
    id: '/subscribe/restaurants-v2/identify',
    path: '/subscribe/restaurants-v2/identify',
    getParentRoute: () => rootRoute,
  } as any)

const SQTokenRouteRoute = SQTokenRouteImport.update({
  id: '/s/q/$token',
  path: '/s/q/$token',
  getParentRoute: () => rootRoute,
} as any)

const SEV2TokenRouteRoute = SEV2TokenRouteImport.update({
  id: '/s/e-v2/$token',
  path: '/s/e-v2/$token',
  getParentRoute: () => rootRoute,
} as any)

const SCTokenRouteRoute = SCTokenRouteImport.update({
  id: '/s/c/$token',
  path: '/s/c/$token',
  getParentRoute: () => rootRoute,
} as any)

const BoUsersUserIdRouteRoute = BoUsersUserIdRouteImport.update({
  id: '/users/$userId',
  path: '/users/$userId',
  getParentRoute: () => BoRoute,
} as any)

const BoSubscriptionsNewRouteRoute = BoSubscriptionsNewRouteImport.update({
  id: '/subscriptions/new',
  path: '/subscriptions/new',
  getParentRoute: () => BoRoute,
} as any)

const BoInvoicingNegativeInvoicesRouteRoute =
  BoInvoicingNegativeInvoicesRouteImport.update({
    id: '/invoicing/negative-invoices',
    path: '/invoicing/negative-invoices',
    getParentRoute: () => BoRoute,
  } as any)

const BoInvoicingInvoiceIdRouteRoute = BoInvoicingInvoiceIdRouteImport.update({
  id: '/invoicing/$invoiceId',
  path: '/invoicing/$invoiceId',
  getParentRoute: () => BoRoute,
} as any)

const BoDocumentationSubscriptionFunnelTextsRouteRoute =
  BoDocumentationSubscriptionFunnelTextsRouteImport.update({
    id: '/documentation/subscription-funnel-texts',
    path: '/documentation/subscription-funnel-texts',
    getParentRoute: () => BoRoute,
  } as any)

const BoDocumentationRolesRouteRoute = BoDocumentationRolesRouteImport.update({
  id: '/documentation/roles',
  path: '/documentation/roles',
  getParentRoute: () => BoRoute,
} as any)

const BoDocumentationGeneralTermsRouteRoute =
  BoDocumentationGeneralTermsRouteImport.update({
    id: '/documentation/general-terms',
    path: '/documentation/general-terms',
    getParentRoute: () => BoRoute,
  } as any)

const BoDocumentationExclusionsRouteRoute =
  BoDocumentationExclusionsRouteImport.update({
    id: '/documentation/exclusions',
    path: '/documentation/exclusions',
    getParentRoute: () => BoRoute,
  } as any)

const BoDocumentationActivitySpecificQuestionsRouteRoute =
  BoDocumentationActivitySpecificQuestionsRouteImport.update({
    id: '/documentation/activity-specific-questions',
    path: '/documentation/activity-specific-questions',
    getParentRoute: () => BoRoute,
  } as any)

const BoAdminTimeRouteRoute = BoAdminTimeRouteImport.update({
  id: '/admin/time',
  path: '/admin/time',
  getParentRoute: () => BoRoute,
} as any)

const BoAdminJobsRouteRoute = BoAdminJobsRouteImport.update({
  id: '/admin/jobs',
  path: '/admin/jobs',
  getParentRoute: () => BoRoute,
} as any)

const BoAdminImaRouteRoute = BoAdminImaRouteImport.update({
  id: '/admin/ima',
  path: '/admin/ima',
  getParentRoute: () => BoRoute,
} as any)

const BoAdminCpmsRouteRoute = BoAdminCpmsRouteImport.update({
  id: '/admin/cpms',
  path: '/admin/cpms',
  getParentRoute: () => BoRoute,
} as any)

const CustomerPaymentMethodsUpdateRouteRoute =
  CustomerPaymentMethodsUpdateRouteImport.update({
    id: '/payment-methods/update',
    path: '/payment-methods/update',
    getParentRoute: () => CustomerRoute,
  } as any)

const CustomerPaymentMethodsNewRouteRoute =
  CustomerPaymentMethodsNewRouteImport.update({
    id: '/payment-methods/new',
    path: '/payment-methods/new',
    getParentRoute: () => CustomerRoute,
  } as any)

const CustomerInsuranceCertificateGeneratorRealEstateSalesAgentsSubscriptionIdRouteRoute =
  CustomerInsuranceCertificateGeneratorRealEstateSalesAgentsSubscriptionIdRouteImport.update(
    {
      id: '/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId',
      path: '/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId',
      getParentRoute: () => CustomerRoute,
    } as any,
  )

const CustomerInsuranceCertificateGeneratorRealEstateAgentsSubscriptionIdRouteRoute =
  CustomerInsuranceCertificateGeneratorRealEstateAgentsSubscriptionIdRouteImport.update(
    {
      id: '/insurance-certificate-generator-real-estate-agents/$subscriptionId',
      path: '/insurance-certificate-generator-real-estate-agents/$subscriptionId',
      getParentRoute: () => CustomerRoute,
    } as any,
  )

const CustomerHealthInsuranceSanteclairRouteRoute =
  CustomerHealthInsuranceSanteclairRouteImport.update({
    id: '/health-insurance/santeclair',
    path: '/health-insurance/santeclair',
    getParentRoute: () => CustomerRoute,
  } as any)

const CustomerHealthInsuranceCpmsRouteRoute =
  CustomerHealthInsuranceCpmsRouteImport.update({
    id: '/health-insurance/cpms',
    path: '/health-insurance/cpms',
    getParentRoute: () => CustomerRoute,
  } as any)

const CustomerHealthInsuranceBeneficiariesRouteRoute =
  CustomerHealthInsuranceBeneficiariesRouteImport.update({
    id: '/health-insurance/beneficiaries',
    path: '/health-insurance/beneficiaries',
    getParentRoute: () => CustomerRoute,
  } as any)

const CustomerContractSubscriptionIdRouteRoute =
  CustomerContractSubscriptionIdRouteImport.update({
    id: '/contract/$subscriptionId',
    path: '/contract/$subscriptionId',
    getParentRoute: () => CustomerRoute,
  } as any)

const redirectsBakTrackingDocRouteRoute =
  redirectsBakTrackingDocRouteImport.update({
    id: '/(redirects)/bak/tracking-doc',
    path: '/bak/tracking-doc',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakSearchRouteRoute = redirectsBakSearchRouteImport.update({
  id: '/(redirects)/bak/search',
  path: '/bak/search',
  getParentRoute: () => rootRoute,
} as any)

const redirectsBakOrganizationsRouteRoute =
  redirectsBakOrganizationsRouteImport.update({
    id: '/(redirects)/bak/organizations',
    path: '/bak/organizations',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakNewSubscriptionRouteRoute =
  redirectsBakNewSubscriptionRouteImport.update({
    id: '/(redirects)/bak/new-subscription',
    path: '/bak/new-subscription',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakMigrationRouteRoute = redirectsBakMigrationRouteImport.update(
  {
    id: '/(redirects)/bak/migration',
    path: '/bak/migration',
    getParentRoute: () => rootRoute,
  } as any,
)

const redirectsBakMembershipsRouteRoute =
  redirectsBakMembershipsRouteImport.update({
    id: '/(redirects)/bak/memberships',
    path: '/bak/memberships',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakHomeRouteRoute = redirectsBakHomeRouteImport.update({
  id: '/(redirects)/bak/home',
  path: '/bak/home',
  getParentRoute: () => rootRoute,
} as any)

const redirectsBakEphemeralJobsRouteRoute =
  redirectsBakEphemeralJobsRouteImport.update({
    id: '/(redirects)/bak/ephemeral-jobs',
    path: '/bak/ephemeral-jobs',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakActivitiesRouteRoute =
  redirectsBakActivitiesRouteImport.update({
    id: '/(redirects)/bak/activities',
    path: '/bak/activities',
    getParentRoute: () => rootRoute,
  } as any)

const SubscribeSubscriptionIdStepIdIndexRoute =
  SubscribeSubscriptionIdStepIdIndexImport.update({
    id: '/subscribe/$subscriptionId/$stepId/',
    path: '/subscribe/$subscriptionId/$stepId/',
    getParentRoute: () => rootRoute,
  } as any)

const BoSubscriptionsSubscriptionIdIndexRoute =
  BoSubscriptionsSubscriptionIdIndexImport.update({
    id: '/subscriptions/$subscriptionId/',
    path: '/subscriptions/$subscriptionId/',
    getParentRoute: () => BoRoute,
  } as any)

const BoContractsSubscriptionIdIndexRoute =
  BoContractsSubscriptionIdIndexImport.update({
    id: '/contracts/$subscriptionId/',
    path: '/contracts/$subscriptionId/',
    getParentRoute: () => BoRoute,
  } as any)

const BoClaimsClaimIdIndexRoute = BoClaimsClaimIdIndexImport.update({
  id: '/claims/$claimId/',
  path: '/claims/$claimId/',
  getParentRoute: () => BoRoute,
} as any)

const redirectsBakUsersIndexRoute = redirectsBakUsersIndexImport.update({
  id: '/(redirects)/bak/users/',
  path: '/bak/users/',
  getParentRoute: () => rootRoute,
} as any)

const redirectsBakPendingSubscriptionsIndexRoute =
  redirectsBakPendingSubscriptionsIndexImport.update({
    id: '/(redirects)/bak/pending-subscriptions/',
    path: '/bak/pending-subscriptions/',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakInvoicingIndexRoute = redirectsBakInvoicingIndexImport.update(
  {
    id: '/(redirects)/bak/invoicing/',
    path: '/bak/invoicing/',
    getParentRoute: () => rootRoute,
  } as any,
)

const redirectsBakDocumentationIndexRoute =
  redirectsBakDocumentationIndexImport.update({
    id: '/(redirects)/bak/documentation/',
    path: '/bak/documentation/',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakAdminIndexRoute = redirectsBakAdminIndexImport.update({
  id: '/(redirects)/bak/admin/',
  path: '/bak/admin/',
  getParentRoute: () => rootRoute,
} as any)

const BoTroubleshootingSessionsSessionIdRouteRoute =
  BoTroubleshootingSessionsSessionIdRouteImport.update({
    id: '/troubleshooting/sessions/$sessionId',
    path: '/troubleshooting/sessions/$sessionId',
    getParentRoute: () => BoRoute,
  } as any)

const BoSubscriptionsSubscriptionIdPaymentRouteRoute =
  BoSubscriptionsSubscriptionIdPaymentRouteImport.update({
    id: '/subscriptions/$subscriptionId/payment',
    path: '/subscriptions/$subscriptionId/payment',
    getParentRoute: () => BoRoute,
  } as any)

const BoSubscriptionsSubscriptionIdHistoryRouteRoute =
  BoSubscriptionsSubscriptionIdHistoryRouteImport.update({
    id: '/subscriptions/$subscriptionId/history',
    path: '/subscriptions/$subscriptionId/history',
    getParentRoute: () => BoRoute,
  } as any)

const BoSubscriptionsSubscriptionIdDetailedSheetRouteRoute =
  BoSubscriptionsSubscriptionIdDetailedSheetRouteImport.update({
    id: '/subscriptions/$subscriptionId/detailed-sheet',
    path: '/subscriptions/$subscriptionId/detailed-sheet',
    getParentRoute: () => BoRoute,
  } as any)

const BoContractsSubscriptionIdStatusRouteRoute =
  BoContractsSubscriptionIdStatusRouteImport.update({
    id: '/contracts/$subscriptionId/status',
    path: '/contracts/$subscriptionId/status',
    getParentRoute: () => BoRoute,
  } as any)

const CustomerSRTokenRouteRoute = CustomerSRTokenRouteImport.update({
  id: '/s/r/$token',
  path: '/s/r/$token',
  getParentRoute: () => CustomerRoute,
} as any)

const CustomerProductGuaranteesSubscriptionIdProductNameRouteRoute =
  CustomerProductGuaranteesSubscriptionIdProductNameRouteImport.update({
    id: '/product-guarantees/$subscriptionId/$productName',
    path: '/product-guarantees/$subscriptionId/$productName',
    getParentRoute: () => CustomerRoute,
  } as any)

const redirectsPartnerOrganizationSearchRouteRoute =
  redirectsPartnerOrganizationSearchRouteImport.update({
    id: '/(redirects)/partner/$organization/search',
    path: '/partner/$organization/search',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsPartnerOrganizationNewSubscriptionRouteRoute =
  redirectsPartnerOrganizationNewSubscriptionRouteImport.update({
    id: '/(redirects)/partner/$organization/new-subscription',
    path: '/partner/$organization/new-subscription',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsPartnerOrganizationHomeRouteRoute =
  redirectsPartnerOrganizationHomeRouteImport.update({
    id: '/(redirects)/partner/$organization/home',
    path: '/partner/$organization/home',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakUsersUserIdRouteRoute =
  redirectsBakUsersUserIdRouteImport.update({
    id: '/(redirects)/bak/users/$userId',
    path: '/bak/users/$userId',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakInvoicingNegativeInvoicesListRouteRoute =
  redirectsBakInvoicingNegativeInvoicesListRouteImport.update({
    id: '/(redirects)/bak/invoicing/negative-invoices-list',
    path: '/bak/invoicing/negative-invoices-list',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakDocumentationSubscriptionFunnelTextsRouteRoute =
  redirectsBakDocumentationSubscriptionFunnelTextsRouteImport.update({
    id: '/(redirects)/bak/documentation/subscription-funnel-texts',
    path: '/bak/documentation/subscription-funnel-texts',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakDocumentationRolesRouteRoute =
  redirectsBakDocumentationRolesRouteImport.update({
    id: '/(redirects)/bak/documentation/roles',
    path: '/bak/documentation/roles',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakDocumentationGeneralTermsRouteRoute =
  redirectsBakDocumentationGeneralTermsRouteImport.update({
    id: '/(redirects)/bak/documentation/general-terms',
    path: '/bak/documentation/general-terms',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakDocumentationExclusionsRouteRoute =
  redirectsBakDocumentationExclusionsRouteImport.update({
    id: '/(redirects)/bak/documentation/exclusions',
    path: '/bak/documentation/exclusions',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakDocumentationActivitySpecificQuestionsRouteRoute =
  redirectsBakDocumentationActivitySpecificQuestionsRouteImport.update({
    id: '/(redirects)/bak/documentation/activity-specific-questions',
    path: '/bak/documentation/activity-specific-questions',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakAdminJobsRouteRoute = redirectsBakAdminJobsRouteImport.update(
  {
    id: '/(redirects)/bak/admin/jobs',
    path: '/bak/admin/jobs',
    getParentRoute: () => rootRoute,
  } as any,
)

const redirectsBakAdminImaRouteRoute = redirectsBakAdminImaRouteImport.update({
  id: '/(redirects)/bak/admin/ima',
  path: '/bak/admin/ima',
  getParentRoute: () => rootRoute,
} as any)

const redirectsBakAdminCpmsRouteRoute = redirectsBakAdminCpmsRouteImport.update(
  {
    id: '/(redirects)/bak/admin/cpms',
    path: '/bak/admin/cpms',
    getParentRoute: () => rootRoute,
  } as any,
)

const redirectsPartnerOrganizationUsersIndexRoute =
  redirectsPartnerOrganizationUsersIndexImport.update({
    id: '/(redirects)/partner/$organization/users/',
    path: '/partner/$organization/users/',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsPartnerOrganizationPendingSubscriptionsIndexRoute =
  redirectsPartnerOrganizationPendingSubscriptionsIndexImport.update({
    id: '/(redirects)/partner/$organization/pending-subscriptions/',
    path: '/partner/$organization/pending-subscriptions/',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakV2PendingSubscriptionsSubscriptionIdIndexRoute =
  redirectsBakV2PendingSubscriptionsSubscriptionIdIndexImport.update({
    id: '/(redirects)/bak/v2-pending-subscriptions/$subscriptionId/',
    path: '/bak/v2-pending-subscriptions/$subscriptionId/',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakContractsSubscriptionIdIndexRoute =
  redirectsBakContractsSubscriptionIdIndexImport.update({
    id: '/(redirects)/bak/contracts/$subscriptionId/',
    path: '/bak/contracts/$subscriptionId/',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakClaimsClaimIdIndexRoute =
  redirectsBakClaimsClaimIdIndexImport.update({
    id: '/(redirects)/bak/claims/$claimId/',
    path: '/bak/claims/$claimId/',
    getParentRoute: () => rootRoute,
  } as any)

const SubscribeSubscriptionIdStepIdDetailTypeDetailIdRouteRoute =
  SubscribeSubscriptionIdStepIdDetailTypeDetailIdRouteImport.update({
    id: '/subscribe/$subscriptionId/$stepId/$detailType/$detailId',
    path: '/subscribe/$subscriptionId/$stepId/$detailType/$detailId',
    getParentRoute: () => rootRoute,
  } as any)

const BoContractsSubscriptionIdRenewalsEndorsementIdRouteRoute =
  BoContractsSubscriptionIdRenewalsEndorsementIdRouteImport.update({
    id: '/contracts/$subscriptionId/renewals/$endorsementId',
    path: '/contracts/$subscriptionId/renewals/$endorsementId',
    getParentRoute: () => BoRoute,
  } as any)

const BoContractsSubscriptionIdInvoicesNewRouteRoute =
  BoContractsSubscriptionIdInvoicesNewRouteImport.update({
    id: '/contracts/$subscriptionId/invoices/new',
    path: '/contracts/$subscriptionId/invoices/new',
    getParentRoute: () => BoRoute,
  } as any)

const BoContractsSubscriptionIdInvoicesDebugRouteRoute =
  BoContractsSubscriptionIdInvoicesDebugRouteImport.update({
    id: '/contracts/$subscriptionId/invoices/debug',
    path: '/contracts/$subscriptionId/invoices/debug',
    getParentRoute: () => BoRoute,
  } as any)

const redirectsPartnerOrganizationUsersUserIdRouteRoute =
  redirectsPartnerOrganizationUsersUserIdRouteImport.update({
    id: '/(redirects)/partner/$organization/users/$userId',
    path: '/partner/$organization/users/$userId',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsPartnerOrganizationContractsSubscriptionIdRouteRoute =
  redirectsPartnerOrganizationContractsSubscriptionIdRouteImport.update({
    id: '/(redirects)/partner/$organization/contracts/$subscriptionId',
    path: '/partner/$organization/contracts/$subscriptionId',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsPartnerOrganizationClaimsClaimIdRouteRoute =
  redirectsPartnerOrganizationClaimsClaimIdRouteImport.update({
    id: '/(redirects)/partner/$organization/claims/$claimId',
    path: '/partner/$organization/claims/$claimId',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute =
  redirectsBakV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteImport.update(
    {
      id: '/(redirects)/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet',
      path: '/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet',
      getParentRoute: () => rootRoute,
    } as any,
  )

const redirectsBakTroubleshootingSessionSessionIdRouteRoute =
  redirectsBakTroubleshootingSessionSessionIdRouteImport.update({
    id: '/(redirects)/bak/troubleshooting/session/$sessionId',
    path: '/bak/troubleshooting/session/$sessionId',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakPendingSubscriptionsSubscriptionIdPaymentRouteRoute =
  redirectsBakPendingSubscriptionsSubscriptionIdPaymentRouteImport.update({
    id: '/(redirects)/bak/pending-subscriptions/$subscriptionId/payment',
    path: '/bak/pending-subscriptions/$subscriptionId/payment',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakPendingSubscriptionsSubscriptionIdHistoryRouteRoute =
  redirectsBakPendingSubscriptionsSubscriptionIdHistoryRouteImport.update({
    id: '/(redirects)/bak/pending-subscriptions/$subscriptionId/history',
    path: '/bak/pending-subscriptions/$subscriptionId/history',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakContractsSubscriptionIdStatusRouteRoute =
  redirectsBakContractsSubscriptionIdStatusRouteImport.update({
    id: '/(redirects)/bak/contracts/$subscriptionId/status',
    path: '/bak/contracts/$subscriptionId/status',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakContractsSubscriptionIdNewInvoiceRouteRoute =
  redirectsBakContractsSubscriptionIdNewInvoiceRouteImport.update({
    id: '/(redirects)/bak/contracts/$subscriptionId/new-invoice',
    path: '/bak/contracts/$subscriptionId/new-invoice',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakContractsSubscriptionIdInvoicingRouteRoute =
  redirectsBakContractsSubscriptionIdInvoicingRouteImport.update({
    id: '/(redirects)/bak/contracts/$subscriptionId/invoicing',
    path: '/bak/contracts/$subscriptionId/invoicing',
    getParentRoute: () => rootRoute,
  } as any)

const BoContractsSubscriptionIdEndorsementsEndorsementIdIndexRoute =
  BoContractsSubscriptionIdEndorsementsEndorsementIdIndexImport.update({
    id: '/contracts/$subscriptionId/endorsements/$endorsementId/',
    path: '/contracts/$subscriptionId/endorsements/$endorsementId/',
    getParentRoute: () => BoRoute,
  } as any)

const redirectsPartnerOrganizationV2PendingSubscriptionsSubscriptionIdIndexRoute =
  redirectsPartnerOrganizationV2PendingSubscriptionsSubscriptionIdIndexImport.update(
    {
      id: '/(redirects)/partner/$organization/v2-pending-subscriptions/$subscriptionId/',
      path: '/partner/$organization/v2-pending-subscriptions/$subscriptionId/',
      getParentRoute: () => rootRoute,
    } as any,
  )

const BoContractsSubscriptionIdEndorsementsEndorsementIdDetailedSheetRouteRoute =
  BoContractsSubscriptionIdEndorsementsEndorsementIdDetailedSheetRouteImport.update(
    {
      id: '/contracts/$subscriptionId/endorsements/$endorsementId/detailed-sheet',
      path: '/contracts/$subscriptionId/endorsements/$endorsementId/detailed-sheet',
      getParentRoute: () => BoRoute,
    } as any,
  )

const BoContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute =
  BoContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteImport.update({
    id: '/contracts/$subscriptionId/detailed-sheet/versions/$versionId',
    path: '/contracts/$subscriptionId/detailed-sheet/versions/$versionId',
    getParentRoute: () => BoRoute,
  } as any)

const redirectsPartnerOrganizationV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute =
  redirectsPartnerOrganizationV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteImport.update(
    {
      id: '/(redirects)/partner/$organization/v2-pending-subscriptions/$subscriptionId/detailed-sheet',
      path: '/partner/$organization/v2-pending-subscriptions/$subscriptionId/detailed-sheet',
      getParentRoute: () => rootRoute,
    } as any,
  )

const redirectsPartnerOrganizationPendingSubscriptionsSubscriptionIdPaymentRouteRoute =
  redirectsPartnerOrganizationPendingSubscriptionsSubscriptionIdPaymentRouteImport.update(
    {
      id: '/(redirects)/partner/$organization/pending-subscriptions/$subscriptionId/payment',
      path: '/partner/$organization/pending-subscriptions/$subscriptionId/payment',
      getParentRoute: () => rootRoute,
    } as any,
  )

const redirectsBakContractsSubscriptionIdRenewalEndorsementIdRouteRoute =
  redirectsBakContractsSubscriptionIdRenewalEndorsementIdRouteImport.update({
    id: '/(redirects)/bak/contracts/$subscriptionId/renewal/$endorsementId',
    path: '/bak/contracts/$subscriptionId/renewal/$endorsementId',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsBakContractsSubscriptionIdEndorsementEndorsementIdIndexRoute =
  redirectsBakContractsSubscriptionIdEndorsementEndorsementIdIndexImport.update(
    {
      id: '/(redirects)/bak/contracts/$subscriptionId/endorsement/$endorsementId/',
      path: '/bak/contracts/$subscriptionId/endorsement/$endorsementId/',
      getParentRoute: () => rootRoute,
    } as any,
  )

const redirectsBakContractsSubscriptionIdEndorsementEndorsementIdDetailedSheetRouteRoute =
  redirectsBakContractsSubscriptionIdEndorsementEndorsementIdDetailedSheetRouteImport.update(
    {
      id: '/(redirects)/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet',
      path: '/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet',
      getParentRoute: () => rootRoute,
    } as any,
  )

const redirectsBakContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute =
  redirectsBakContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteImport.update(
    {
      id: '/(redirects)/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId',
      path: '/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId',
      getParentRoute: () => rootRoute,
    } as any,
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/impersonate': {
      id: '/impersonate'
      path: '/impersonate'
      fullPath: '/impersonate'
      preLoaderRoute: typeof ImpersonateRouteImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginRouteImport
      parentRoute: typeof rootRoute
    }
    '/payment-method-update': {
      id: '/payment-method-update'
      path: '/payment-method-update'
      fullPath: '/payment-method-update'
      preLoaderRoute: typeof PaymentMethodUpdateRouteImport
      parentRoute: typeof rootRoute
    }
    '/receive-quote': {
      id: '/receive-quote'
      path: '/receive-quote'
      fullPath: '/receive-quote'
      preLoaderRoute: typeof ReceiveQuoteRouteImport
      parentRoute: typeof rootRoute
    }
    '/search': {
      id: '/search'
      path: '/search'
      fullPath: '/search'
      preLoaderRoute: typeof SearchRouteImport
      parentRoute: typeof rootRoute
    }
    '/subscribe-no-possible-step': {
      id: '/subscribe-no-possible-step'
      path: '/subscribe-no-possible-step'
      fullPath: '/subscribe-no-possible-step'
      preLoaderRoute: typeof SubscribeNoPossibleStepRouteImport
      parentRoute: typeof rootRoute
    }
    '/unsubscribe': {
      id: '/unsubscribe'
      path: '/unsubscribe'
      fullPath: '/unsubscribe'
      preLoaderRoute: typeof UnsubscribeRouteImport
      parentRoute: typeof rootRoute
    }
    '/_customer': {
      id: '/_customer'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof CustomerImport
      parentRoute: typeof rootRoute
    }
    '/bo': {
      id: '/bo'
      path: '/bo'
      fullPath: '/bo'
      preLoaderRoute: typeof BoImport
      parentRoute: typeof rootRoute
    }
    '/_customer/account': {
      id: '/_customer/account'
      path: '/account'
      fullPath: '/account'
      preLoaderRoute: typeof CustomerAccountRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/contract-guarantees': {
      id: '/_customer/contract-guarantees'
      path: '/contract-guarantees'
      fullPath: '/contract-guarantees'
      preLoaderRoute: typeof CustomerContractGuaranteesRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/contracts': {
      id: '/_customer/contracts'
      path: '/contracts'
      fullPath: '/contracts'
      preLoaderRoute: typeof CustomerContractsRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/home': {
      id: '/_customer/home'
      path: '/home'
      fullPath: '/home'
      preLoaderRoute: typeof CustomerHomeRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/invoices': {
      id: '/_customer/invoices'
      path: '/invoices'
      fullPath: '/invoices'
      preLoaderRoute: typeof CustomerInvoicesRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/report-claim': {
      id: '/_customer/report-claim'
      path: '/report-claim'
      fullPath: '/report-claim'
      preLoaderRoute: typeof CustomerReportClaimRouteImport
      parentRoute: typeof CustomerImport
    }
    '/bo/activities': {
      id: '/bo/activities'
      path: '/activities'
      fullPath: '/bo/activities'
      preLoaderRoute: typeof BoActivitiesRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/ephemeral-jobs': {
      id: '/bo/ephemeral-jobs'
      path: '/ephemeral-jobs'
      fullPath: '/bo/ephemeral-jobs'
      preLoaderRoute: typeof BoEphemeralJobsRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/memberships': {
      id: '/bo/memberships'
      path: '/memberships'
      fullPath: '/bo/memberships'
      preLoaderRoute: typeof BoMembershipsRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/migration': {
      id: '/bo/migration'
      path: '/migration'
      fullPath: '/bo/migration'
      preLoaderRoute: typeof BoMigrationRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/organizations': {
      id: '/bo/organizations'
      path: '/organizations'
      fullPath: '/bo/organizations'
      preLoaderRoute: typeof BoOrganizationsRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/search': {
      id: '/bo/search'
      path: '/search'
      fullPath: '/bo/search'
      preLoaderRoute: typeof BoSearchRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/searchV2': {
      id: '/bo/searchV2'
      path: '/searchV2'
      fullPath: '/bo/searchV2'
      preLoaderRoute: typeof BoSearchV2RouteImport
      parentRoute: typeof BoImport
    }
    '/bo/tracking-doc': {
      id: '/bo/tracking-doc'
      path: '/tracking-doc'
      fullPath: '/bo/tracking-doc'
      preLoaderRoute: typeof BoTrackingDocRouteImport
      parentRoute: typeof BoImport
    }
    '/payment/authentication': {
      id: '/payment/authentication'
      path: '/payment/authentication'
      fullPath: '/payment/authentication'
      preLoaderRoute: typeof PaymentAuthenticationRouteImport
      parentRoute: typeof rootRoute
    }
    '/subscribe-exclusion/$subscriptionId': {
      id: '/subscribe-exclusion/$subscriptionId'
      path: '/subscribe-exclusion/$subscriptionId'
      fullPath: '/subscribe-exclusion/$subscriptionId'
      preLoaderRoute: typeof SubscribeExclusionSubscriptionIdRouteImport
      parentRoute: typeof rootRoute
    }
    '/subscribe/landing': {
      id: '/subscribe/landing'
      path: '/subscribe/landing'
      fullPath: '/subscribe/landing'
      preLoaderRoute: typeof SubscribeLandingRouteImport
      parentRoute: typeof rootRoute
    }
    '/bo/': {
      id: '/bo/'
      path: '/'
      fullPath: '/bo/'
      preLoaderRoute: typeof BoIndexImport
      parentRoute: typeof BoImport
    }
    '/(redirects)/bak/activities': {
      id: '/(redirects)/bak/activities'
      path: '/bak/activities'
      fullPath: '/bak/activities'
      preLoaderRoute: typeof redirectsBakActivitiesRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/ephemeral-jobs': {
      id: '/(redirects)/bak/ephemeral-jobs'
      path: '/bak/ephemeral-jobs'
      fullPath: '/bak/ephemeral-jobs'
      preLoaderRoute: typeof redirectsBakEphemeralJobsRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/home': {
      id: '/(redirects)/bak/home'
      path: '/bak/home'
      fullPath: '/bak/home'
      preLoaderRoute: typeof redirectsBakHomeRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/memberships': {
      id: '/(redirects)/bak/memberships'
      path: '/bak/memberships'
      fullPath: '/bak/memberships'
      preLoaderRoute: typeof redirectsBakMembershipsRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/migration': {
      id: '/(redirects)/bak/migration'
      path: '/bak/migration'
      fullPath: '/bak/migration'
      preLoaderRoute: typeof redirectsBakMigrationRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/new-subscription': {
      id: '/(redirects)/bak/new-subscription'
      path: '/bak/new-subscription'
      fullPath: '/bak/new-subscription'
      preLoaderRoute: typeof redirectsBakNewSubscriptionRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/organizations': {
      id: '/(redirects)/bak/organizations'
      path: '/bak/organizations'
      fullPath: '/bak/organizations'
      preLoaderRoute: typeof redirectsBakOrganizationsRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/search': {
      id: '/(redirects)/bak/search'
      path: '/bak/search'
      fullPath: '/bak/search'
      preLoaderRoute: typeof redirectsBakSearchRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/tracking-doc': {
      id: '/(redirects)/bak/tracking-doc'
      path: '/bak/tracking-doc'
      fullPath: '/bak/tracking-doc'
      preLoaderRoute: typeof redirectsBakTrackingDocRouteImport
      parentRoute: typeof rootRoute
    }
    '/_customer/contract/$subscriptionId': {
      id: '/_customer/contract/$subscriptionId'
      path: '/contract/$subscriptionId'
      fullPath: '/contract/$subscriptionId'
      preLoaderRoute: typeof CustomerContractSubscriptionIdRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/health-insurance/beneficiaries': {
      id: '/_customer/health-insurance/beneficiaries'
      path: '/health-insurance/beneficiaries'
      fullPath: '/health-insurance/beneficiaries'
      preLoaderRoute: typeof CustomerHealthInsuranceBeneficiariesRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/health-insurance/cpms': {
      id: '/_customer/health-insurance/cpms'
      path: '/health-insurance/cpms'
      fullPath: '/health-insurance/cpms'
      preLoaderRoute: typeof CustomerHealthInsuranceCpmsRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/health-insurance/santeclair': {
      id: '/_customer/health-insurance/santeclair'
      path: '/health-insurance/santeclair'
      fullPath: '/health-insurance/santeclair'
      preLoaderRoute: typeof CustomerHealthInsuranceSanteclairRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/insurance-certificate-generator-real-estate-agents/$subscriptionId': {
      id: '/_customer/insurance-certificate-generator-real-estate-agents/$subscriptionId'
      path: '/insurance-certificate-generator-real-estate-agents/$subscriptionId'
      fullPath: '/insurance-certificate-generator-real-estate-agents/$subscriptionId'
      preLoaderRoute: typeof CustomerInsuranceCertificateGeneratorRealEstateAgentsSubscriptionIdRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId': {
      id: '/_customer/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId'
      path: '/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId'
      fullPath: '/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId'
      preLoaderRoute: typeof CustomerInsuranceCertificateGeneratorRealEstateSalesAgentsSubscriptionIdRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/payment-methods/new': {
      id: '/_customer/payment-methods/new'
      path: '/payment-methods/new'
      fullPath: '/payment-methods/new'
      preLoaderRoute: typeof CustomerPaymentMethodsNewRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/payment-methods/update': {
      id: '/_customer/payment-methods/update'
      path: '/payment-methods/update'
      fullPath: '/payment-methods/update'
      preLoaderRoute: typeof CustomerPaymentMethodsUpdateRouteImport
      parentRoute: typeof CustomerImport
    }
    '/bo/admin/cpms': {
      id: '/bo/admin/cpms'
      path: '/admin/cpms'
      fullPath: '/bo/admin/cpms'
      preLoaderRoute: typeof BoAdminCpmsRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/admin/ima': {
      id: '/bo/admin/ima'
      path: '/admin/ima'
      fullPath: '/bo/admin/ima'
      preLoaderRoute: typeof BoAdminImaRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/admin/jobs': {
      id: '/bo/admin/jobs'
      path: '/admin/jobs'
      fullPath: '/bo/admin/jobs'
      preLoaderRoute: typeof BoAdminJobsRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/admin/time': {
      id: '/bo/admin/time'
      path: '/admin/time'
      fullPath: '/bo/admin/time'
      preLoaderRoute: typeof BoAdminTimeRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/documentation/activity-specific-questions': {
      id: '/bo/documentation/activity-specific-questions'
      path: '/documentation/activity-specific-questions'
      fullPath: '/bo/documentation/activity-specific-questions'
      preLoaderRoute: typeof BoDocumentationActivitySpecificQuestionsRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/documentation/exclusions': {
      id: '/bo/documentation/exclusions'
      path: '/documentation/exclusions'
      fullPath: '/bo/documentation/exclusions'
      preLoaderRoute: typeof BoDocumentationExclusionsRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/documentation/general-terms': {
      id: '/bo/documentation/general-terms'
      path: '/documentation/general-terms'
      fullPath: '/bo/documentation/general-terms'
      preLoaderRoute: typeof BoDocumentationGeneralTermsRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/documentation/roles': {
      id: '/bo/documentation/roles'
      path: '/documentation/roles'
      fullPath: '/bo/documentation/roles'
      preLoaderRoute: typeof BoDocumentationRolesRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/documentation/subscription-funnel-texts': {
      id: '/bo/documentation/subscription-funnel-texts'
      path: '/documentation/subscription-funnel-texts'
      fullPath: '/bo/documentation/subscription-funnel-texts'
      preLoaderRoute: typeof BoDocumentationSubscriptionFunnelTextsRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/invoicing/$invoiceId': {
      id: '/bo/invoicing/$invoiceId'
      path: '/invoicing/$invoiceId'
      fullPath: '/bo/invoicing/$invoiceId'
      preLoaderRoute: typeof BoInvoicingInvoiceIdRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/invoicing/negative-invoices': {
      id: '/bo/invoicing/negative-invoices'
      path: '/invoicing/negative-invoices'
      fullPath: '/bo/invoicing/negative-invoices'
      preLoaderRoute: typeof BoInvoicingNegativeInvoicesRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/subscriptions/new': {
      id: '/bo/subscriptions/new'
      path: '/subscriptions/new'
      fullPath: '/bo/subscriptions/new'
      preLoaderRoute: typeof BoSubscriptionsNewRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/users/$userId': {
      id: '/bo/users/$userId'
      path: '/users/$userId'
      fullPath: '/bo/users/$userId'
      preLoaderRoute: typeof BoUsersUserIdRouteImport
      parentRoute: typeof BoImport
    }
    '/s/c/$token': {
      id: '/s/c/$token'
      path: '/s/c/$token'
      fullPath: '/s/c/$token'
      preLoaderRoute: typeof SCTokenRouteImport
      parentRoute: typeof rootRoute
    }
    '/s/e-v2/$token': {
      id: '/s/e-v2/$token'
      path: '/s/e-v2/$token'
      fullPath: '/s/e-v2/$token'
      preLoaderRoute: typeof SEV2TokenRouteImport
      parentRoute: typeof rootRoute
    }
    '/s/q/$token': {
      id: '/s/q/$token'
      path: '/s/q/$token'
      fullPath: '/s/q/$token'
      preLoaderRoute: typeof SQTokenRouteImport
      parentRoute: typeof rootRoute
    }
    '/subscribe/restaurants-v2/identify': {
      id: '/subscribe/restaurants-v2/identify'
      path: '/subscribe/restaurants-v2/identify'
      fullPath: '/subscribe/restaurants-v2/identify'
      preLoaderRoute: typeof SubscribeRestaurantsV2IdentifyRouteImport
      parentRoute: typeof rootRoute
    }
    '/_customer/health-insurance/': {
      id: '/_customer/health-insurance/'
      path: '/health-insurance'
      fullPath: '/health-insurance'
      preLoaderRoute: typeof CustomerHealthInsuranceIndexImport
      parentRoute: typeof CustomerImport
    }
    '/bo/admin/': {
      id: '/bo/admin/'
      path: '/admin'
      fullPath: '/bo/admin'
      preLoaderRoute: typeof BoAdminIndexImport
      parentRoute: typeof BoImport
    }
    '/bo/documentation/': {
      id: '/bo/documentation/'
      path: '/documentation'
      fullPath: '/bo/documentation'
      preLoaderRoute: typeof BoDocumentationIndexImport
      parentRoute: typeof BoImport
    }
    '/bo/invoicing/': {
      id: '/bo/invoicing/'
      path: '/invoicing'
      fullPath: '/bo/invoicing'
      preLoaderRoute: typeof BoInvoicingIndexImport
      parentRoute: typeof BoImport
    }
    '/bo/subscriptions/': {
      id: '/bo/subscriptions/'
      path: '/subscriptions'
      fullPath: '/bo/subscriptions'
      preLoaderRoute: typeof BoSubscriptionsIndexImport
      parentRoute: typeof BoImport
    }
    '/bo/users/': {
      id: '/bo/users/'
      path: '/users'
      fullPath: '/bo/users'
      preLoaderRoute: typeof BoUsersIndexImport
      parentRoute: typeof BoImport
    }
    '/(redirects)/bak/admin/cpms': {
      id: '/(redirects)/bak/admin/cpms'
      path: '/bak/admin/cpms'
      fullPath: '/bak/admin/cpms'
      preLoaderRoute: typeof redirectsBakAdminCpmsRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/admin/ima': {
      id: '/(redirects)/bak/admin/ima'
      path: '/bak/admin/ima'
      fullPath: '/bak/admin/ima'
      preLoaderRoute: typeof redirectsBakAdminImaRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/admin/jobs': {
      id: '/(redirects)/bak/admin/jobs'
      path: '/bak/admin/jobs'
      fullPath: '/bak/admin/jobs'
      preLoaderRoute: typeof redirectsBakAdminJobsRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/documentation/activity-specific-questions': {
      id: '/(redirects)/bak/documentation/activity-specific-questions'
      path: '/bak/documentation/activity-specific-questions'
      fullPath: '/bak/documentation/activity-specific-questions'
      preLoaderRoute: typeof redirectsBakDocumentationActivitySpecificQuestionsRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/documentation/exclusions': {
      id: '/(redirects)/bak/documentation/exclusions'
      path: '/bak/documentation/exclusions'
      fullPath: '/bak/documentation/exclusions'
      preLoaderRoute: typeof redirectsBakDocumentationExclusionsRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/documentation/general-terms': {
      id: '/(redirects)/bak/documentation/general-terms'
      path: '/bak/documentation/general-terms'
      fullPath: '/bak/documentation/general-terms'
      preLoaderRoute: typeof redirectsBakDocumentationGeneralTermsRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/documentation/roles': {
      id: '/(redirects)/bak/documentation/roles'
      path: '/bak/documentation/roles'
      fullPath: '/bak/documentation/roles'
      preLoaderRoute: typeof redirectsBakDocumentationRolesRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/documentation/subscription-funnel-texts': {
      id: '/(redirects)/bak/documentation/subscription-funnel-texts'
      path: '/bak/documentation/subscription-funnel-texts'
      fullPath: '/bak/documentation/subscription-funnel-texts'
      preLoaderRoute: typeof redirectsBakDocumentationSubscriptionFunnelTextsRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/invoicing/negative-invoices-list': {
      id: '/(redirects)/bak/invoicing/negative-invoices-list'
      path: '/bak/invoicing/negative-invoices-list'
      fullPath: '/bak/invoicing/negative-invoices-list'
      preLoaderRoute: typeof redirectsBakInvoicingNegativeInvoicesListRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/users/$userId': {
      id: '/(redirects)/bak/users/$userId'
      path: '/bak/users/$userId'
      fullPath: '/bak/users/$userId'
      preLoaderRoute: typeof redirectsBakUsersUserIdRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/partner/$organization/home': {
      id: '/(redirects)/partner/$organization/home'
      path: '/partner/$organization/home'
      fullPath: '/partner/$organization/home'
      preLoaderRoute: typeof redirectsPartnerOrganizationHomeRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/partner/$organization/new-subscription': {
      id: '/(redirects)/partner/$organization/new-subscription'
      path: '/partner/$organization/new-subscription'
      fullPath: '/partner/$organization/new-subscription'
      preLoaderRoute: typeof redirectsPartnerOrganizationNewSubscriptionRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/partner/$organization/search': {
      id: '/(redirects)/partner/$organization/search'
      path: '/partner/$organization/search'
      fullPath: '/partner/$organization/search'
      preLoaderRoute: typeof redirectsPartnerOrganizationSearchRouteImport
      parentRoute: typeof rootRoute
    }
    '/_customer/product-guarantees/$subscriptionId/$productName': {
      id: '/_customer/product-guarantees/$subscriptionId/$productName'
      path: '/product-guarantees/$subscriptionId/$productName'
      fullPath: '/product-guarantees/$subscriptionId/$productName'
      preLoaderRoute: typeof CustomerProductGuaranteesSubscriptionIdProductNameRouteImport
      parentRoute: typeof CustomerImport
    }
    '/_customer/s/r/$token': {
      id: '/_customer/s/r/$token'
      path: '/s/r/$token'
      fullPath: '/s/r/$token'
      preLoaderRoute: typeof CustomerSRTokenRouteImport
      parentRoute: typeof CustomerImport
    }
    '/bo/contracts/$subscriptionId/status': {
      id: '/bo/contracts/$subscriptionId/status'
      path: '/contracts/$subscriptionId/status'
      fullPath: '/bo/contracts/$subscriptionId/status'
      preLoaderRoute: typeof BoContractsSubscriptionIdStatusRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/subscriptions/$subscriptionId/detailed-sheet': {
      id: '/bo/subscriptions/$subscriptionId/detailed-sheet'
      path: '/subscriptions/$subscriptionId/detailed-sheet'
      fullPath: '/bo/subscriptions/$subscriptionId/detailed-sheet'
      preLoaderRoute: typeof BoSubscriptionsSubscriptionIdDetailedSheetRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/subscriptions/$subscriptionId/history': {
      id: '/bo/subscriptions/$subscriptionId/history'
      path: '/subscriptions/$subscriptionId/history'
      fullPath: '/bo/subscriptions/$subscriptionId/history'
      preLoaderRoute: typeof BoSubscriptionsSubscriptionIdHistoryRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/subscriptions/$subscriptionId/payment': {
      id: '/bo/subscriptions/$subscriptionId/payment'
      path: '/subscriptions/$subscriptionId/payment'
      fullPath: '/bo/subscriptions/$subscriptionId/payment'
      preLoaderRoute: typeof BoSubscriptionsSubscriptionIdPaymentRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/troubleshooting/sessions/$sessionId': {
      id: '/bo/troubleshooting/sessions/$sessionId'
      path: '/troubleshooting/sessions/$sessionId'
      fullPath: '/bo/troubleshooting/sessions/$sessionId'
      preLoaderRoute: typeof BoTroubleshootingSessionsSessionIdRouteImport
      parentRoute: typeof BoImport
    }
    '/(redirects)/bak/admin/': {
      id: '/(redirects)/bak/admin/'
      path: '/bak/admin'
      fullPath: '/bak/admin'
      preLoaderRoute: typeof redirectsBakAdminIndexImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/documentation/': {
      id: '/(redirects)/bak/documentation/'
      path: '/bak/documentation'
      fullPath: '/bak/documentation'
      preLoaderRoute: typeof redirectsBakDocumentationIndexImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/invoicing/': {
      id: '/(redirects)/bak/invoicing/'
      path: '/bak/invoicing'
      fullPath: '/bak/invoicing'
      preLoaderRoute: typeof redirectsBakInvoicingIndexImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/pending-subscriptions/': {
      id: '/(redirects)/bak/pending-subscriptions/'
      path: '/bak/pending-subscriptions'
      fullPath: '/bak/pending-subscriptions'
      preLoaderRoute: typeof redirectsBakPendingSubscriptionsIndexImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/users/': {
      id: '/(redirects)/bak/users/'
      path: '/bak/users'
      fullPath: '/bak/users'
      preLoaderRoute: typeof redirectsBakUsersIndexImport
      parentRoute: typeof rootRoute
    }
    '/bo/claims/$claimId/': {
      id: '/bo/claims/$claimId/'
      path: '/claims/$claimId'
      fullPath: '/bo/claims/$claimId'
      preLoaderRoute: typeof BoClaimsClaimIdIndexImport
      parentRoute: typeof BoImport
    }
    '/bo/contracts/$subscriptionId/': {
      id: '/bo/contracts/$subscriptionId/'
      path: '/contracts/$subscriptionId'
      fullPath: '/bo/contracts/$subscriptionId'
      preLoaderRoute: typeof BoContractsSubscriptionIdIndexImport
      parentRoute: typeof BoImport
    }
    '/bo/subscriptions/$subscriptionId/': {
      id: '/bo/subscriptions/$subscriptionId/'
      path: '/subscriptions/$subscriptionId'
      fullPath: '/bo/subscriptions/$subscriptionId'
      preLoaderRoute: typeof BoSubscriptionsSubscriptionIdIndexImport
      parentRoute: typeof BoImport
    }
    '/subscribe/$subscriptionId/$stepId/': {
      id: '/subscribe/$subscriptionId/$stepId/'
      path: '/subscribe/$subscriptionId/$stepId'
      fullPath: '/subscribe/$subscriptionId/$stepId'
      preLoaderRoute: typeof SubscribeSubscriptionIdStepIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/contracts/$subscriptionId/invoicing': {
      id: '/(redirects)/bak/contracts/$subscriptionId/invoicing'
      path: '/bak/contracts/$subscriptionId/invoicing'
      fullPath: '/bak/contracts/$subscriptionId/invoicing'
      preLoaderRoute: typeof redirectsBakContractsSubscriptionIdInvoicingRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/contracts/$subscriptionId/new-invoice': {
      id: '/(redirects)/bak/contracts/$subscriptionId/new-invoice'
      path: '/bak/contracts/$subscriptionId/new-invoice'
      fullPath: '/bak/contracts/$subscriptionId/new-invoice'
      preLoaderRoute: typeof redirectsBakContractsSubscriptionIdNewInvoiceRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/contracts/$subscriptionId/status': {
      id: '/(redirects)/bak/contracts/$subscriptionId/status'
      path: '/bak/contracts/$subscriptionId/status'
      fullPath: '/bak/contracts/$subscriptionId/status'
      preLoaderRoute: typeof redirectsBakContractsSubscriptionIdStatusRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/pending-subscriptions/$subscriptionId/history': {
      id: '/(redirects)/bak/pending-subscriptions/$subscriptionId/history'
      path: '/bak/pending-subscriptions/$subscriptionId/history'
      fullPath: '/bak/pending-subscriptions/$subscriptionId/history'
      preLoaderRoute: typeof redirectsBakPendingSubscriptionsSubscriptionIdHistoryRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/pending-subscriptions/$subscriptionId/payment': {
      id: '/(redirects)/bak/pending-subscriptions/$subscriptionId/payment'
      path: '/bak/pending-subscriptions/$subscriptionId/payment'
      fullPath: '/bak/pending-subscriptions/$subscriptionId/payment'
      preLoaderRoute: typeof redirectsBakPendingSubscriptionsSubscriptionIdPaymentRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/troubleshooting/session/$sessionId': {
      id: '/(redirects)/bak/troubleshooting/session/$sessionId'
      path: '/bak/troubleshooting/session/$sessionId'
      fullPath: '/bak/troubleshooting/session/$sessionId'
      preLoaderRoute: typeof redirectsBakTroubleshootingSessionSessionIdRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet': {
      id: '/(redirects)/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet'
      path: '/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet'
      fullPath: '/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet'
      preLoaderRoute: typeof redirectsBakV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/partner/$organization/claims/$claimId': {
      id: '/(redirects)/partner/$organization/claims/$claimId'
      path: '/partner/$organization/claims/$claimId'
      fullPath: '/partner/$organization/claims/$claimId'
      preLoaderRoute: typeof redirectsPartnerOrganizationClaimsClaimIdRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/partner/$organization/contracts/$subscriptionId': {
      id: '/(redirects)/partner/$organization/contracts/$subscriptionId'
      path: '/partner/$organization/contracts/$subscriptionId'
      fullPath: '/partner/$organization/contracts/$subscriptionId'
      preLoaderRoute: typeof redirectsPartnerOrganizationContractsSubscriptionIdRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/partner/$organization/users/$userId': {
      id: '/(redirects)/partner/$organization/users/$userId'
      path: '/partner/$organization/users/$userId'
      fullPath: '/partner/$organization/users/$userId'
      preLoaderRoute: typeof redirectsPartnerOrganizationUsersUserIdRouteImport
      parentRoute: typeof rootRoute
    }
    '/bo/contracts/$subscriptionId/invoices/debug': {
      id: '/bo/contracts/$subscriptionId/invoices/debug'
      path: '/contracts/$subscriptionId/invoices/debug'
      fullPath: '/bo/contracts/$subscriptionId/invoices/debug'
      preLoaderRoute: typeof BoContractsSubscriptionIdInvoicesDebugRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/contracts/$subscriptionId/invoices/new': {
      id: '/bo/contracts/$subscriptionId/invoices/new'
      path: '/contracts/$subscriptionId/invoices/new'
      fullPath: '/bo/contracts/$subscriptionId/invoices/new'
      preLoaderRoute: typeof BoContractsSubscriptionIdInvoicesNewRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/contracts/$subscriptionId/renewals/$endorsementId': {
      id: '/bo/contracts/$subscriptionId/renewals/$endorsementId'
      path: '/contracts/$subscriptionId/renewals/$endorsementId'
      fullPath: '/bo/contracts/$subscriptionId/renewals/$endorsementId'
      preLoaderRoute: typeof BoContractsSubscriptionIdRenewalsEndorsementIdRouteImport
      parentRoute: typeof BoImport
    }
    '/subscribe/$subscriptionId/$stepId/$detailType/$detailId': {
      id: '/subscribe/$subscriptionId/$stepId/$detailType/$detailId'
      path: '/subscribe/$subscriptionId/$stepId/$detailType/$detailId'
      fullPath: '/subscribe/$subscriptionId/$stepId/$detailType/$detailId'
      preLoaderRoute: typeof SubscribeSubscriptionIdStepIdDetailTypeDetailIdRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/claims/$claimId/': {
      id: '/(redirects)/bak/claims/$claimId/'
      path: '/bak/claims/$claimId'
      fullPath: '/bak/claims/$claimId'
      preLoaderRoute: typeof redirectsBakClaimsClaimIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/contracts/$subscriptionId/': {
      id: '/(redirects)/bak/contracts/$subscriptionId/'
      path: '/bak/contracts/$subscriptionId'
      fullPath: '/bak/contracts/$subscriptionId'
      preLoaderRoute: typeof redirectsBakContractsSubscriptionIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/v2-pending-subscriptions/$subscriptionId/': {
      id: '/(redirects)/bak/v2-pending-subscriptions/$subscriptionId/'
      path: '/bak/v2-pending-subscriptions/$subscriptionId'
      fullPath: '/bak/v2-pending-subscriptions/$subscriptionId'
      preLoaderRoute: typeof redirectsBakV2PendingSubscriptionsSubscriptionIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/partner/$organization/pending-subscriptions/': {
      id: '/(redirects)/partner/$organization/pending-subscriptions/'
      path: '/partner/$organization/pending-subscriptions'
      fullPath: '/partner/$organization/pending-subscriptions'
      preLoaderRoute: typeof redirectsPartnerOrganizationPendingSubscriptionsIndexImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/partner/$organization/users/': {
      id: '/(redirects)/partner/$organization/users/'
      path: '/partner/$organization/users'
      fullPath: '/partner/$organization/users'
      preLoaderRoute: typeof redirectsPartnerOrganizationUsersIndexImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/contracts/$subscriptionId/renewal/$endorsementId': {
      id: '/(redirects)/bak/contracts/$subscriptionId/renewal/$endorsementId'
      path: '/bak/contracts/$subscriptionId/renewal/$endorsementId'
      fullPath: '/bak/contracts/$subscriptionId/renewal/$endorsementId'
      preLoaderRoute: typeof redirectsBakContractsSubscriptionIdRenewalEndorsementIdRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/partner/$organization/pending-subscriptions/$subscriptionId/payment': {
      id: '/(redirects)/partner/$organization/pending-subscriptions/$subscriptionId/payment'
      path: '/partner/$organization/pending-subscriptions/$subscriptionId/payment'
      fullPath: '/partner/$organization/pending-subscriptions/$subscriptionId/payment'
      preLoaderRoute: typeof redirectsPartnerOrganizationPendingSubscriptionsSubscriptionIdPaymentRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/partner/$organization/v2-pending-subscriptions/$subscriptionId/detailed-sheet': {
      id: '/(redirects)/partner/$organization/v2-pending-subscriptions/$subscriptionId/detailed-sheet'
      path: '/partner/$organization/v2-pending-subscriptions/$subscriptionId/detailed-sheet'
      fullPath: '/partner/$organization/v2-pending-subscriptions/$subscriptionId/detailed-sheet'
      preLoaderRoute: typeof redirectsPartnerOrganizationV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteImport
      parentRoute: typeof rootRoute
    }
    '/bo/contracts/$subscriptionId/detailed-sheet/versions/$versionId': {
      id: '/bo/contracts/$subscriptionId/detailed-sheet/versions/$versionId'
      path: '/contracts/$subscriptionId/detailed-sheet/versions/$versionId'
      fullPath: '/bo/contracts/$subscriptionId/detailed-sheet/versions/$versionId'
      preLoaderRoute: typeof BoContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteImport
      parentRoute: typeof BoImport
    }
    '/bo/contracts/$subscriptionId/endorsements/$endorsementId/detailed-sheet': {
      id: '/bo/contracts/$subscriptionId/endorsements/$endorsementId/detailed-sheet'
      path: '/contracts/$subscriptionId/endorsements/$endorsementId/detailed-sheet'
      fullPath: '/bo/contracts/$subscriptionId/endorsements/$endorsementId/detailed-sheet'
      preLoaderRoute: typeof BoContractsSubscriptionIdEndorsementsEndorsementIdDetailedSheetRouteImport
      parentRoute: typeof BoImport
    }
    '/(redirects)/partner/$organization/v2-pending-subscriptions/$subscriptionId/': {
      id: '/(redirects)/partner/$organization/v2-pending-subscriptions/$subscriptionId/'
      path: '/partner/$organization/v2-pending-subscriptions/$subscriptionId'
      fullPath: '/partner/$organization/v2-pending-subscriptions/$subscriptionId'
      preLoaderRoute: typeof redirectsPartnerOrganizationV2PendingSubscriptionsSubscriptionIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/bo/contracts/$subscriptionId/endorsements/$endorsementId/': {
      id: '/bo/contracts/$subscriptionId/endorsements/$endorsementId/'
      path: '/contracts/$subscriptionId/endorsements/$endorsementId'
      fullPath: '/bo/contracts/$subscriptionId/endorsements/$endorsementId'
      preLoaderRoute: typeof BoContractsSubscriptionIdEndorsementsEndorsementIdIndexImport
      parentRoute: typeof BoImport
    }
    '/(redirects)/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId': {
      id: '/(redirects)/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId'
      path: '/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId'
      fullPath: '/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId'
      preLoaderRoute: typeof redirectsBakContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet': {
      id: '/(redirects)/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet'
      path: '/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet'
      fullPath: '/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet'
      preLoaderRoute: typeof redirectsBakContractsSubscriptionIdEndorsementEndorsementIdDetailedSheetRouteImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/bak/contracts/$subscriptionId/endorsement/$endorsementId/': {
      id: '/(redirects)/bak/contracts/$subscriptionId/endorsement/$endorsementId/'
      path: '/bak/contracts/$subscriptionId/endorsement/$endorsementId'
      fullPath: '/bak/contracts/$subscriptionId/endorsement/$endorsementId'
      preLoaderRoute: typeof redirectsBakContractsSubscriptionIdEndorsementEndorsementIdIndexImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

interface CustomerRouteChildren {
  CustomerAccountRouteRoute: typeof CustomerAccountRouteRoute
  CustomerContractGuaranteesRouteRoute: typeof CustomerContractGuaranteesRouteRoute
  CustomerContractsRouteRoute: typeof CustomerContractsRouteRoute
  CustomerHomeRouteRoute: typeof CustomerHomeRouteRoute
  CustomerInvoicesRouteRoute: typeof CustomerInvoicesRouteRoute
  CustomerReportClaimRouteRoute: typeof CustomerReportClaimRouteRoute
  CustomerContractSubscriptionIdRouteRoute: typeof CustomerContractSubscriptionIdRouteRoute
  CustomerHealthInsuranceBeneficiariesRouteRoute: typeof CustomerHealthInsuranceBeneficiariesRouteRoute
  CustomerHealthInsuranceCpmsRouteRoute: typeof CustomerHealthInsuranceCpmsRouteRoute
  CustomerHealthInsuranceSanteclairRouteRoute: typeof CustomerHealthInsuranceSanteclairRouteRoute
  CustomerInsuranceCertificateGeneratorRealEstateAgentsSubscriptionIdRouteRoute: typeof CustomerInsuranceCertificateGeneratorRealEstateAgentsSubscriptionIdRouteRoute
  CustomerInsuranceCertificateGeneratorRealEstateSalesAgentsSubscriptionIdRouteRoute: typeof CustomerInsuranceCertificateGeneratorRealEstateSalesAgentsSubscriptionIdRouteRoute
  CustomerPaymentMethodsNewRouteRoute: typeof CustomerPaymentMethodsNewRouteRoute
  CustomerPaymentMethodsUpdateRouteRoute: typeof CustomerPaymentMethodsUpdateRouteRoute
  CustomerHealthInsuranceIndexRoute: typeof CustomerHealthInsuranceIndexRoute
  CustomerProductGuaranteesSubscriptionIdProductNameRouteRoute: typeof CustomerProductGuaranteesSubscriptionIdProductNameRouteRoute
  CustomerSRTokenRouteRoute: typeof CustomerSRTokenRouteRoute
}

const CustomerRouteChildren: CustomerRouteChildren = {
  CustomerAccountRouteRoute: CustomerAccountRouteRoute,
  CustomerContractGuaranteesRouteRoute: CustomerContractGuaranteesRouteRoute,
  CustomerContractsRouteRoute: CustomerContractsRouteRoute,
  CustomerHomeRouteRoute: CustomerHomeRouteRoute,
  CustomerInvoicesRouteRoute: CustomerInvoicesRouteRoute,
  CustomerReportClaimRouteRoute: CustomerReportClaimRouteRoute,
  CustomerContractSubscriptionIdRouteRoute:
    CustomerContractSubscriptionIdRouteRoute,
  CustomerHealthInsuranceBeneficiariesRouteRoute:
    CustomerHealthInsuranceBeneficiariesRouteRoute,
  CustomerHealthInsuranceCpmsRouteRoute: CustomerHealthInsuranceCpmsRouteRoute,
  CustomerHealthInsuranceSanteclairRouteRoute:
    CustomerHealthInsuranceSanteclairRouteRoute,
  CustomerInsuranceCertificateGeneratorRealEstateAgentsSubscriptionIdRouteRoute:
    CustomerInsuranceCertificateGeneratorRealEstateAgentsSubscriptionIdRouteRoute,
  CustomerInsuranceCertificateGeneratorRealEstateSalesAgentsSubscriptionIdRouteRoute:
    CustomerInsuranceCertificateGeneratorRealEstateSalesAgentsSubscriptionIdRouteRoute,
  CustomerPaymentMethodsNewRouteRoute: CustomerPaymentMethodsNewRouteRoute,
  CustomerPaymentMethodsUpdateRouteRoute:
    CustomerPaymentMethodsUpdateRouteRoute,
  CustomerHealthInsuranceIndexRoute: CustomerHealthInsuranceIndexRoute,
  CustomerProductGuaranteesSubscriptionIdProductNameRouteRoute:
    CustomerProductGuaranteesSubscriptionIdProductNameRouteRoute,
  CustomerSRTokenRouteRoute: CustomerSRTokenRouteRoute,
}

const CustomerRouteWithChildren = CustomerRoute._addFileChildren(
  CustomerRouteChildren,
)

interface BoRouteChildren {
  BoActivitiesRouteRoute: typeof BoActivitiesRouteRoute
  BoEphemeralJobsRouteRoute: typeof BoEphemeralJobsRouteRoute
  BoMembershipsRouteRoute: typeof BoMembershipsRouteRoute
  BoMigrationRouteRoute: typeof BoMigrationRouteRoute
  BoOrganizationsRouteRoute: typeof BoOrganizationsRouteRoute
  BoSearchRouteRoute: typeof BoSearchRouteRoute
  BoSearchV2RouteRoute: typeof BoSearchV2RouteRoute
  BoTrackingDocRouteRoute: typeof BoTrackingDocRouteRoute
  BoIndexRoute: typeof BoIndexRoute
  BoAdminCpmsRouteRoute: typeof BoAdminCpmsRouteRoute
  BoAdminImaRouteRoute: typeof BoAdminImaRouteRoute
  BoAdminJobsRouteRoute: typeof BoAdminJobsRouteRoute
  BoAdminTimeRouteRoute: typeof BoAdminTimeRouteRoute
  BoDocumentationActivitySpecificQuestionsRouteRoute: typeof BoDocumentationActivitySpecificQuestionsRouteRoute
  BoDocumentationExclusionsRouteRoute: typeof BoDocumentationExclusionsRouteRoute
  BoDocumentationGeneralTermsRouteRoute: typeof BoDocumentationGeneralTermsRouteRoute
  BoDocumentationRolesRouteRoute: typeof BoDocumentationRolesRouteRoute
  BoDocumentationSubscriptionFunnelTextsRouteRoute: typeof BoDocumentationSubscriptionFunnelTextsRouteRoute
  BoInvoicingInvoiceIdRouteRoute: typeof BoInvoicingInvoiceIdRouteRoute
  BoInvoicingNegativeInvoicesRouteRoute: typeof BoInvoicingNegativeInvoicesRouteRoute
  BoSubscriptionsNewRouteRoute: typeof BoSubscriptionsNewRouteRoute
  BoUsersUserIdRouteRoute: typeof BoUsersUserIdRouteRoute
  BoAdminIndexRoute: typeof BoAdminIndexRoute
  BoDocumentationIndexRoute: typeof BoDocumentationIndexRoute
  BoInvoicingIndexRoute: typeof BoInvoicingIndexRoute
  BoSubscriptionsIndexRoute: typeof BoSubscriptionsIndexRoute
  BoUsersIndexRoute: typeof BoUsersIndexRoute
  BoContractsSubscriptionIdStatusRouteRoute: typeof BoContractsSubscriptionIdStatusRouteRoute
  BoSubscriptionsSubscriptionIdDetailedSheetRouteRoute: typeof BoSubscriptionsSubscriptionIdDetailedSheetRouteRoute
  BoSubscriptionsSubscriptionIdHistoryRouteRoute: typeof BoSubscriptionsSubscriptionIdHistoryRouteRoute
  BoSubscriptionsSubscriptionIdPaymentRouteRoute: typeof BoSubscriptionsSubscriptionIdPaymentRouteRoute
  BoTroubleshootingSessionsSessionIdRouteRoute: typeof BoTroubleshootingSessionsSessionIdRouteRoute
  BoClaimsClaimIdIndexRoute: typeof BoClaimsClaimIdIndexRoute
  BoContractsSubscriptionIdIndexRoute: typeof BoContractsSubscriptionIdIndexRoute
  BoSubscriptionsSubscriptionIdIndexRoute: typeof BoSubscriptionsSubscriptionIdIndexRoute
  BoContractsSubscriptionIdInvoicesDebugRouteRoute: typeof BoContractsSubscriptionIdInvoicesDebugRouteRoute
  BoContractsSubscriptionIdInvoicesNewRouteRoute: typeof BoContractsSubscriptionIdInvoicesNewRouteRoute
  BoContractsSubscriptionIdRenewalsEndorsementIdRouteRoute: typeof BoContractsSubscriptionIdRenewalsEndorsementIdRouteRoute
  BoContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute: typeof BoContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute
  BoContractsSubscriptionIdEndorsementsEndorsementIdDetailedSheetRouteRoute: typeof BoContractsSubscriptionIdEndorsementsEndorsementIdDetailedSheetRouteRoute
  BoContractsSubscriptionIdEndorsementsEndorsementIdIndexRoute: typeof BoContractsSubscriptionIdEndorsementsEndorsementIdIndexRoute
}

const BoRouteChildren: BoRouteChildren = {
  BoActivitiesRouteRoute: BoActivitiesRouteRoute,
  BoEphemeralJobsRouteRoute: BoEphemeralJobsRouteRoute,
  BoMembershipsRouteRoute: BoMembershipsRouteRoute,
  BoMigrationRouteRoute: BoMigrationRouteRoute,
  BoOrganizationsRouteRoute: BoOrganizationsRouteRoute,
  BoSearchRouteRoute: BoSearchRouteRoute,
  BoSearchV2RouteRoute: BoSearchV2RouteRoute,
  BoTrackingDocRouteRoute: BoTrackingDocRouteRoute,
  BoIndexRoute: BoIndexRoute,
  BoAdminCpmsRouteRoute: BoAdminCpmsRouteRoute,
  BoAdminImaRouteRoute: BoAdminImaRouteRoute,
  BoAdminJobsRouteRoute: BoAdminJobsRouteRoute,
  BoAdminTimeRouteRoute: BoAdminTimeRouteRoute,
  BoDocumentationActivitySpecificQuestionsRouteRoute:
    BoDocumentationActivitySpecificQuestionsRouteRoute,
  BoDocumentationExclusionsRouteRoute: BoDocumentationExclusionsRouteRoute,
  BoDocumentationGeneralTermsRouteRoute: BoDocumentationGeneralTermsRouteRoute,
  BoDocumentationRolesRouteRoute: BoDocumentationRolesRouteRoute,
  BoDocumentationSubscriptionFunnelTextsRouteRoute:
    BoDocumentationSubscriptionFunnelTextsRouteRoute,
  BoInvoicingInvoiceIdRouteRoute: BoInvoicingInvoiceIdRouteRoute,
  BoInvoicingNegativeInvoicesRouteRoute: BoInvoicingNegativeInvoicesRouteRoute,
  BoSubscriptionsNewRouteRoute: BoSubscriptionsNewRouteRoute,
  BoUsersUserIdRouteRoute: BoUsersUserIdRouteRoute,
  BoAdminIndexRoute: BoAdminIndexRoute,
  BoDocumentationIndexRoute: BoDocumentationIndexRoute,
  BoInvoicingIndexRoute: BoInvoicingIndexRoute,
  BoSubscriptionsIndexRoute: BoSubscriptionsIndexRoute,
  BoUsersIndexRoute: BoUsersIndexRoute,
  BoContractsSubscriptionIdStatusRouteRoute:
    BoContractsSubscriptionIdStatusRouteRoute,
  BoSubscriptionsSubscriptionIdDetailedSheetRouteRoute:
    BoSubscriptionsSubscriptionIdDetailedSheetRouteRoute,
  BoSubscriptionsSubscriptionIdHistoryRouteRoute:
    BoSubscriptionsSubscriptionIdHistoryRouteRoute,
  BoSubscriptionsSubscriptionIdPaymentRouteRoute:
    BoSubscriptionsSubscriptionIdPaymentRouteRoute,
  BoTroubleshootingSessionsSessionIdRouteRoute:
    BoTroubleshootingSessionsSessionIdRouteRoute,
  BoClaimsClaimIdIndexRoute: BoClaimsClaimIdIndexRoute,
  BoContractsSubscriptionIdIndexRoute: BoContractsSubscriptionIdIndexRoute,
  BoSubscriptionsSubscriptionIdIndexRoute:
    BoSubscriptionsSubscriptionIdIndexRoute,
  BoContractsSubscriptionIdInvoicesDebugRouteRoute:
    BoContractsSubscriptionIdInvoicesDebugRouteRoute,
  BoContractsSubscriptionIdInvoicesNewRouteRoute:
    BoContractsSubscriptionIdInvoicesNewRouteRoute,
  BoContractsSubscriptionIdRenewalsEndorsementIdRouteRoute:
    BoContractsSubscriptionIdRenewalsEndorsementIdRouteRoute,
  BoContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute:
    BoContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute,
  BoContractsSubscriptionIdEndorsementsEndorsementIdDetailedSheetRouteRoute:
    BoContractsSubscriptionIdEndorsementsEndorsementIdDetailedSheetRouteRoute,
  BoContractsSubscriptionIdEndorsementsEndorsementIdIndexRoute:
    BoContractsSubscriptionIdEndorsementsEndorsementIdIndexRoute,
}

const BoRouteWithChildren = BoRoute._addFileChildren(BoRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/impersonate': typeof ImpersonateRouteRoute
  '/login': typeof LoginRouteRoute
  '/payment-method-update': typeof PaymentMethodUpdateRouteRoute
  '/receive-quote': typeof ReceiveQuoteRouteRoute
  '/search': typeof SearchRouteRoute
  '/subscribe-no-possible-step': typeof SubscribeNoPossibleStepRouteRoute
  '/unsubscribe': typeof UnsubscribeRouteRoute
  '': typeof CustomerRouteWithChildren
  '/bo': typeof BoRouteWithChildren
  '/account': typeof CustomerAccountRouteRoute
  '/contract-guarantees': typeof CustomerContractGuaranteesRouteRoute
  '/contracts': typeof CustomerContractsRouteRoute
  '/home': typeof CustomerHomeRouteRoute
  '/invoices': typeof CustomerInvoicesRouteRoute
  '/report-claim': typeof CustomerReportClaimRouteRoute
  '/bo/activities': typeof BoActivitiesRouteRoute
  '/bo/ephemeral-jobs': typeof BoEphemeralJobsRouteRoute
  '/bo/memberships': typeof BoMembershipsRouteRoute
  '/bo/migration': typeof BoMigrationRouteRoute
  '/bo/organizations': typeof BoOrganizationsRouteRoute
  '/bo/search': typeof BoSearchRouteRoute
  '/bo/searchV2': typeof BoSearchV2RouteRoute
  '/bo/tracking-doc': typeof BoTrackingDocRouteRoute
  '/payment/authentication': typeof PaymentAuthenticationRouteRoute
  '/subscribe-exclusion/$subscriptionId': typeof SubscribeExclusionSubscriptionIdRouteRoute
  '/subscribe/landing': typeof SubscribeLandingRouteRoute
  '/bo/': typeof BoIndexRoute
  '/bak/activities': typeof redirectsBakActivitiesRouteRoute
  '/bak/ephemeral-jobs': typeof redirectsBakEphemeralJobsRouteRoute
  '/bak/home': typeof redirectsBakHomeRouteRoute
  '/bak/memberships': typeof redirectsBakMembershipsRouteRoute
  '/bak/migration': typeof redirectsBakMigrationRouteRoute
  '/bak/new-subscription': typeof redirectsBakNewSubscriptionRouteRoute
  '/bak/organizations': typeof redirectsBakOrganizationsRouteRoute
  '/bak/search': typeof redirectsBakSearchRouteRoute
  '/bak/tracking-doc': typeof redirectsBakTrackingDocRouteRoute
  '/contract/$subscriptionId': typeof CustomerContractSubscriptionIdRouteRoute
  '/health-insurance/beneficiaries': typeof CustomerHealthInsuranceBeneficiariesRouteRoute
  '/health-insurance/cpms': typeof CustomerHealthInsuranceCpmsRouteRoute
  '/health-insurance/santeclair': typeof CustomerHealthInsuranceSanteclairRouteRoute
  '/insurance-certificate-generator-real-estate-agents/$subscriptionId': typeof CustomerInsuranceCertificateGeneratorRealEstateAgentsSubscriptionIdRouteRoute
  '/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId': typeof CustomerInsuranceCertificateGeneratorRealEstateSalesAgentsSubscriptionIdRouteRoute
  '/payment-methods/new': typeof CustomerPaymentMethodsNewRouteRoute
  '/payment-methods/update': typeof CustomerPaymentMethodsUpdateRouteRoute
  '/bo/admin/cpms': typeof BoAdminCpmsRouteRoute
  '/bo/admin/ima': typeof BoAdminImaRouteRoute
  '/bo/admin/jobs': typeof BoAdminJobsRouteRoute
  '/bo/admin/time': typeof BoAdminTimeRouteRoute
  '/bo/documentation/activity-specific-questions': typeof BoDocumentationActivitySpecificQuestionsRouteRoute
  '/bo/documentation/exclusions': typeof BoDocumentationExclusionsRouteRoute
  '/bo/documentation/general-terms': typeof BoDocumentationGeneralTermsRouteRoute
  '/bo/documentation/roles': typeof BoDocumentationRolesRouteRoute
  '/bo/documentation/subscription-funnel-texts': typeof BoDocumentationSubscriptionFunnelTextsRouteRoute
  '/bo/invoicing/$invoiceId': typeof BoInvoicingInvoiceIdRouteRoute
  '/bo/invoicing/negative-invoices': typeof BoInvoicingNegativeInvoicesRouteRoute
  '/bo/subscriptions/new': typeof BoSubscriptionsNewRouteRoute
  '/bo/users/$userId': typeof BoUsersUserIdRouteRoute
  '/s/c/$token': typeof SCTokenRouteRoute
  '/s/e-v2/$token': typeof SEV2TokenRouteRoute
  '/s/q/$token': typeof SQTokenRouteRoute
  '/subscribe/restaurants-v2/identify': typeof SubscribeRestaurantsV2IdentifyRouteRoute
  '/health-insurance': typeof CustomerHealthInsuranceIndexRoute
  '/bo/admin': typeof BoAdminIndexRoute
  '/bo/documentation': typeof BoDocumentationIndexRoute
  '/bo/invoicing': typeof BoInvoicingIndexRoute
  '/bo/subscriptions': typeof BoSubscriptionsIndexRoute
  '/bo/users': typeof BoUsersIndexRoute
  '/bak/admin/cpms': typeof redirectsBakAdminCpmsRouteRoute
  '/bak/admin/ima': typeof redirectsBakAdminImaRouteRoute
  '/bak/admin/jobs': typeof redirectsBakAdminJobsRouteRoute
  '/bak/documentation/activity-specific-questions': typeof redirectsBakDocumentationActivitySpecificQuestionsRouteRoute
  '/bak/documentation/exclusions': typeof redirectsBakDocumentationExclusionsRouteRoute
  '/bak/documentation/general-terms': typeof redirectsBakDocumentationGeneralTermsRouteRoute
  '/bak/documentation/roles': typeof redirectsBakDocumentationRolesRouteRoute
  '/bak/documentation/subscription-funnel-texts': typeof redirectsBakDocumentationSubscriptionFunnelTextsRouteRoute
  '/bak/invoicing/negative-invoices-list': typeof redirectsBakInvoicingNegativeInvoicesListRouteRoute
  '/bak/users/$userId': typeof redirectsBakUsersUserIdRouteRoute
  '/partner/$organization/home': typeof redirectsPartnerOrganizationHomeRouteRoute
  '/partner/$organization/new-subscription': typeof redirectsPartnerOrganizationNewSubscriptionRouteRoute
  '/partner/$organization/search': typeof redirectsPartnerOrganizationSearchRouteRoute
  '/product-guarantees/$subscriptionId/$productName': typeof CustomerProductGuaranteesSubscriptionIdProductNameRouteRoute
  '/s/r/$token': typeof CustomerSRTokenRouteRoute
  '/bo/contracts/$subscriptionId/status': typeof BoContractsSubscriptionIdStatusRouteRoute
  '/bo/subscriptions/$subscriptionId/detailed-sheet': typeof BoSubscriptionsSubscriptionIdDetailedSheetRouteRoute
  '/bo/subscriptions/$subscriptionId/history': typeof BoSubscriptionsSubscriptionIdHistoryRouteRoute
  '/bo/subscriptions/$subscriptionId/payment': typeof BoSubscriptionsSubscriptionIdPaymentRouteRoute
  '/bo/troubleshooting/sessions/$sessionId': typeof BoTroubleshootingSessionsSessionIdRouteRoute
  '/bak/admin': typeof redirectsBakAdminIndexRoute
  '/bak/documentation': typeof redirectsBakDocumentationIndexRoute
  '/bak/invoicing': typeof redirectsBakInvoicingIndexRoute
  '/bak/pending-subscriptions': typeof redirectsBakPendingSubscriptionsIndexRoute
  '/bak/users': typeof redirectsBakUsersIndexRoute
  '/bo/claims/$claimId': typeof BoClaimsClaimIdIndexRoute
  '/bo/contracts/$subscriptionId': typeof BoContractsSubscriptionIdIndexRoute
  '/bo/subscriptions/$subscriptionId': typeof BoSubscriptionsSubscriptionIdIndexRoute
  '/subscribe/$subscriptionId/$stepId': typeof SubscribeSubscriptionIdStepIdIndexRoute
  '/bak/contracts/$subscriptionId/invoicing': typeof redirectsBakContractsSubscriptionIdInvoicingRouteRoute
  '/bak/contracts/$subscriptionId/new-invoice': typeof redirectsBakContractsSubscriptionIdNewInvoiceRouteRoute
  '/bak/contracts/$subscriptionId/status': typeof redirectsBakContractsSubscriptionIdStatusRouteRoute
  '/bak/pending-subscriptions/$subscriptionId/history': typeof redirectsBakPendingSubscriptionsSubscriptionIdHistoryRouteRoute
  '/bak/pending-subscriptions/$subscriptionId/payment': typeof redirectsBakPendingSubscriptionsSubscriptionIdPaymentRouteRoute
  '/bak/troubleshooting/session/$sessionId': typeof redirectsBakTroubleshootingSessionSessionIdRouteRoute
  '/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet': typeof redirectsBakV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute
  '/partner/$organization/claims/$claimId': typeof redirectsPartnerOrganizationClaimsClaimIdRouteRoute
  '/partner/$organization/contracts/$subscriptionId': typeof redirectsPartnerOrganizationContractsSubscriptionIdRouteRoute
  '/partner/$organization/users/$userId': typeof redirectsPartnerOrganizationUsersUserIdRouteRoute
  '/bo/contracts/$subscriptionId/invoices/debug': typeof BoContractsSubscriptionIdInvoicesDebugRouteRoute
  '/bo/contracts/$subscriptionId/invoices/new': typeof BoContractsSubscriptionIdInvoicesNewRouteRoute
  '/bo/contracts/$subscriptionId/renewals/$endorsementId': typeof BoContractsSubscriptionIdRenewalsEndorsementIdRouteRoute
  '/subscribe/$subscriptionId/$stepId/$detailType/$detailId': typeof SubscribeSubscriptionIdStepIdDetailTypeDetailIdRouteRoute
  '/bak/claims/$claimId': typeof redirectsBakClaimsClaimIdIndexRoute
  '/bak/contracts/$subscriptionId': typeof redirectsBakContractsSubscriptionIdIndexRoute
  '/bak/v2-pending-subscriptions/$subscriptionId': typeof redirectsBakV2PendingSubscriptionsSubscriptionIdIndexRoute
  '/partner/$organization/pending-subscriptions': typeof redirectsPartnerOrganizationPendingSubscriptionsIndexRoute
  '/partner/$organization/users': typeof redirectsPartnerOrganizationUsersIndexRoute
  '/bak/contracts/$subscriptionId/renewal/$endorsementId': typeof redirectsBakContractsSubscriptionIdRenewalEndorsementIdRouteRoute
  '/partner/$organization/pending-subscriptions/$subscriptionId/payment': typeof redirectsPartnerOrganizationPendingSubscriptionsSubscriptionIdPaymentRouteRoute
  '/partner/$organization/v2-pending-subscriptions/$subscriptionId/detailed-sheet': typeof redirectsPartnerOrganizationV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute
  '/bo/contracts/$subscriptionId/detailed-sheet/versions/$versionId': typeof BoContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute
  '/bo/contracts/$subscriptionId/endorsements/$endorsementId/detailed-sheet': typeof BoContractsSubscriptionIdEndorsementsEndorsementIdDetailedSheetRouteRoute
  '/partner/$organization/v2-pending-subscriptions/$subscriptionId': typeof redirectsPartnerOrganizationV2PendingSubscriptionsSubscriptionIdIndexRoute
  '/bo/contracts/$subscriptionId/endorsements/$endorsementId': typeof BoContractsSubscriptionIdEndorsementsEndorsementIdIndexRoute
  '/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId': typeof redirectsBakContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute
  '/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet': typeof redirectsBakContractsSubscriptionIdEndorsementEndorsementIdDetailedSheetRouteRoute
  '/bak/contracts/$subscriptionId/endorsement/$endorsementId': typeof redirectsBakContractsSubscriptionIdEndorsementEndorsementIdIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/impersonate': typeof ImpersonateRouteRoute
  '/login': typeof LoginRouteRoute
  '/payment-method-update': typeof PaymentMethodUpdateRouteRoute
  '/receive-quote': typeof ReceiveQuoteRouteRoute
  '/search': typeof SearchRouteRoute
  '/subscribe-no-possible-step': typeof SubscribeNoPossibleStepRouteRoute
  '/unsubscribe': typeof UnsubscribeRouteRoute
  '': typeof CustomerRouteWithChildren
  '/account': typeof CustomerAccountRouteRoute
  '/contract-guarantees': typeof CustomerContractGuaranteesRouteRoute
  '/contracts': typeof CustomerContractsRouteRoute
  '/home': typeof CustomerHomeRouteRoute
  '/invoices': typeof CustomerInvoicesRouteRoute
  '/report-claim': typeof CustomerReportClaimRouteRoute
  '/bo/activities': typeof BoActivitiesRouteRoute
  '/bo/ephemeral-jobs': typeof BoEphemeralJobsRouteRoute
  '/bo/memberships': typeof BoMembershipsRouteRoute
  '/bo/migration': typeof BoMigrationRouteRoute
  '/bo/organizations': typeof BoOrganizationsRouteRoute
  '/bo/search': typeof BoSearchRouteRoute
  '/bo/searchV2': typeof BoSearchV2RouteRoute
  '/bo/tracking-doc': typeof BoTrackingDocRouteRoute
  '/payment/authentication': typeof PaymentAuthenticationRouteRoute
  '/subscribe-exclusion/$subscriptionId': typeof SubscribeExclusionSubscriptionIdRouteRoute
  '/subscribe/landing': typeof SubscribeLandingRouteRoute
  '/bo': typeof BoIndexRoute
  '/bak/activities': typeof redirectsBakActivitiesRouteRoute
  '/bak/ephemeral-jobs': typeof redirectsBakEphemeralJobsRouteRoute
  '/bak/home': typeof redirectsBakHomeRouteRoute
  '/bak/memberships': typeof redirectsBakMembershipsRouteRoute
  '/bak/migration': typeof redirectsBakMigrationRouteRoute
  '/bak/new-subscription': typeof redirectsBakNewSubscriptionRouteRoute
  '/bak/organizations': typeof redirectsBakOrganizationsRouteRoute
  '/bak/search': typeof redirectsBakSearchRouteRoute
  '/bak/tracking-doc': typeof redirectsBakTrackingDocRouteRoute
  '/contract/$subscriptionId': typeof CustomerContractSubscriptionIdRouteRoute
  '/health-insurance/beneficiaries': typeof CustomerHealthInsuranceBeneficiariesRouteRoute
  '/health-insurance/cpms': typeof CustomerHealthInsuranceCpmsRouteRoute
  '/health-insurance/santeclair': typeof CustomerHealthInsuranceSanteclairRouteRoute
  '/insurance-certificate-generator-real-estate-agents/$subscriptionId': typeof CustomerInsuranceCertificateGeneratorRealEstateAgentsSubscriptionIdRouteRoute
  '/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId': typeof CustomerInsuranceCertificateGeneratorRealEstateSalesAgentsSubscriptionIdRouteRoute
  '/payment-methods/new': typeof CustomerPaymentMethodsNewRouteRoute
  '/payment-methods/update': typeof CustomerPaymentMethodsUpdateRouteRoute
  '/bo/admin/cpms': typeof BoAdminCpmsRouteRoute
  '/bo/admin/ima': typeof BoAdminImaRouteRoute
  '/bo/admin/jobs': typeof BoAdminJobsRouteRoute
  '/bo/admin/time': typeof BoAdminTimeRouteRoute
  '/bo/documentation/activity-specific-questions': typeof BoDocumentationActivitySpecificQuestionsRouteRoute
  '/bo/documentation/exclusions': typeof BoDocumentationExclusionsRouteRoute
  '/bo/documentation/general-terms': typeof BoDocumentationGeneralTermsRouteRoute
  '/bo/documentation/roles': typeof BoDocumentationRolesRouteRoute
  '/bo/documentation/subscription-funnel-texts': typeof BoDocumentationSubscriptionFunnelTextsRouteRoute
  '/bo/invoicing/$invoiceId': typeof BoInvoicingInvoiceIdRouteRoute
  '/bo/invoicing/negative-invoices': typeof BoInvoicingNegativeInvoicesRouteRoute
  '/bo/subscriptions/new': typeof BoSubscriptionsNewRouteRoute
  '/bo/users/$userId': typeof BoUsersUserIdRouteRoute
  '/s/c/$token': typeof SCTokenRouteRoute
  '/s/e-v2/$token': typeof SEV2TokenRouteRoute
  '/s/q/$token': typeof SQTokenRouteRoute
  '/subscribe/restaurants-v2/identify': typeof SubscribeRestaurantsV2IdentifyRouteRoute
  '/health-insurance': typeof CustomerHealthInsuranceIndexRoute
  '/bo/admin': typeof BoAdminIndexRoute
  '/bo/documentation': typeof BoDocumentationIndexRoute
  '/bo/invoicing': typeof BoInvoicingIndexRoute
  '/bo/subscriptions': typeof BoSubscriptionsIndexRoute
  '/bo/users': typeof BoUsersIndexRoute
  '/bak/admin/cpms': typeof redirectsBakAdminCpmsRouteRoute
  '/bak/admin/ima': typeof redirectsBakAdminImaRouteRoute
  '/bak/admin/jobs': typeof redirectsBakAdminJobsRouteRoute
  '/bak/documentation/activity-specific-questions': typeof redirectsBakDocumentationActivitySpecificQuestionsRouteRoute
  '/bak/documentation/exclusions': typeof redirectsBakDocumentationExclusionsRouteRoute
  '/bak/documentation/general-terms': typeof redirectsBakDocumentationGeneralTermsRouteRoute
  '/bak/documentation/roles': typeof redirectsBakDocumentationRolesRouteRoute
  '/bak/documentation/subscription-funnel-texts': typeof redirectsBakDocumentationSubscriptionFunnelTextsRouteRoute
  '/bak/invoicing/negative-invoices-list': typeof redirectsBakInvoicingNegativeInvoicesListRouteRoute
  '/bak/users/$userId': typeof redirectsBakUsersUserIdRouteRoute
  '/partner/$organization/home': typeof redirectsPartnerOrganizationHomeRouteRoute
  '/partner/$organization/new-subscription': typeof redirectsPartnerOrganizationNewSubscriptionRouteRoute
  '/partner/$organization/search': typeof redirectsPartnerOrganizationSearchRouteRoute
  '/product-guarantees/$subscriptionId/$productName': typeof CustomerProductGuaranteesSubscriptionIdProductNameRouteRoute
  '/s/r/$token': typeof CustomerSRTokenRouteRoute
  '/bo/contracts/$subscriptionId/status': typeof BoContractsSubscriptionIdStatusRouteRoute
  '/bo/subscriptions/$subscriptionId/detailed-sheet': typeof BoSubscriptionsSubscriptionIdDetailedSheetRouteRoute
  '/bo/subscriptions/$subscriptionId/history': typeof BoSubscriptionsSubscriptionIdHistoryRouteRoute
  '/bo/subscriptions/$subscriptionId/payment': typeof BoSubscriptionsSubscriptionIdPaymentRouteRoute
  '/bo/troubleshooting/sessions/$sessionId': typeof BoTroubleshootingSessionsSessionIdRouteRoute
  '/bak/admin': typeof redirectsBakAdminIndexRoute
  '/bak/documentation': typeof redirectsBakDocumentationIndexRoute
  '/bak/invoicing': typeof redirectsBakInvoicingIndexRoute
  '/bak/pending-subscriptions': typeof redirectsBakPendingSubscriptionsIndexRoute
  '/bak/users': typeof redirectsBakUsersIndexRoute
  '/bo/claims/$claimId': typeof BoClaimsClaimIdIndexRoute
  '/bo/contracts/$subscriptionId': typeof BoContractsSubscriptionIdIndexRoute
  '/bo/subscriptions/$subscriptionId': typeof BoSubscriptionsSubscriptionIdIndexRoute
  '/subscribe/$subscriptionId/$stepId': typeof SubscribeSubscriptionIdStepIdIndexRoute
  '/bak/contracts/$subscriptionId/invoicing': typeof redirectsBakContractsSubscriptionIdInvoicingRouteRoute
  '/bak/contracts/$subscriptionId/new-invoice': typeof redirectsBakContractsSubscriptionIdNewInvoiceRouteRoute
  '/bak/contracts/$subscriptionId/status': typeof redirectsBakContractsSubscriptionIdStatusRouteRoute
  '/bak/pending-subscriptions/$subscriptionId/history': typeof redirectsBakPendingSubscriptionsSubscriptionIdHistoryRouteRoute
  '/bak/pending-subscriptions/$subscriptionId/payment': typeof redirectsBakPendingSubscriptionsSubscriptionIdPaymentRouteRoute
  '/bak/troubleshooting/session/$sessionId': typeof redirectsBakTroubleshootingSessionSessionIdRouteRoute
  '/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet': typeof redirectsBakV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute
  '/partner/$organization/claims/$claimId': typeof redirectsPartnerOrganizationClaimsClaimIdRouteRoute
  '/partner/$organization/contracts/$subscriptionId': typeof redirectsPartnerOrganizationContractsSubscriptionIdRouteRoute
  '/partner/$organization/users/$userId': typeof redirectsPartnerOrganizationUsersUserIdRouteRoute
  '/bo/contracts/$subscriptionId/invoices/debug': typeof BoContractsSubscriptionIdInvoicesDebugRouteRoute
  '/bo/contracts/$subscriptionId/invoices/new': typeof BoContractsSubscriptionIdInvoicesNewRouteRoute
  '/bo/contracts/$subscriptionId/renewals/$endorsementId': typeof BoContractsSubscriptionIdRenewalsEndorsementIdRouteRoute
  '/subscribe/$subscriptionId/$stepId/$detailType/$detailId': typeof SubscribeSubscriptionIdStepIdDetailTypeDetailIdRouteRoute
  '/bak/claims/$claimId': typeof redirectsBakClaimsClaimIdIndexRoute
  '/bak/contracts/$subscriptionId': typeof redirectsBakContractsSubscriptionIdIndexRoute
  '/bak/v2-pending-subscriptions/$subscriptionId': typeof redirectsBakV2PendingSubscriptionsSubscriptionIdIndexRoute
  '/partner/$organization/pending-subscriptions': typeof redirectsPartnerOrganizationPendingSubscriptionsIndexRoute
  '/partner/$organization/users': typeof redirectsPartnerOrganizationUsersIndexRoute
  '/bak/contracts/$subscriptionId/renewal/$endorsementId': typeof redirectsBakContractsSubscriptionIdRenewalEndorsementIdRouteRoute
  '/partner/$organization/pending-subscriptions/$subscriptionId/payment': typeof redirectsPartnerOrganizationPendingSubscriptionsSubscriptionIdPaymentRouteRoute
  '/partner/$organization/v2-pending-subscriptions/$subscriptionId/detailed-sheet': typeof redirectsPartnerOrganizationV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute
  '/bo/contracts/$subscriptionId/detailed-sheet/versions/$versionId': typeof BoContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute
  '/bo/contracts/$subscriptionId/endorsements/$endorsementId/detailed-sheet': typeof BoContractsSubscriptionIdEndorsementsEndorsementIdDetailedSheetRouteRoute
  '/partner/$organization/v2-pending-subscriptions/$subscriptionId': typeof redirectsPartnerOrganizationV2PendingSubscriptionsSubscriptionIdIndexRoute
  '/bo/contracts/$subscriptionId/endorsements/$endorsementId': typeof BoContractsSubscriptionIdEndorsementsEndorsementIdIndexRoute
  '/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId': typeof redirectsBakContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute
  '/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet': typeof redirectsBakContractsSubscriptionIdEndorsementEndorsementIdDetailedSheetRouteRoute
  '/bak/contracts/$subscriptionId/endorsement/$endorsementId': typeof redirectsBakContractsSubscriptionIdEndorsementEndorsementIdIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/impersonate': typeof ImpersonateRouteRoute
  '/login': typeof LoginRouteRoute
  '/payment-method-update': typeof PaymentMethodUpdateRouteRoute
  '/receive-quote': typeof ReceiveQuoteRouteRoute
  '/search': typeof SearchRouteRoute
  '/subscribe-no-possible-step': typeof SubscribeNoPossibleStepRouteRoute
  '/unsubscribe': typeof UnsubscribeRouteRoute
  '/_customer': typeof CustomerRouteWithChildren
  '/bo': typeof BoRouteWithChildren
  '/_customer/account': typeof CustomerAccountRouteRoute
  '/_customer/contract-guarantees': typeof CustomerContractGuaranteesRouteRoute
  '/_customer/contracts': typeof CustomerContractsRouteRoute
  '/_customer/home': typeof CustomerHomeRouteRoute
  '/_customer/invoices': typeof CustomerInvoicesRouteRoute
  '/_customer/report-claim': typeof CustomerReportClaimRouteRoute
  '/bo/activities': typeof BoActivitiesRouteRoute
  '/bo/ephemeral-jobs': typeof BoEphemeralJobsRouteRoute
  '/bo/memberships': typeof BoMembershipsRouteRoute
  '/bo/migration': typeof BoMigrationRouteRoute
  '/bo/organizations': typeof BoOrganizationsRouteRoute
  '/bo/search': typeof BoSearchRouteRoute
  '/bo/searchV2': typeof BoSearchV2RouteRoute
  '/bo/tracking-doc': typeof BoTrackingDocRouteRoute
  '/payment/authentication': typeof PaymentAuthenticationRouteRoute
  '/subscribe-exclusion/$subscriptionId': typeof SubscribeExclusionSubscriptionIdRouteRoute
  '/subscribe/landing': typeof SubscribeLandingRouteRoute
  '/bo/': typeof BoIndexRoute
  '/(redirects)/bak/activities': typeof redirectsBakActivitiesRouteRoute
  '/(redirects)/bak/ephemeral-jobs': typeof redirectsBakEphemeralJobsRouteRoute
  '/(redirects)/bak/home': typeof redirectsBakHomeRouteRoute
  '/(redirects)/bak/memberships': typeof redirectsBakMembershipsRouteRoute
  '/(redirects)/bak/migration': typeof redirectsBakMigrationRouteRoute
  '/(redirects)/bak/new-subscription': typeof redirectsBakNewSubscriptionRouteRoute
  '/(redirects)/bak/organizations': typeof redirectsBakOrganizationsRouteRoute
  '/(redirects)/bak/search': typeof redirectsBakSearchRouteRoute
  '/(redirects)/bak/tracking-doc': typeof redirectsBakTrackingDocRouteRoute
  '/_customer/contract/$subscriptionId': typeof CustomerContractSubscriptionIdRouteRoute
  '/_customer/health-insurance/beneficiaries': typeof CustomerHealthInsuranceBeneficiariesRouteRoute
  '/_customer/health-insurance/cpms': typeof CustomerHealthInsuranceCpmsRouteRoute
  '/_customer/health-insurance/santeclair': typeof CustomerHealthInsuranceSanteclairRouteRoute
  '/_customer/insurance-certificate-generator-real-estate-agents/$subscriptionId': typeof CustomerInsuranceCertificateGeneratorRealEstateAgentsSubscriptionIdRouteRoute
  '/_customer/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId': typeof CustomerInsuranceCertificateGeneratorRealEstateSalesAgentsSubscriptionIdRouteRoute
  '/_customer/payment-methods/new': typeof CustomerPaymentMethodsNewRouteRoute
  '/_customer/payment-methods/update': typeof CustomerPaymentMethodsUpdateRouteRoute
  '/bo/admin/cpms': typeof BoAdminCpmsRouteRoute
  '/bo/admin/ima': typeof BoAdminImaRouteRoute
  '/bo/admin/jobs': typeof BoAdminJobsRouteRoute
  '/bo/admin/time': typeof BoAdminTimeRouteRoute
  '/bo/documentation/activity-specific-questions': typeof BoDocumentationActivitySpecificQuestionsRouteRoute
  '/bo/documentation/exclusions': typeof BoDocumentationExclusionsRouteRoute
  '/bo/documentation/general-terms': typeof BoDocumentationGeneralTermsRouteRoute
  '/bo/documentation/roles': typeof BoDocumentationRolesRouteRoute
  '/bo/documentation/subscription-funnel-texts': typeof BoDocumentationSubscriptionFunnelTextsRouteRoute
  '/bo/invoicing/$invoiceId': typeof BoInvoicingInvoiceIdRouteRoute
  '/bo/invoicing/negative-invoices': typeof BoInvoicingNegativeInvoicesRouteRoute
  '/bo/subscriptions/new': typeof BoSubscriptionsNewRouteRoute
  '/bo/users/$userId': typeof BoUsersUserIdRouteRoute
  '/s/c/$token': typeof SCTokenRouteRoute
  '/s/e-v2/$token': typeof SEV2TokenRouteRoute
  '/s/q/$token': typeof SQTokenRouteRoute
  '/subscribe/restaurants-v2/identify': typeof SubscribeRestaurantsV2IdentifyRouteRoute
  '/_customer/health-insurance/': typeof CustomerHealthInsuranceIndexRoute
  '/bo/admin/': typeof BoAdminIndexRoute
  '/bo/documentation/': typeof BoDocumentationIndexRoute
  '/bo/invoicing/': typeof BoInvoicingIndexRoute
  '/bo/subscriptions/': typeof BoSubscriptionsIndexRoute
  '/bo/users/': typeof BoUsersIndexRoute
  '/(redirects)/bak/admin/cpms': typeof redirectsBakAdminCpmsRouteRoute
  '/(redirects)/bak/admin/ima': typeof redirectsBakAdminImaRouteRoute
  '/(redirects)/bak/admin/jobs': typeof redirectsBakAdminJobsRouteRoute
  '/(redirects)/bak/documentation/activity-specific-questions': typeof redirectsBakDocumentationActivitySpecificQuestionsRouteRoute
  '/(redirects)/bak/documentation/exclusions': typeof redirectsBakDocumentationExclusionsRouteRoute
  '/(redirects)/bak/documentation/general-terms': typeof redirectsBakDocumentationGeneralTermsRouteRoute
  '/(redirects)/bak/documentation/roles': typeof redirectsBakDocumentationRolesRouteRoute
  '/(redirects)/bak/documentation/subscription-funnel-texts': typeof redirectsBakDocumentationSubscriptionFunnelTextsRouteRoute
  '/(redirects)/bak/invoicing/negative-invoices-list': typeof redirectsBakInvoicingNegativeInvoicesListRouteRoute
  '/(redirects)/bak/users/$userId': typeof redirectsBakUsersUserIdRouteRoute
  '/(redirects)/partner/$organization/home': typeof redirectsPartnerOrganizationHomeRouteRoute
  '/(redirects)/partner/$organization/new-subscription': typeof redirectsPartnerOrganizationNewSubscriptionRouteRoute
  '/(redirects)/partner/$organization/search': typeof redirectsPartnerOrganizationSearchRouteRoute
  '/_customer/product-guarantees/$subscriptionId/$productName': typeof CustomerProductGuaranteesSubscriptionIdProductNameRouteRoute
  '/_customer/s/r/$token': typeof CustomerSRTokenRouteRoute
  '/bo/contracts/$subscriptionId/status': typeof BoContractsSubscriptionIdStatusRouteRoute
  '/bo/subscriptions/$subscriptionId/detailed-sheet': typeof BoSubscriptionsSubscriptionIdDetailedSheetRouteRoute
  '/bo/subscriptions/$subscriptionId/history': typeof BoSubscriptionsSubscriptionIdHistoryRouteRoute
  '/bo/subscriptions/$subscriptionId/payment': typeof BoSubscriptionsSubscriptionIdPaymentRouteRoute
  '/bo/troubleshooting/sessions/$sessionId': typeof BoTroubleshootingSessionsSessionIdRouteRoute
  '/(redirects)/bak/admin/': typeof redirectsBakAdminIndexRoute
  '/(redirects)/bak/documentation/': typeof redirectsBakDocumentationIndexRoute
  '/(redirects)/bak/invoicing/': typeof redirectsBakInvoicingIndexRoute
  '/(redirects)/bak/pending-subscriptions/': typeof redirectsBakPendingSubscriptionsIndexRoute
  '/(redirects)/bak/users/': typeof redirectsBakUsersIndexRoute
  '/bo/claims/$claimId/': typeof BoClaimsClaimIdIndexRoute
  '/bo/contracts/$subscriptionId/': typeof BoContractsSubscriptionIdIndexRoute
  '/bo/subscriptions/$subscriptionId/': typeof BoSubscriptionsSubscriptionIdIndexRoute
  '/subscribe/$subscriptionId/$stepId/': typeof SubscribeSubscriptionIdStepIdIndexRoute
  '/(redirects)/bak/contracts/$subscriptionId/invoicing': typeof redirectsBakContractsSubscriptionIdInvoicingRouteRoute
  '/(redirects)/bak/contracts/$subscriptionId/new-invoice': typeof redirectsBakContractsSubscriptionIdNewInvoiceRouteRoute
  '/(redirects)/bak/contracts/$subscriptionId/status': typeof redirectsBakContractsSubscriptionIdStatusRouteRoute
  '/(redirects)/bak/pending-subscriptions/$subscriptionId/history': typeof redirectsBakPendingSubscriptionsSubscriptionIdHistoryRouteRoute
  '/(redirects)/bak/pending-subscriptions/$subscriptionId/payment': typeof redirectsBakPendingSubscriptionsSubscriptionIdPaymentRouteRoute
  '/(redirects)/bak/troubleshooting/session/$sessionId': typeof redirectsBakTroubleshootingSessionSessionIdRouteRoute
  '/(redirects)/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet': typeof redirectsBakV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute
  '/(redirects)/partner/$organization/claims/$claimId': typeof redirectsPartnerOrganizationClaimsClaimIdRouteRoute
  '/(redirects)/partner/$organization/contracts/$subscriptionId': typeof redirectsPartnerOrganizationContractsSubscriptionIdRouteRoute
  '/(redirects)/partner/$organization/users/$userId': typeof redirectsPartnerOrganizationUsersUserIdRouteRoute
  '/bo/contracts/$subscriptionId/invoices/debug': typeof BoContractsSubscriptionIdInvoicesDebugRouteRoute
  '/bo/contracts/$subscriptionId/invoices/new': typeof BoContractsSubscriptionIdInvoicesNewRouteRoute
  '/bo/contracts/$subscriptionId/renewals/$endorsementId': typeof BoContractsSubscriptionIdRenewalsEndorsementIdRouteRoute
  '/subscribe/$subscriptionId/$stepId/$detailType/$detailId': typeof SubscribeSubscriptionIdStepIdDetailTypeDetailIdRouteRoute
  '/(redirects)/bak/claims/$claimId/': typeof redirectsBakClaimsClaimIdIndexRoute
  '/(redirects)/bak/contracts/$subscriptionId/': typeof redirectsBakContractsSubscriptionIdIndexRoute
  '/(redirects)/bak/v2-pending-subscriptions/$subscriptionId/': typeof redirectsBakV2PendingSubscriptionsSubscriptionIdIndexRoute
  '/(redirects)/partner/$organization/pending-subscriptions/': typeof redirectsPartnerOrganizationPendingSubscriptionsIndexRoute
  '/(redirects)/partner/$organization/users/': typeof redirectsPartnerOrganizationUsersIndexRoute
  '/(redirects)/bak/contracts/$subscriptionId/renewal/$endorsementId': typeof redirectsBakContractsSubscriptionIdRenewalEndorsementIdRouteRoute
  '/(redirects)/partner/$organization/pending-subscriptions/$subscriptionId/payment': typeof redirectsPartnerOrganizationPendingSubscriptionsSubscriptionIdPaymentRouteRoute
  '/(redirects)/partner/$organization/v2-pending-subscriptions/$subscriptionId/detailed-sheet': typeof redirectsPartnerOrganizationV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute
  '/bo/contracts/$subscriptionId/detailed-sheet/versions/$versionId': typeof BoContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute
  '/bo/contracts/$subscriptionId/endorsements/$endorsementId/detailed-sheet': typeof BoContractsSubscriptionIdEndorsementsEndorsementIdDetailedSheetRouteRoute
  '/(redirects)/partner/$organization/v2-pending-subscriptions/$subscriptionId/': typeof redirectsPartnerOrganizationV2PendingSubscriptionsSubscriptionIdIndexRoute
  '/bo/contracts/$subscriptionId/endorsements/$endorsementId/': typeof BoContractsSubscriptionIdEndorsementsEndorsementIdIndexRoute
  '/(redirects)/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId': typeof redirectsBakContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute
  '/(redirects)/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet': typeof redirectsBakContractsSubscriptionIdEndorsementEndorsementIdDetailedSheetRouteRoute
  '/(redirects)/bak/contracts/$subscriptionId/endorsement/$endorsementId/': typeof redirectsBakContractsSubscriptionIdEndorsementEndorsementIdIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/impersonate'
    | '/login'
    | '/payment-method-update'
    | '/receive-quote'
    | '/search'
    | '/subscribe-no-possible-step'
    | '/unsubscribe'
    | ''
    | '/bo'
    | '/account'
    | '/contract-guarantees'
    | '/contracts'
    | '/home'
    | '/invoices'
    | '/report-claim'
    | '/bo/activities'
    | '/bo/ephemeral-jobs'
    | '/bo/memberships'
    | '/bo/migration'
    | '/bo/organizations'
    | '/bo/search'
    | '/bo/searchV2'
    | '/bo/tracking-doc'
    | '/payment/authentication'
    | '/subscribe-exclusion/$subscriptionId'
    | '/subscribe/landing'
    | '/bo/'
    | '/bak/activities'
    | '/bak/ephemeral-jobs'
    | '/bak/home'
    | '/bak/memberships'
    | '/bak/migration'
    | '/bak/new-subscription'
    | '/bak/organizations'
    | '/bak/search'
    | '/bak/tracking-doc'
    | '/contract/$subscriptionId'
    | '/health-insurance/beneficiaries'
    | '/health-insurance/cpms'
    | '/health-insurance/santeclair'
    | '/insurance-certificate-generator-real-estate-agents/$subscriptionId'
    | '/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId'
    | '/payment-methods/new'
    | '/payment-methods/update'
    | '/bo/admin/cpms'
    | '/bo/admin/ima'
    | '/bo/admin/jobs'
    | '/bo/admin/time'
    | '/bo/documentation/activity-specific-questions'
    | '/bo/documentation/exclusions'
    | '/bo/documentation/general-terms'
    | '/bo/documentation/roles'
    | '/bo/documentation/subscription-funnel-texts'
    | '/bo/invoicing/$invoiceId'
    | '/bo/invoicing/negative-invoices'
    | '/bo/subscriptions/new'
    | '/bo/users/$userId'
    | '/s/c/$token'
    | '/s/e-v2/$token'
    | '/s/q/$token'
    | '/subscribe/restaurants-v2/identify'
    | '/health-insurance'
    | '/bo/admin'
    | '/bo/documentation'
    | '/bo/invoicing'
    | '/bo/subscriptions'
    | '/bo/users'
    | '/bak/admin/cpms'
    | '/bak/admin/ima'
    | '/bak/admin/jobs'
    | '/bak/documentation/activity-specific-questions'
    | '/bak/documentation/exclusions'
    | '/bak/documentation/general-terms'
    | '/bak/documentation/roles'
    | '/bak/documentation/subscription-funnel-texts'
    | '/bak/invoicing/negative-invoices-list'
    | '/bak/users/$userId'
    | '/partner/$organization/home'
    | '/partner/$organization/new-subscription'
    | '/partner/$organization/search'
    | '/product-guarantees/$subscriptionId/$productName'
    | '/s/r/$token'
    | '/bo/contracts/$subscriptionId/status'
    | '/bo/subscriptions/$subscriptionId/detailed-sheet'
    | '/bo/subscriptions/$subscriptionId/history'
    | '/bo/subscriptions/$subscriptionId/payment'
    | '/bo/troubleshooting/sessions/$sessionId'
    | '/bak/admin'
    | '/bak/documentation'
    | '/bak/invoicing'
    | '/bak/pending-subscriptions'
    | '/bak/users'
    | '/bo/claims/$claimId'
    | '/bo/contracts/$subscriptionId'
    | '/bo/subscriptions/$subscriptionId'
    | '/subscribe/$subscriptionId/$stepId'
    | '/bak/contracts/$subscriptionId/invoicing'
    | '/bak/contracts/$subscriptionId/new-invoice'
    | '/bak/contracts/$subscriptionId/status'
    | '/bak/pending-subscriptions/$subscriptionId/history'
    | '/bak/pending-subscriptions/$subscriptionId/payment'
    | '/bak/troubleshooting/session/$sessionId'
    | '/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet'
    | '/partner/$organization/claims/$claimId'
    | '/partner/$organization/contracts/$subscriptionId'
    | '/partner/$organization/users/$userId'
    | '/bo/contracts/$subscriptionId/invoices/debug'
    | '/bo/contracts/$subscriptionId/invoices/new'
    | '/bo/contracts/$subscriptionId/renewals/$endorsementId'
    | '/subscribe/$subscriptionId/$stepId/$detailType/$detailId'
    | '/bak/claims/$claimId'
    | '/bak/contracts/$subscriptionId'
    | '/bak/v2-pending-subscriptions/$subscriptionId'
    | '/partner/$organization/pending-subscriptions'
    | '/partner/$organization/users'
    | '/bak/contracts/$subscriptionId/renewal/$endorsementId'
    | '/partner/$organization/pending-subscriptions/$subscriptionId/payment'
    | '/partner/$organization/v2-pending-subscriptions/$subscriptionId/detailed-sheet'
    | '/bo/contracts/$subscriptionId/detailed-sheet/versions/$versionId'
    | '/bo/contracts/$subscriptionId/endorsements/$endorsementId/detailed-sheet'
    | '/partner/$organization/v2-pending-subscriptions/$subscriptionId'
    | '/bo/contracts/$subscriptionId/endorsements/$endorsementId'
    | '/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId'
    | '/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet'
    | '/bak/contracts/$subscriptionId/endorsement/$endorsementId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/impersonate'
    | '/login'
    | '/payment-method-update'
    | '/receive-quote'
    | '/search'
    | '/subscribe-no-possible-step'
    | '/unsubscribe'
    | ''
    | '/account'
    | '/contract-guarantees'
    | '/contracts'
    | '/home'
    | '/invoices'
    | '/report-claim'
    | '/bo/activities'
    | '/bo/ephemeral-jobs'
    | '/bo/memberships'
    | '/bo/migration'
    | '/bo/organizations'
    | '/bo/search'
    | '/bo/searchV2'
    | '/bo/tracking-doc'
    | '/payment/authentication'
    | '/subscribe-exclusion/$subscriptionId'
    | '/subscribe/landing'
    | '/bo'
    | '/bak/activities'
    | '/bak/ephemeral-jobs'
    | '/bak/home'
    | '/bak/memberships'
    | '/bak/migration'
    | '/bak/new-subscription'
    | '/bak/organizations'
    | '/bak/search'
    | '/bak/tracking-doc'
    | '/contract/$subscriptionId'
    | '/health-insurance/beneficiaries'
    | '/health-insurance/cpms'
    | '/health-insurance/santeclair'
    | '/insurance-certificate-generator-real-estate-agents/$subscriptionId'
    | '/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId'
    | '/payment-methods/new'
    | '/payment-methods/update'
    | '/bo/admin/cpms'
    | '/bo/admin/ima'
    | '/bo/admin/jobs'
    | '/bo/admin/time'
    | '/bo/documentation/activity-specific-questions'
    | '/bo/documentation/exclusions'
    | '/bo/documentation/general-terms'
    | '/bo/documentation/roles'
    | '/bo/documentation/subscription-funnel-texts'
    | '/bo/invoicing/$invoiceId'
    | '/bo/invoicing/negative-invoices'
    | '/bo/subscriptions/new'
    | '/bo/users/$userId'
    | '/s/c/$token'
    | '/s/e-v2/$token'
    | '/s/q/$token'
    | '/subscribe/restaurants-v2/identify'
    | '/health-insurance'
    | '/bo/admin'
    | '/bo/documentation'
    | '/bo/invoicing'
    | '/bo/subscriptions'
    | '/bo/users'
    | '/bak/admin/cpms'
    | '/bak/admin/ima'
    | '/bak/admin/jobs'
    | '/bak/documentation/activity-specific-questions'
    | '/bak/documentation/exclusions'
    | '/bak/documentation/general-terms'
    | '/bak/documentation/roles'
    | '/bak/documentation/subscription-funnel-texts'
    | '/bak/invoicing/negative-invoices-list'
    | '/bak/users/$userId'
    | '/partner/$organization/home'
    | '/partner/$organization/new-subscription'
    | '/partner/$organization/search'
    | '/product-guarantees/$subscriptionId/$productName'
    | '/s/r/$token'
    | '/bo/contracts/$subscriptionId/status'
    | '/bo/subscriptions/$subscriptionId/detailed-sheet'
    | '/bo/subscriptions/$subscriptionId/history'
    | '/bo/subscriptions/$subscriptionId/payment'
    | '/bo/troubleshooting/sessions/$sessionId'
    | '/bak/admin'
    | '/bak/documentation'
    | '/bak/invoicing'
    | '/bak/pending-subscriptions'
    | '/bak/users'
    | '/bo/claims/$claimId'
    | '/bo/contracts/$subscriptionId'
    | '/bo/subscriptions/$subscriptionId'
    | '/subscribe/$subscriptionId/$stepId'
    | '/bak/contracts/$subscriptionId/invoicing'
    | '/bak/contracts/$subscriptionId/new-invoice'
    | '/bak/contracts/$subscriptionId/status'
    | '/bak/pending-subscriptions/$subscriptionId/history'
    | '/bak/pending-subscriptions/$subscriptionId/payment'
    | '/bak/troubleshooting/session/$sessionId'
    | '/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet'
    | '/partner/$organization/claims/$claimId'
    | '/partner/$organization/contracts/$subscriptionId'
    | '/partner/$organization/users/$userId'
    | '/bo/contracts/$subscriptionId/invoices/debug'
    | '/bo/contracts/$subscriptionId/invoices/new'
    | '/bo/contracts/$subscriptionId/renewals/$endorsementId'
    | '/subscribe/$subscriptionId/$stepId/$detailType/$detailId'
    | '/bak/claims/$claimId'
    | '/bak/contracts/$subscriptionId'
    | '/bak/v2-pending-subscriptions/$subscriptionId'
    | '/partner/$organization/pending-subscriptions'
    | '/partner/$organization/users'
    | '/bak/contracts/$subscriptionId/renewal/$endorsementId'
    | '/partner/$organization/pending-subscriptions/$subscriptionId/payment'
    | '/partner/$organization/v2-pending-subscriptions/$subscriptionId/detailed-sheet'
    | '/bo/contracts/$subscriptionId/detailed-sheet/versions/$versionId'
    | '/bo/contracts/$subscriptionId/endorsements/$endorsementId/detailed-sheet'
    | '/partner/$organization/v2-pending-subscriptions/$subscriptionId'
    | '/bo/contracts/$subscriptionId/endorsements/$endorsementId'
    | '/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId'
    | '/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet'
    | '/bak/contracts/$subscriptionId/endorsement/$endorsementId'
  id:
    | '__root__'
    | '/'
    | '/impersonate'
    | '/login'
    | '/payment-method-update'
    | '/receive-quote'
    | '/search'
    | '/subscribe-no-possible-step'
    | '/unsubscribe'
    | '/_customer'
    | '/bo'
    | '/_customer/account'
    | '/_customer/contract-guarantees'
    | '/_customer/contracts'
    | '/_customer/home'
    | '/_customer/invoices'
    | '/_customer/report-claim'
    | '/bo/activities'
    | '/bo/ephemeral-jobs'
    | '/bo/memberships'
    | '/bo/migration'
    | '/bo/organizations'
    | '/bo/search'
    | '/bo/searchV2'
    | '/bo/tracking-doc'
    | '/payment/authentication'
    | '/subscribe-exclusion/$subscriptionId'
    | '/subscribe/landing'
    | '/bo/'
    | '/(redirects)/bak/activities'
    | '/(redirects)/bak/ephemeral-jobs'
    | '/(redirects)/bak/home'
    | '/(redirects)/bak/memberships'
    | '/(redirects)/bak/migration'
    | '/(redirects)/bak/new-subscription'
    | '/(redirects)/bak/organizations'
    | '/(redirects)/bak/search'
    | '/(redirects)/bak/tracking-doc'
    | '/_customer/contract/$subscriptionId'
    | '/_customer/health-insurance/beneficiaries'
    | '/_customer/health-insurance/cpms'
    | '/_customer/health-insurance/santeclair'
    | '/_customer/insurance-certificate-generator-real-estate-agents/$subscriptionId'
    | '/_customer/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId'
    | '/_customer/payment-methods/new'
    | '/_customer/payment-methods/update'
    | '/bo/admin/cpms'
    | '/bo/admin/ima'
    | '/bo/admin/jobs'
    | '/bo/admin/time'
    | '/bo/documentation/activity-specific-questions'
    | '/bo/documentation/exclusions'
    | '/bo/documentation/general-terms'
    | '/bo/documentation/roles'
    | '/bo/documentation/subscription-funnel-texts'
    | '/bo/invoicing/$invoiceId'
    | '/bo/invoicing/negative-invoices'
    | '/bo/subscriptions/new'
    | '/bo/users/$userId'
    | '/s/c/$token'
    | '/s/e-v2/$token'
    | '/s/q/$token'
    | '/subscribe/restaurants-v2/identify'
    | '/_customer/health-insurance/'
    | '/bo/admin/'
    | '/bo/documentation/'
    | '/bo/invoicing/'
    | '/bo/subscriptions/'
    | '/bo/users/'
    | '/(redirects)/bak/admin/cpms'
    | '/(redirects)/bak/admin/ima'
    | '/(redirects)/bak/admin/jobs'
    | '/(redirects)/bak/documentation/activity-specific-questions'
    | '/(redirects)/bak/documentation/exclusions'
    | '/(redirects)/bak/documentation/general-terms'
    | '/(redirects)/bak/documentation/roles'
    | '/(redirects)/bak/documentation/subscription-funnel-texts'
    | '/(redirects)/bak/invoicing/negative-invoices-list'
    | '/(redirects)/bak/users/$userId'
    | '/(redirects)/partner/$organization/home'
    | '/(redirects)/partner/$organization/new-subscription'
    | '/(redirects)/partner/$organization/search'
    | '/_customer/product-guarantees/$subscriptionId/$productName'
    | '/_customer/s/r/$token'
    | '/bo/contracts/$subscriptionId/status'
    | '/bo/subscriptions/$subscriptionId/detailed-sheet'
    | '/bo/subscriptions/$subscriptionId/history'
    | '/bo/subscriptions/$subscriptionId/payment'
    | '/bo/troubleshooting/sessions/$sessionId'
    | '/(redirects)/bak/admin/'
    | '/(redirects)/bak/documentation/'
    | '/(redirects)/bak/invoicing/'
    | '/(redirects)/bak/pending-subscriptions/'
    | '/(redirects)/bak/users/'
    | '/bo/claims/$claimId/'
    | '/bo/contracts/$subscriptionId/'
    | '/bo/subscriptions/$subscriptionId/'
    | '/subscribe/$subscriptionId/$stepId/'
    | '/(redirects)/bak/contracts/$subscriptionId/invoicing'
    | '/(redirects)/bak/contracts/$subscriptionId/new-invoice'
    | '/(redirects)/bak/contracts/$subscriptionId/status'
    | '/(redirects)/bak/pending-subscriptions/$subscriptionId/history'
    | '/(redirects)/bak/pending-subscriptions/$subscriptionId/payment'
    | '/(redirects)/bak/troubleshooting/session/$sessionId'
    | '/(redirects)/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet'
    | '/(redirects)/partner/$organization/claims/$claimId'
    | '/(redirects)/partner/$organization/contracts/$subscriptionId'
    | '/(redirects)/partner/$organization/users/$userId'
    | '/bo/contracts/$subscriptionId/invoices/debug'
    | '/bo/contracts/$subscriptionId/invoices/new'
    | '/bo/contracts/$subscriptionId/renewals/$endorsementId'
    | '/subscribe/$subscriptionId/$stepId/$detailType/$detailId'
    | '/(redirects)/bak/claims/$claimId/'
    | '/(redirects)/bak/contracts/$subscriptionId/'
    | '/(redirects)/bak/v2-pending-subscriptions/$subscriptionId/'
    | '/(redirects)/partner/$organization/pending-subscriptions/'
    | '/(redirects)/partner/$organization/users/'
    | '/(redirects)/bak/contracts/$subscriptionId/renewal/$endorsementId'
    | '/(redirects)/partner/$organization/pending-subscriptions/$subscriptionId/payment'
    | '/(redirects)/partner/$organization/v2-pending-subscriptions/$subscriptionId/detailed-sheet'
    | '/bo/contracts/$subscriptionId/detailed-sheet/versions/$versionId'
    | '/bo/contracts/$subscriptionId/endorsements/$endorsementId/detailed-sheet'
    | '/(redirects)/partner/$organization/v2-pending-subscriptions/$subscriptionId/'
    | '/bo/contracts/$subscriptionId/endorsements/$endorsementId/'
    | '/(redirects)/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId'
    | '/(redirects)/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet'
    | '/(redirects)/bak/contracts/$subscriptionId/endorsement/$endorsementId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  ImpersonateRouteRoute: typeof ImpersonateRouteRoute
  LoginRouteRoute: typeof LoginRouteRoute
  PaymentMethodUpdateRouteRoute: typeof PaymentMethodUpdateRouteRoute
  ReceiveQuoteRouteRoute: typeof ReceiveQuoteRouteRoute
  SearchRouteRoute: typeof SearchRouteRoute
  SubscribeNoPossibleStepRouteRoute: typeof SubscribeNoPossibleStepRouteRoute
  UnsubscribeRouteRoute: typeof UnsubscribeRouteRoute
  CustomerRoute: typeof CustomerRouteWithChildren
  BoRoute: typeof BoRouteWithChildren
  PaymentAuthenticationRouteRoute: typeof PaymentAuthenticationRouteRoute
  SubscribeExclusionSubscriptionIdRouteRoute: typeof SubscribeExclusionSubscriptionIdRouteRoute
  SubscribeLandingRouteRoute: typeof SubscribeLandingRouteRoute
  redirectsBakActivitiesRouteRoute: typeof redirectsBakActivitiesRouteRoute
  redirectsBakEphemeralJobsRouteRoute: typeof redirectsBakEphemeralJobsRouteRoute
  redirectsBakHomeRouteRoute: typeof redirectsBakHomeRouteRoute
  redirectsBakMembershipsRouteRoute: typeof redirectsBakMembershipsRouteRoute
  redirectsBakMigrationRouteRoute: typeof redirectsBakMigrationRouteRoute
  redirectsBakNewSubscriptionRouteRoute: typeof redirectsBakNewSubscriptionRouteRoute
  redirectsBakOrganizationsRouteRoute: typeof redirectsBakOrganizationsRouteRoute
  redirectsBakSearchRouteRoute: typeof redirectsBakSearchRouteRoute
  redirectsBakTrackingDocRouteRoute: typeof redirectsBakTrackingDocRouteRoute
  SCTokenRouteRoute: typeof SCTokenRouteRoute
  SEV2TokenRouteRoute: typeof SEV2TokenRouteRoute
  SQTokenRouteRoute: typeof SQTokenRouteRoute
  SubscribeRestaurantsV2IdentifyRouteRoute: typeof SubscribeRestaurantsV2IdentifyRouteRoute
  redirectsBakAdminCpmsRouteRoute: typeof redirectsBakAdminCpmsRouteRoute
  redirectsBakAdminImaRouteRoute: typeof redirectsBakAdminImaRouteRoute
  redirectsBakAdminJobsRouteRoute: typeof redirectsBakAdminJobsRouteRoute
  redirectsBakDocumentationActivitySpecificQuestionsRouteRoute: typeof redirectsBakDocumentationActivitySpecificQuestionsRouteRoute
  redirectsBakDocumentationExclusionsRouteRoute: typeof redirectsBakDocumentationExclusionsRouteRoute
  redirectsBakDocumentationGeneralTermsRouteRoute: typeof redirectsBakDocumentationGeneralTermsRouteRoute
  redirectsBakDocumentationRolesRouteRoute: typeof redirectsBakDocumentationRolesRouteRoute
  redirectsBakDocumentationSubscriptionFunnelTextsRouteRoute: typeof redirectsBakDocumentationSubscriptionFunnelTextsRouteRoute
  redirectsBakInvoicingNegativeInvoicesListRouteRoute: typeof redirectsBakInvoicingNegativeInvoicesListRouteRoute
  redirectsBakUsersUserIdRouteRoute: typeof redirectsBakUsersUserIdRouteRoute
  redirectsPartnerOrganizationHomeRouteRoute: typeof redirectsPartnerOrganizationHomeRouteRoute
  redirectsPartnerOrganizationNewSubscriptionRouteRoute: typeof redirectsPartnerOrganizationNewSubscriptionRouteRoute
  redirectsPartnerOrganizationSearchRouteRoute: typeof redirectsPartnerOrganizationSearchRouteRoute
  redirectsBakAdminIndexRoute: typeof redirectsBakAdminIndexRoute
  redirectsBakDocumentationIndexRoute: typeof redirectsBakDocumentationIndexRoute
  redirectsBakInvoicingIndexRoute: typeof redirectsBakInvoicingIndexRoute
  redirectsBakPendingSubscriptionsIndexRoute: typeof redirectsBakPendingSubscriptionsIndexRoute
  redirectsBakUsersIndexRoute: typeof redirectsBakUsersIndexRoute
  SubscribeSubscriptionIdStepIdIndexRoute: typeof SubscribeSubscriptionIdStepIdIndexRoute
  redirectsBakContractsSubscriptionIdInvoicingRouteRoute: typeof redirectsBakContractsSubscriptionIdInvoicingRouteRoute
  redirectsBakContractsSubscriptionIdNewInvoiceRouteRoute: typeof redirectsBakContractsSubscriptionIdNewInvoiceRouteRoute
  redirectsBakContractsSubscriptionIdStatusRouteRoute: typeof redirectsBakContractsSubscriptionIdStatusRouteRoute
  redirectsBakPendingSubscriptionsSubscriptionIdHistoryRouteRoute: typeof redirectsBakPendingSubscriptionsSubscriptionIdHistoryRouteRoute
  redirectsBakPendingSubscriptionsSubscriptionIdPaymentRouteRoute: typeof redirectsBakPendingSubscriptionsSubscriptionIdPaymentRouteRoute
  redirectsBakTroubleshootingSessionSessionIdRouteRoute: typeof redirectsBakTroubleshootingSessionSessionIdRouteRoute
  redirectsBakV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute: typeof redirectsBakV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute
  redirectsPartnerOrganizationClaimsClaimIdRouteRoute: typeof redirectsPartnerOrganizationClaimsClaimIdRouteRoute
  redirectsPartnerOrganizationContractsSubscriptionIdRouteRoute: typeof redirectsPartnerOrganizationContractsSubscriptionIdRouteRoute
  redirectsPartnerOrganizationUsersUserIdRouteRoute: typeof redirectsPartnerOrganizationUsersUserIdRouteRoute
  SubscribeSubscriptionIdStepIdDetailTypeDetailIdRouteRoute: typeof SubscribeSubscriptionIdStepIdDetailTypeDetailIdRouteRoute
  redirectsBakClaimsClaimIdIndexRoute: typeof redirectsBakClaimsClaimIdIndexRoute
  redirectsBakContractsSubscriptionIdIndexRoute: typeof redirectsBakContractsSubscriptionIdIndexRoute
  redirectsBakV2PendingSubscriptionsSubscriptionIdIndexRoute: typeof redirectsBakV2PendingSubscriptionsSubscriptionIdIndexRoute
  redirectsPartnerOrganizationPendingSubscriptionsIndexRoute: typeof redirectsPartnerOrganizationPendingSubscriptionsIndexRoute
  redirectsPartnerOrganizationUsersIndexRoute: typeof redirectsPartnerOrganizationUsersIndexRoute
  redirectsBakContractsSubscriptionIdRenewalEndorsementIdRouteRoute: typeof redirectsBakContractsSubscriptionIdRenewalEndorsementIdRouteRoute
  redirectsPartnerOrganizationPendingSubscriptionsSubscriptionIdPaymentRouteRoute: typeof redirectsPartnerOrganizationPendingSubscriptionsSubscriptionIdPaymentRouteRoute
  redirectsPartnerOrganizationV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute: typeof redirectsPartnerOrganizationV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute
  redirectsPartnerOrganizationV2PendingSubscriptionsSubscriptionIdIndexRoute: typeof redirectsPartnerOrganizationV2PendingSubscriptionsSubscriptionIdIndexRoute
  redirectsBakContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute: typeof redirectsBakContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute
  redirectsBakContractsSubscriptionIdEndorsementEndorsementIdDetailedSheetRouteRoute: typeof redirectsBakContractsSubscriptionIdEndorsementEndorsementIdDetailedSheetRouteRoute
  redirectsBakContractsSubscriptionIdEndorsementEndorsementIdIndexRoute: typeof redirectsBakContractsSubscriptionIdEndorsementEndorsementIdIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  ImpersonateRouteRoute: ImpersonateRouteRoute,
  LoginRouteRoute: LoginRouteRoute,
  PaymentMethodUpdateRouteRoute: PaymentMethodUpdateRouteRoute,
  ReceiveQuoteRouteRoute: ReceiveQuoteRouteRoute,
  SearchRouteRoute: SearchRouteRoute,
  SubscribeNoPossibleStepRouteRoute: SubscribeNoPossibleStepRouteRoute,
  UnsubscribeRouteRoute: UnsubscribeRouteRoute,
  CustomerRoute: CustomerRouteWithChildren,
  BoRoute: BoRouteWithChildren,
  PaymentAuthenticationRouteRoute: PaymentAuthenticationRouteRoute,
  SubscribeExclusionSubscriptionIdRouteRoute:
    SubscribeExclusionSubscriptionIdRouteRoute,
  SubscribeLandingRouteRoute: SubscribeLandingRouteRoute,
  redirectsBakActivitiesRouteRoute: redirectsBakActivitiesRouteRoute,
  redirectsBakEphemeralJobsRouteRoute: redirectsBakEphemeralJobsRouteRoute,
  redirectsBakHomeRouteRoute: redirectsBakHomeRouteRoute,
  redirectsBakMembershipsRouteRoute: redirectsBakMembershipsRouteRoute,
  redirectsBakMigrationRouteRoute: redirectsBakMigrationRouteRoute,
  redirectsBakNewSubscriptionRouteRoute: redirectsBakNewSubscriptionRouteRoute,
  redirectsBakOrganizationsRouteRoute: redirectsBakOrganizationsRouteRoute,
  redirectsBakSearchRouteRoute: redirectsBakSearchRouteRoute,
  redirectsBakTrackingDocRouteRoute: redirectsBakTrackingDocRouteRoute,
  SCTokenRouteRoute: SCTokenRouteRoute,
  SEV2TokenRouteRoute: SEV2TokenRouteRoute,
  SQTokenRouteRoute: SQTokenRouteRoute,
  SubscribeRestaurantsV2IdentifyRouteRoute:
    SubscribeRestaurantsV2IdentifyRouteRoute,
  redirectsBakAdminCpmsRouteRoute: redirectsBakAdminCpmsRouteRoute,
  redirectsBakAdminImaRouteRoute: redirectsBakAdminImaRouteRoute,
  redirectsBakAdminJobsRouteRoute: redirectsBakAdminJobsRouteRoute,
  redirectsBakDocumentationActivitySpecificQuestionsRouteRoute:
    redirectsBakDocumentationActivitySpecificQuestionsRouteRoute,
  redirectsBakDocumentationExclusionsRouteRoute:
    redirectsBakDocumentationExclusionsRouteRoute,
  redirectsBakDocumentationGeneralTermsRouteRoute:
    redirectsBakDocumentationGeneralTermsRouteRoute,
  redirectsBakDocumentationRolesRouteRoute:
    redirectsBakDocumentationRolesRouteRoute,
  redirectsBakDocumentationSubscriptionFunnelTextsRouteRoute:
    redirectsBakDocumentationSubscriptionFunnelTextsRouteRoute,
  redirectsBakInvoicingNegativeInvoicesListRouteRoute:
    redirectsBakInvoicingNegativeInvoicesListRouteRoute,
  redirectsBakUsersUserIdRouteRoute: redirectsBakUsersUserIdRouteRoute,
  redirectsPartnerOrganizationHomeRouteRoute:
    redirectsPartnerOrganizationHomeRouteRoute,
  redirectsPartnerOrganizationNewSubscriptionRouteRoute:
    redirectsPartnerOrganizationNewSubscriptionRouteRoute,
  redirectsPartnerOrganizationSearchRouteRoute:
    redirectsPartnerOrganizationSearchRouteRoute,
  redirectsBakAdminIndexRoute: redirectsBakAdminIndexRoute,
  redirectsBakDocumentationIndexRoute: redirectsBakDocumentationIndexRoute,
  redirectsBakInvoicingIndexRoute: redirectsBakInvoicingIndexRoute,
  redirectsBakPendingSubscriptionsIndexRoute:
    redirectsBakPendingSubscriptionsIndexRoute,
  redirectsBakUsersIndexRoute: redirectsBakUsersIndexRoute,
  SubscribeSubscriptionIdStepIdIndexRoute:
    SubscribeSubscriptionIdStepIdIndexRoute,
  redirectsBakContractsSubscriptionIdInvoicingRouteRoute:
    redirectsBakContractsSubscriptionIdInvoicingRouteRoute,
  redirectsBakContractsSubscriptionIdNewInvoiceRouteRoute:
    redirectsBakContractsSubscriptionIdNewInvoiceRouteRoute,
  redirectsBakContractsSubscriptionIdStatusRouteRoute:
    redirectsBakContractsSubscriptionIdStatusRouteRoute,
  redirectsBakPendingSubscriptionsSubscriptionIdHistoryRouteRoute:
    redirectsBakPendingSubscriptionsSubscriptionIdHistoryRouteRoute,
  redirectsBakPendingSubscriptionsSubscriptionIdPaymentRouteRoute:
    redirectsBakPendingSubscriptionsSubscriptionIdPaymentRouteRoute,
  redirectsBakTroubleshootingSessionSessionIdRouteRoute:
    redirectsBakTroubleshootingSessionSessionIdRouteRoute,
  redirectsBakV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute:
    redirectsBakV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute,
  redirectsPartnerOrganizationClaimsClaimIdRouteRoute:
    redirectsPartnerOrganizationClaimsClaimIdRouteRoute,
  redirectsPartnerOrganizationContractsSubscriptionIdRouteRoute:
    redirectsPartnerOrganizationContractsSubscriptionIdRouteRoute,
  redirectsPartnerOrganizationUsersUserIdRouteRoute:
    redirectsPartnerOrganizationUsersUserIdRouteRoute,
  SubscribeSubscriptionIdStepIdDetailTypeDetailIdRouteRoute:
    SubscribeSubscriptionIdStepIdDetailTypeDetailIdRouteRoute,
  redirectsBakClaimsClaimIdIndexRoute: redirectsBakClaimsClaimIdIndexRoute,
  redirectsBakContractsSubscriptionIdIndexRoute:
    redirectsBakContractsSubscriptionIdIndexRoute,
  redirectsBakV2PendingSubscriptionsSubscriptionIdIndexRoute:
    redirectsBakV2PendingSubscriptionsSubscriptionIdIndexRoute,
  redirectsPartnerOrganizationPendingSubscriptionsIndexRoute:
    redirectsPartnerOrganizationPendingSubscriptionsIndexRoute,
  redirectsPartnerOrganizationUsersIndexRoute:
    redirectsPartnerOrganizationUsersIndexRoute,
  redirectsBakContractsSubscriptionIdRenewalEndorsementIdRouteRoute:
    redirectsBakContractsSubscriptionIdRenewalEndorsementIdRouteRoute,
  redirectsPartnerOrganizationPendingSubscriptionsSubscriptionIdPaymentRouteRoute:
    redirectsPartnerOrganizationPendingSubscriptionsSubscriptionIdPaymentRouteRoute,
  redirectsPartnerOrganizationV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute:
    redirectsPartnerOrganizationV2PendingSubscriptionsSubscriptionIdDetailedSheetRouteRoute,
  redirectsPartnerOrganizationV2PendingSubscriptionsSubscriptionIdIndexRoute:
    redirectsPartnerOrganizationV2PendingSubscriptionsSubscriptionIdIndexRoute,
  redirectsBakContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute:
    redirectsBakContractsSubscriptionIdDetailedSheetVersionsVersionIdRouteRoute,
  redirectsBakContractsSubscriptionIdEndorsementEndorsementIdDetailedSheetRouteRoute:
    redirectsBakContractsSubscriptionIdEndorsementEndorsementIdDetailedSheetRouteRoute,
  redirectsBakContractsSubscriptionIdEndorsementEndorsementIdIndexRoute:
    redirectsBakContractsSubscriptionIdEndorsementEndorsementIdIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/impersonate",
        "/login",
        "/payment-method-update",
        "/receive-quote",
        "/search",
        "/subscribe-no-possible-step",
        "/unsubscribe",
        "/_customer",
        "/bo",
        "/payment/authentication",
        "/subscribe-exclusion/$subscriptionId",
        "/subscribe/landing",
        "/(redirects)/bak/activities",
        "/(redirects)/bak/ephemeral-jobs",
        "/(redirects)/bak/home",
        "/(redirects)/bak/memberships",
        "/(redirects)/bak/migration",
        "/(redirects)/bak/new-subscription",
        "/(redirects)/bak/organizations",
        "/(redirects)/bak/search",
        "/(redirects)/bak/tracking-doc",
        "/s/c/$token",
        "/s/e-v2/$token",
        "/s/q/$token",
        "/subscribe/restaurants-v2/identify",
        "/(redirects)/bak/admin/cpms",
        "/(redirects)/bak/admin/ima",
        "/(redirects)/bak/admin/jobs",
        "/(redirects)/bak/documentation/activity-specific-questions",
        "/(redirects)/bak/documentation/exclusions",
        "/(redirects)/bak/documentation/general-terms",
        "/(redirects)/bak/documentation/roles",
        "/(redirects)/bak/documentation/subscription-funnel-texts",
        "/(redirects)/bak/invoicing/negative-invoices-list",
        "/(redirects)/bak/users/$userId",
        "/(redirects)/partner/$organization/home",
        "/(redirects)/partner/$organization/new-subscription",
        "/(redirects)/partner/$organization/search",
        "/(redirects)/bak/admin/",
        "/(redirects)/bak/documentation/",
        "/(redirects)/bak/invoicing/",
        "/(redirects)/bak/pending-subscriptions/",
        "/(redirects)/bak/users/",
        "/subscribe/$subscriptionId/$stepId/",
        "/(redirects)/bak/contracts/$subscriptionId/invoicing",
        "/(redirects)/bak/contracts/$subscriptionId/new-invoice",
        "/(redirects)/bak/contracts/$subscriptionId/status",
        "/(redirects)/bak/pending-subscriptions/$subscriptionId/history",
        "/(redirects)/bak/pending-subscriptions/$subscriptionId/payment",
        "/(redirects)/bak/troubleshooting/session/$sessionId",
        "/(redirects)/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet",
        "/(redirects)/partner/$organization/claims/$claimId",
        "/(redirects)/partner/$organization/contracts/$subscriptionId",
        "/(redirects)/partner/$organization/users/$userId",
        "/subscribe/$subscriptionId/$stepId/$detailType/$detailId",
        "/(redirects)/bak/claims/$claimId/",
        "/(redirects)/bak/contracts/$subscriptionId/",
        "/(redirects)/bak/v2-pending-subscriptions/$subscriptionId/",
        "/(redirects)/partner/$organization/pending-subscriptions/",
        "/(redirects)/partner/$organization/users/",
        "/(redirects)/bak/contracts/$subscriptionId/renewal/$endorsementId",
        "/(redirects)/partner/$organization/pending-subscriptions/$subscriptionId/payment",
        "/(redirects)/partner/$organization/v2-pending-subscriptions/$subscriptionId/detailed-sheet",
        "/(redirects)/partner/$organization/v2-pending-subscriptions/$subscriptionId/",
        "/(redirects)/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId",
        "/(redirects)/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet",
        "/(redirects)/bak/contracts/$subscriptionId/endorsement/$endorsementId/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/impersonate": {
      "filePath": "impersonate/route.tsx"
    },
    "/login": {
      "filePath": "login/route.tsx"
    },
    "/payment-method-update": {
      "filePath": "payment-method-update/route.tsx"
    },
    "/receive-quote": {
      "filePath": "receive-quote/route.tsx"
    },
    "/search": {
      "filePath": "search/route.tsx"
    },
    "/subscribe-no-possible-step": {
      "filePath": "subscribe-no-possible-step/route.tsx"
    },
    "/unsubscribe": {
      "filePath": "unsubscribe/route.tsx"
    },
    "/_customer": {
      "filePath": "_customer.tsx",
      "children": [
        "/_customer/account",
        "/_customer/contract-guarantees",
        "/_customer/contracts",
        "/_customer/home",
        "/_customer/invoices",
        "/_customer/report-claim",
        "/_customer/contract/$subscriptionId",
        "/_customer/health-insurance/beneficiaries",
        "/_customer/health-insurance/cpms",
        "/_customer/health-insurance/santeclair",
        "/_customer/insurance-certificate-generator-real-estate-agents/$subscriptionId",
        "/_customer/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId",
        "/_customer/payment-methods/new",
        "/_customer/payment-methods/update",
        "/_customer/health-insurance/",
        "/_customer/product-guarantees/$subscriptionId/$productName",
        "/_customer/s/r/$token"
      ]
    },
    "/bo": {
      "filePath": "bo.tsx",
      "children": [
        "/bo/activities",
        "/bo/ephemeral-jobs",
        "/bo/memberships",
        "/bo/migration",
        "/bo/organizations",
        "/bo/search",
        "/bo/searchV2",
        "/bo/tracking-doc",
        "/bo/",
        "/bo/admin/cpms",
        "/bo/admin/ima",
        "/bo/admin/jobs",
        "/bo/admin/time",
        "/bo/documentation/activity-specific-questions",
        "/bo/documentation/exclusions",
        "/bo/documentation/general-terms",
        "/bo/documentation/roles",
        "/bo/documentation/subscription-funnel-texts",
        "/bo/invoicing/$invoiceId",
        "/bo/invoicing/negative-invoices",
        "/bo/subscriptions/new",
        "/bo/users/$userId",
        "/bo/admin/",
        "/bo/documentation/",
        "/bo/invoicing/",
        "/bo/subscriptions/",
        "/bo/users/",
        "/bo/contracts/$subscriptionId/status",
        "/bo/subscriptions/$subscriptionId/detailed-sheet",
        "/bo/subscriptions/$subscriptionId/history",
        "/bo/subscriptions/$subscriptionId/payment",
        "/bo/troubleshooting/sessions/$sessionId",
        "/bo/claims/$claimId/",
        "/bo/contracts/$subscriptionId/",
        "/bo/subscriptions/$subscriptionId/",
        "/bo/contracts/$subscriptionId/invoices/debug",
        "/bo/contracts/$subscriptionId/invoices/new",
        "/bo/contracts/$subscriptionId/renewals/$endorsementId",
        "/bo/contracts/$subscriptionId/detailed-sheet/versions/$versionId",
        "/bo/contracts/$subscriptionId/endorsements/$endorsementId/detailed-sheet",
        "/bo/contracts/$subscriptionId/endorsements/$endorsementId/"
      ]
    },
    "/_customer/account": {
      "filePath": "_customer/account/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/contract-guarantees": {
      "filePath": "_customer/contract-guarantees/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/contracts": {
      "filePath": "_customer/contracts/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/home": {
      "filePath": "_customer/home/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/invoices": {
      "filePath": "_customer/invoices/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/report-claim": {
      "filePath": "_customer/report-claim/route.tsx",
      "parent": "/_customer"
    },
    "/bo/activities": {
      "filePath": "bo/activities/route.tsx",
      "parent": "/bo"
    },
    "/bo/ephemeral-jobs": {
      "filePath": "bo/ephemeral-jobs/route.tsx",
      "parent": "/bo"
    },
    "/bo/memberships": {
      "filePath": "bo/memberships/route.tsx",
      "parent": "/bo"
    },
    "/bo/migration": {
      "filePath": "bo/migration/route.tsx",
      "parent": "/bo"
    },
    "/bo/organizations": {
      "filePath": "bo/organizations/route.tsx",
      "parent": "/bo"
    },
    "/bo/search": {
      "filePath": "bo/search/route.tsx",
      "parent": "/bo"
    },
    "/bo/searchV2": {
      "filePath": "bo/searchV2/route.tsx",
      "parent": "/bo"
    },
    "/bo/tracking-doc": {
      "filePath": "bo/tracking-doc/route.tsx",
      "parent": "/bo"
    },
    "/payment/authentication": {
      "filePath": "payment/authentication/route.tsx"
    },
    "/subscribe-exclusion/$subscriptionId": {
      "filePath": "subscribe-exclusion/$subscriptionId/route.tsx"
    },
    "/subscribe/landing": {
      "filePath": "subscribe/landing/route.tsx"
    },
    "/bo/": {
      "filePath": "bo/index.tsx",
      "parent": "/bo"
    },
    "/(redirects)/bak/activities": {
      "filePath": "(redirects)/bak/activities/route.tsx"
    },
    "/(redirects)/bak/ephemeral-jobs": {
      "filePath": "(redirects)/bak/ephemeral-jobs/route.tsx"
    },
    "/(redirects)/bak/home": {
      "filePath": "(redirects)/bak/home/route.tsx"
    },
    "/(redirects)/bak/memberships": {
      "filePath": "(redirects)/bak/memberships/route.tsx"
    },
    "/(redirects)/bak/migration": {
      "filePath": "(redirects)/bak/migration/route.tsx"
    },
    "/(redirects)/bak/new-subscription": {
      "filePath": "(redirects)/bak/new-subscription/route.tsx"
    },
    "/(redirects)/bak/organizations": {
      "filePath": "(redirects)/bak/organizations/route.tsx"
    },
    "/(redirects)/bak/search": {
      "filePath": "(redirects)/bak/search/route.tsx"
    },
    "/(redirects)/bak/tracking-doc": {
      "filePath": "(redirects)/bak/tracking-doc/route.tsx"
    },
    "/_customer/contract/$subscriptionId": {
      "filePath": "_customer/contract/$subscriptionId/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/health-insurance/beneficiaries": {
      "filePath": "_customer/health-insurance/beneficiaries/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/health-insurance/cpms": {
      "filePath": "_customer/health-insurance/cpms/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/health-insurance/santeclair": {
      "filePath": "_customer/health-insurance/santeclair/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/insurance-certificate-generator-real-estate-agents/$subscriptionId": {
      "filePath": "_customer/insurance-certificate-generator-real-estate-agents/$subscriptionId/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId": {
      "filePath": "_customer/insurance-certificate-generator-real-estate-sales-agents/$subscriptionId/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/payment-methods/new": {
      "filePath": "_customer/payment-methods/new/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/payment-methods/update": {
      "filePath": "_customer/payment-methods/update/route.tsx",
      "parent": "/_customer"
    },
    "/bo/admin/cpms": {
      "filePath": "bo/admin/cpms/route.tsx",
      "parent": "/bo"
    },
    "/bo/admin/ima": {
      "filePath": "bo/admin/ima/route.tsx",
      "parent": "/bo"
    },
    "/bo/admin/jobs": {
      "filePath": "bo/admin/jobs/route.tsx",
      "parent": "/bo"
    },
    "/bo/admin/time": {
      "filePath": "bo/admin/time/route.tsx",
      "parent": "/bo"
    },
    "/bo/documentation/activity-specific-questions": {
      "filePath": "bo/documentation/activity-specific-questions/route.tsx",
      "parent": "/bo"
    },
    "/bo/documentation/exclusions": {
      "filePath": "bo/documentation/exclusions/route.tsx",
      "parent": "/bo"
    },
    "/bo/documentation/general-terms": {
      "filePath": "bo/documentation/general-terms/route.tsx",
      "parent": "/bo"
    },
    "/bo/documentation/roles": {
      "filePath": "bo/documentation/roles/route.tsx",
      "parent": "/bo"
    },
    "/bo/documentation/subscription-funnel-texts": {
      "filePath": "bo/documentation/subscription-funnel-texts/route.tsx",
      "parent": "/bo"
    },
    "/bo/invoicing/$invoiceId": {
      "filePath": "bo/invoicing/$invoiceId/route.tsx",
      "parent": "/bo"
    },
    "/bo/invoicing/negative-invoices": {
      "filePath": "bo/invoicing/negative-invoices/route.tsx",
      "parent": "/bo"
    },
    "/bo/subscriptions/new": {
      "filePath": "bo/subscriptions/new/route.tsx",
      "parent": "/bo"
    },
    "/bo/users/$userId": {
      "filePath": "bo/users/$userId/route.tsx",
      "parent": "/bo"
    },
    "/s/c/$token": {
      "filePath": "s/c/$token/route.tsx"
    },
    "/s/e-v2/$token": {
      "filePath": "s/e-v2/$token/route.tsx"
    },
    "/s/q/$token": {
      "filePath": "s/q/$token/route.tsx"
    },
    "/subscribe/restaurants-v2/identify": {
      "filePath": "subscribe/restaurants-v2/identify/route.tsx"
    },
    "/_customer/health-insurance/": {
      "filePath": "_customer/health-insurance/index.tsx",
      "parent": "/_customer"
    },
    "/bo/admin/": {
      "filePath": "bo/admin/index.tsx",
      "parent": "/bo"
    },
    "/bo/documentation/": {
      "filePath": "bo/documentation/index.tsx",
      "parent": "/bo"
    },
    "/bo/invoicing/": {
      "filePath": "bo/invoicing/index.tsx",
      "parent": "/bo"
    },
    "/bo/subscriptions/": {
      "filePath": "bo/subscriptions/index.tsx",
      "parent": "/bo"
    },
    "/bo/users/": {
      "filePath": "bo/users/index.tsx",
      "parent": "/bo"
    },
    "/(redirects)/bak/admin/cpms": {
      "filePath": "(redirects)/bak/admin/cpms/route.tsx"
    },
    "/(redirects)/bak/admin/ima": {
      "filePath": "(redirects)/bak/admin/ima/route.tsx"
    },
    "/(redirects)/bak/admin/jobs": {
      "filePath": "(redirects)/bak/admin/jobs/route.tsx"
    },
    "/(redirects)/bak/documentation/activity-specific-questions": {
      "filePath": "(redirects)/bak/documentation/activity-specific-questions/route.tsx"
    },
    "/(redirects)/bak/documentation/exclusions": {
      "filePath": "(redirects)/bak/documentation/exclusions/route.tsx"
    },
    "/(redirects)/bak/documentation/general-terms": {
      "filePath": "(redirects)/bak/documentation/general-terms/route.tsx"
    },
    "/(redirects)/bak/documentation/roles": {
      "filePath": "(redirects)/bak/documentation/roles/route.tsx"
    },
    "/(redirects)/bak/documentation/subscription-funnel-texts": {
      "filePath": "(redirects)/bak/documentation/subscription-funnel-texts/route.tsx"
    },
    "/(redirects)/bak/invoicing/negative-invoices-list": {
      "filePath": "(redirects)/bak/invoicing/negative-invoices-list/route.tsx"
    },
    "/(redirects)/bak/users/$userId": {
      "filePath": "(redirects)/bak/users/$userId/route.tsx"
    },
    "/(redirects)/partner/$organization/home": {
      "filePath": "(redirects)/partner/$organization/home/route.tsx"
    },
    "/(redirects)/partner/$organization/new-subscription": {
      "filePath": "(redirects)/partner/$organization/new-subscription/route.tsx"
    },
    "/(redirects)/partner/$organization/search": {
      "filePath": "(redirects)/partner/$organization/search/route.tsx"
    },
    "/_customer/product-guarantees/$subscriptionId/$productName": {
      "filePath": "_customer/product-guarantees/$subscriptionId/$productName/route.tsx",
      "parent": "/_customer"
    },
    "/_customer/s/r/$token": {
      "filePath": "_customer/s/r/$token/route.tsx",
      "parent": "/_customer"
    },
    "/bo/contracts/$subscriptionId/status": {
      "filePath": "bo/contracts/$subscriptionId/status/route.tsx",
      "parent": "/bo"
    },
    "/bo/subscriptions/$subscriptionId/detailed-sheet": {
      "filePath": "bo/subscriptions/$subscriptionId/detailed-sheet/route.tsx",
      "parent": "/bo"
    },
    "/bo/subscriptions/$subscriptionId/history": {
      "filePath": "bo/subscriptions/$subscriptionId/history/route.tsx",
      "parent": "/bo"
    },
    "/bo/subscriptions/$subscriptionId/payment": {
      "filePath": "bo/subscriptions/$subscriptionId/payment/route.tsx",
      "parent": "/bo"
    },
    "/bo/troubleshooting/sessions/$sessionId": {
      "filePath": "bo/troubleshooting/sessions/$sessionId/route.tsx",
      "parent": "/bo"
    },
    "/(redirects)/bak/admin/": {
      "filePath": "(redirects)/bak/admin/index.tsx"
    },
    "/(redirects)/bak/documentation/": {
      "filePath": "(redirects)/bak/documentation/index.tsx"
    },
    "/(redirects)/bak/invoicing/": {
      "filePath": "(redirects)/bak/invoicing/index.tsx"
    },
    "/(redirects)/bak/pending-subscriptions/": {
      "filePath": "(redirects)/bak/pending-subscriptions/index.tsx"
    },
    "/(redirects)/bak/users/": {
      "filePath": "(redirects)/bak/users/index.tsx"
    },
    "/bo/claims/$claimId/": {
      "filePath": "bo/claims/$claimId/index.tsx",
      "parent": "/bo"
    },
    "/bo/contracts/$subscriptionId/": {
      "filePath": "bo/contracts/$subscriptionId/index.tsx",
      "parent": "/bo"
    },
    "/bo/subscriptions/$subscriptionId/": {
      "filePath": "bo/subscriptions/$subscriptionId/index.tsx",
      "parent": "/bo"
    },
    "/subscribe/$subscriptionId/$stepId/": {
      "filePath": "subscribe/$subscriptionId/$stepId/index.tsx"
    },
    "/(redirects)/bak/contracts/$subscriptionId/invoicing": {
      "filePath": "(redirects)/bak/contracts/$subscriptionId/invoicing/route.tsx"
    },
    "/(redirects)/bak/contracts/$subscriptionId/new-invoice": {
      "filePath": "(redirects)/bak/contracts/$subscriptionId/new-invoice/route.tsx"
    },
    "/(redirects)/bak/contracts/$subscriptionId/status": {
      "filePath": "(redirects)/bak/contracts/$subscriptionId/status/route.tsx"
    },
    "/(redirects)/bak/pending-subscriptions/$subscriptionId/history": {
      "filePath": "(redirects)/bak/pending-subscriptions/$subscriptionId/history/route.tsx"
    },
    "/(redirects)/bak/pending-subscriptions/$subscriptionId/payment": {
      "filePath": "(redirects)/bak/pending-subscriptions/$subscriptionId/payment/route.tsx"
    },
    "/(redirects)/bak/troubleshooting/session/$sessionId": {
      "filePath": "(redirects)/bak/troubleshooting/session/$sessionId/route.tsx"
    },
    "/(redirects)/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet": {
      "filePath": "(redirects)/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet/route.tsx"
    },
    "/(redirects)/partner/$organization/claims/$claimId": {
      "filePath": "(redirects)/partner/$organization/claims/$claimId/route.tsx"
    },
    "/(redirects)/partner/$organization/contracts/$subscriptionId": {
      "filePath": "(redirects)/partner/$organization/contracts/$subscriptionId/route.tsx"
    },
    "/(redirects)/partner/$organization/users/$userId": {
      "filePath": "(redirects)/partner/$organization/users/$userId/route.tsx"
    },
    "/bo/contracts/$subscriptionId/invoices/debug": {
      "filePath": "bo/contracts/$subscriptionId/invoices/debug/route.tsx",
      "parent": "/bo"
    },
    "/bo/contracts/$subscriptionId/invoices/new": {
      "filePath": "bo/contracts/$subscriptionId/invoices/new/route.tsx",
      "parent": "/bo"
    },
    "/bo/contracts/$subscriptionId/renewals/$endorsementId": {
      "filePath": "bo/contracts/$subscriptionId/renewals/$endorsementId/route.tsx",
      "parent": "/bo"
    },
    "/subscribe/$subscriptionId/$stepId/$detailType/$detailId": {
      "filePath": "subscribe/$subscriptionId/$stepId/$detailType/$detailId/route.tsx"
    },
    "/(redirects)/bak/claims/$claimId/": {
      "filePath": "(redirects)/bak/claims/$claimId/index.tsx"
    },
    "/(redirects)/bak/contracts/$subscriptionId/": {
      "filePath": "(redirects)/bak/contracts/$subscriptionId/index.tsx"
    },
    "/(redirects)/bak/v2-pending-subscriptions/$subscriptionId/": {
      "filePath": "(redirects)/bak/v2-pending-subscriptions/$subscriptionId/index.tsx"
    },
    "/(redirects)/partner/$organization/pending-subscriptions/": {
      "filePath": "(redirects)/partner/$organization/pending-subscriptions/index.tsx"
    },
    "/(redirects)/partner/$organization/users/": {
      "filePath": "(redirects)/partner/$organization/users/index.tsx"
    },
    "/(redirects)/bak/contracts/$subscriptionId/renewal/$endorsementId": {
      "filePath": "(redirects)/bak/contracts/$subscriptionId/renewal/$endorsementId/route.tsx"
    },
    "/(redirects)/partner/$organization/pending-subscriptions/$subscriptionId/payment": {
      "filePath": "(redirects)/partner/$organization/pending-subscriptions/$subscriptionId/payment/route.tsx"
    },
    "/(redirects)/partner/$organization/v2-pending-subscriptions/$subscriptionId/detailed-sheet": {
      "filePath": "(redirects)/partner/$organization/v2-pending-subscriptions/$subscriptionId/detailed-sheet/route.tsx"
    },
    "/bo/contracts/$subscriptionId/detailed-sheet/versions/$versionId": {
      "filePath": "bo/contracts/$subscriptionId/detailed-sheet/versions/$versionId/route.tsx",
      "parent": "/bo"
    },
    "/bo/contracts/$subscriptionId/endorsements/$endorsementId/detailed-sheet": {
      "filePath": "bo/contracts/$subscriptionId/endorsements/$endorsementId/detailed-sheet/route.tsx",
      "parent": "/bo"
    },
    "/(redirects)/partner/$organization/v2-pending-subscriptions/$subscriptionId/": {
      "filePath": "(redirects)/partner/$organization/v2-pending-subscriptions/$subscriptionId/index.tsx"
    },
    "/bo/contracts/$subscriptionId/endorsements/$endorsementId/": {
      "filePath": "bo/contracts/$subscriptionId/endorsements/$endorsementId/index.tsx",
      "parent": "/bo"
    },
    "/(redirects)/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId": {
      "filePath": "(redirects)/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId/route.tsx"
    },
    "/(redirects)/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet": {
      "filePath": "(redirects)/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet/route.tsx"
    },
    "/(redirects)/bak/contracts/$subscriptionId/endorsement/$endorsementId/": {
      "filePath": "(redirects)/bak/contracts/$subscriptionId/endorsement/$endorsementId/index.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
