import { createFileRoute } from '@tanstack/react-router'
import DetailedSheetPage from '../../../../../components/pages/backoffice/detailed-sheet-page/DetailedSheetPage'
import { PermissionChecker } from '../../../../../components/templates/permission-checker'

export const Route = createFileRoute('/bo/subscriptions/$subscriptionId/detailed-sheet')({
  component: function Component() {
    const { subscriptionId } = Route.useParams()
    return (
      <PermissionChecker requiredPermissions={['subscription.read']}>
        <DetailedSheetPage subscriptionId={subscriptionId} endorsementId={undefined} versionId={undefined} />
      </PermissionChecker>
    )
  },
})
