import { createFileRoute } from '@tanstack/react-router'

import RolesDocumentationPage from '../../../../components/pages/backoffice/platform/platform-documentation/roles-documentation-page'
import { PermissionChecker } from '../../../../components/templates/permission-checker'

export const Route = createFileRoute('/bo/documentation/roles')({
  component: () => (
    <PermissionChecker requiredPermissions={['roles.read']}>
      <RolesDocumentationPage />
    </PermissionChecker>
  ),
})
