import { createFileRoute, Navigate } from '@tanstack/react-router'

export const Route = createFileRoute('/(redirects)/bak/troubleshooting/session/$sessionId')({
  component: () => (
    <Navigate
      replace
      search
      from="/bak/troubleshooting/session/$sessionId"
      to="/bo/troubleshooting/sessions/$sessionId"
    />
  ),
})
