import { borderRadius as borderRadiusTokensThemed } from './border-radius-tokens.json'

type BorderRadiusTokensKeys = 10 | 20 | 30 | 40 | 50 | 60 | 70

export const borderRadius = Object.keys(borderRadiusTokensThemed.light).reduce(
  (styles, tokenName) => ({
    ...styles,
    [tokenName.replace('border-radius-', '')]: `var(--${tokenName})`,
  }),
  {} as Record<BorderRadiusTokensKeys, string>,
)
