import { createFileRoute } from '@tanstack/react-router'
import DetailedSheetPage from '../../../../../../../components/pages/backoffice/detailed-sheet-page/DetailedSheetPage'
import { PermissionChecker } from '../../../../../../../components/templates/permission-checker'

export const Route = createFileRoute('/bo/contracts/$subscriptionId/endorsements/$endorsementId/detailed-sheet')({
  component: function Component() {
    const { subscriptionId, endorsementId } = Route.useParams()
    return (
      <PermissionChecker requiredPermissions={['endorsement.create']}>
        <DetailedSheetPage subscriptionId={subscriptionId} endorsementId={endorsementId} versionId={undefined} />
      </PermissionChecker>
    )
  },
})
