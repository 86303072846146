import { spacing as spacingTokensThemed } from './spacing-tokens.json'

export type Spacing = 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 100

export const spacing = Object.keys(spacingTokensThemed.light).reduce(
  (styles, tokenName) => ({
    ...styles,
    [tokenName.replace('spacing-', '')]: `var(--${tokenName})`,
  }),
  {} as Record<Spacing, string>,
)
