import { createFileRoute, Navigate } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/(redirects)/partner/$organization/pending-subscriptions/$subscriptionId/payment',
)({
  component: () => (
    <Navigate
      replace
      search
      from="/partner/$organization/pending-subscriptions/$subscriptionId/payment"
      to="/bo/subscriptions/$subscriptionId/payment"
    />
  ),
})
