import { borderStroke as borderStrokeTokensThemed } from './border-stroke-tokens.json'

type BorderStrokeTokensKeys = 10 | 20 | 30

export const borderStroke = Object.keys(borderStrokeTokensThemed.light).reduce(
  (styles, tokenName) => ({
    ...styles,
    [tokenName.replace('border-stroke-', '')]: `var(--${tokenName})`,
  }),
  {} as Record<BorderStrokeTokensKeys, string>,
)
