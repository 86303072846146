import { TechnicalError } from '@orus.eu/error'
import { createFileRoute } from '@tanstack/react-router'
import PartnersClaimPage from '../../../../components/pages/backoffice/partners/PartnersClaimPage'
import PlatformClaimPage from '../../../../components/pages/backoffice/platform/platform-claim-page/PlatformClaimPage'
import { useBackofficeUser } from '../../../../lib/use-permissions'

export const Route = createFileRoute('/bo/claims/$claimId/')({
  component: function Component() {
    const { claimId } = Route.useParams()
    const backofficeUser = useBackofficeUser()

    if (!backofficeUser) {
      throw new TechnicalError('There should have been a backoffice user at this point')
    }

    return backofficeUser.type === 'platform' ? (
      <PlatformClaimPage />
    ) : (
      <PartnersClaimPage claimId={claimId} organization={backofficeUser.organization} />
    )
  },
})
