import { createFileRoute } from '@tanstack/react-router'

import { TechnicalError } from '@orus.eu/error'
import PartnerSearchPage from '../../../components/pages/backoffice/partners/partners-search-page'
import PlatformSearchPage from '../../../components/pages/backoffice/platform/platform-search-page'
import { PermissionChecker } from '../../../components/templates/permission-checker'
import { useBackofficeUser } from '../../../lib/use-permissions'

export const Route = createFileRoute('/bo/search')({
  component: function Component() {
    const backofficeUser = useBackofficeUser()

    if (!backofficeUser) {
      throw new TechnicalError('There should have been a backoffice user at this point')
    }

    return (
      <PermissionChecker requiredPermissions={['search']}>
        {backofficeUser.type === 'platform' ? (
          <PlatformSearchPage />
        ) : (
          <PartnerSearchPage organization={backofficeUser.organization} />
        )}
      </PermissionChecker>
    )
  },
})
