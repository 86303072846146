import { createFileRoute, Navigate } from '@tanstack/react-router'

export const Route = createFileRoute('/(redirects)/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId')({
  component: () => (
    <Navigate
      replace
      search
      from="/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId"
      to="/bo/contracts/$subscriptionId/detailed-sheet/versions/$versionId"
    />
  ),
})
