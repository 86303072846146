import { memo, useCallback, useMemo, useState, type ComponentProps, type FormEvent, type ReactNode } from 'react'

import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
} from '@mui/material'

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { desktopMediaQuery, largeBackOfficeMediaQuery, useUiContext } from '../../code-only/hooks'
import { useIsOwerflowing } from '../../code-only/hooks/use-is-overflowing'
import { shouldForwardProp } from '../../code-only/styled.js'
import { Avatar } from '../../components'
import { Button, buttonDimensionsPerButtonSize, type ButtonAvatarPlacement } from '../../components/button/index.js'
import { borderStroke, type CompoundIconName } from '../../foundations'
import { borderRadius } from '../../foundations/border-radius-tokens.js'
import { colorTokens } from '../../foundations/color-tokens.js'
import { shadow } from '../../foundations/shadow-tokens.js'
import { spacing } from '../../foundations/spacing-tokens.js'
import { Text } from '../../foundations/text'

export type DialogSize = 'small' | 'medium' | 'large' | 'xlarge'

export type DialogStyle =
  /**
   * The default style, with a neutral look and feel
   */
  | 'base'
  /**
   * A style that indicates the success of an operation, or good news in general
   */
  | 'success'
  /**
   * A style that indicates a dangerous situation
   */
  | 'danger'

export type DialogProps = {
  /**
   * The main content of the dialog
   */
  children: ReactNode
  /**
   * A callback called to close the dialog
   */
  onClose?: (() => void) | undefined
  size: DialogSize
  /**
   * An optinal icon displayed in the header of the dialog
   */
  icon?: CompoundIconName | undefined

  /**
   * An optinal avatar displayed in the header of the dialog
   */
  avatar?: ReactNode | undefined

  /**
   * An optional title displayed in the header of the dialog
   */
  title?: ReactNode | undefined
  /**
   * An optional footer text displayed at the bottom of the dialog
   */
  footerText?: string | undefined
  /** An optional group of buttons displayed in place of the close button */
  topRightButtonGroup?: ReactNode | undefined
  /**
   * An optional semantic style applied to the dialog. Sets default values for some color and icons
   */
  style?: DialogStyle | undefined
  /** Whether the overlay should be blurred */
  blurOverlay?: boolean | undefined
  /** Customize the form's id */

  formId?: string | undefined
  primaryActionLabel?: string | undefined
  primaryActionDisabled?: boolean | undefined
  primaryActionLoading?: boolean | undefined
  primaryActionAvatar?: ReactNode | undefined
  primaryActionAvatarPosition?: ButtonAvatarPlacement | undefined
  onPrimaryAction?: (() => Promise<void> | void) | undefined
  secondaryActionLabel?: string | undefined
  secondaryActionDisabled?: boolean | undefined
  secondaryActionLoading?: boolean | undefined
  onSecondaryAction?: (() => void) | undefined
  isBackoffice?: boolean | undefined
  withoutContentMargin?: boolean | undefined
  tabbar?: ReactNode | undefined
  allowEmptyFooter?: boolean | undefined
}

const desktopDialogSizes: Record<DialogSize, string> = {
  small: '384px',
  medium: '576px',
  large: '768px',
  xlarge: '1464px',
}

/**
 * The buttons are in their own wrapper, because the ordering between buttons and optional text
 * is not the same between mobile and desktop, no matter which flex direction we use.
 */
const DialogFooterButtons = styled.div`
  flex: 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${spacing['40']};
  margin-left: 0 !important; /* workaround an unwanted extra margin added by MuiDialogActions */
  flex-direction: column-reverse;
  ${desktopMediaQuery} {
    flex-direction: row;
  }
`

const FooterButton = memo<
  Omit<ComponentProps<typeof Button>, 'size' | 'fullWidth'> & { isBackoffice?: boolean | undefined }
>(function FooterButton(props) {
  const screenType = useUiContext()
  return (
    <Button
      {...props}
      size={screenType === 'mobile' ? 'large' : props.isBackoffice ? 'small' : 'medium'}
      fullWidth={screenType === 'mobile'}
    />
  )
})

const styleIcons: Record<DialogStyle, CompoundIconName | undefined> = {
  base: undefined,
  success: 'circle-check-regular',
  danger: 'diamond-exclamation-regular',
}

const styleColors: Record<DialogStyle, string | undefined> = {
  base: undefined,
  success: colorTokens['color-bg-success-primary'],
  danger: colorTokens['color-bg-danger-primary'],
}

const DesktopEmptyFooterFiller = styled.div`
  height: ${buttonDimensionsPerButtonSize.medium.height};
`

export const Dialog = memo<DialogProps>(function Dialog(props) {
  const [formId] = useState(props.formId ?? Math.random().toString())
  const screenType = useUiContext()
  const { isOverflowing, ref: contentContainerRef } = useIsOwerflowing()

  const { onPrimaryAction, isBackoffice, tabbar } = props
  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      void onPrimaryAction?.()
      event.stopPropagation()
    },
    [onPrimaryAction],
  )

  const { style = 'base' } = props

  const icon = props.icon ?? (style ? styleIcons[style] : undefined)

  const setupAvatar = useMemo(
    () =>
      props.avatar || icon ? <>{props.avatar || <Avatar icon={icon} size="50" color={styleColors[style]} />}</> : <></>,
    [props.avatar, icon, style],
  )

  return (
    <StyledMuiDialog
      open
      $blurOverlay={props.blurOverlay}
      onClose={props.onClose ?? (() => {})}
      fullScreen={screenType === 'mobile'}
      transitionDuration={
        screenType === 'mobile'
          ? {
              appear: 0,
              exit: 0,
            }
          : undefined
      }
      scroll="paper"
      size={props.size}
      $isOverflowing={isOverflowing}
      $withoutContentMargin={props.withoutContentMargin}
    >
      <DialogTitle>
        {setupAvatar}
        {props.title ? (
          typeof props.title === 'string' ? (
            <Text
              css={css`
                flex-grow: 1;
              `}
              variant="h6"
            >
              {props.title}
            </Text>
          ) : (
            <div
              css={css`
                flex-grow: 1;
                overflow: hidden;
              `}
            >
              {props.title}
            </div>
          )
        ) : null}
        <div
          css={css`
            margin-left: auto;
          `}
        >
          {props.topRightButtonGroup
            ? props.topRightButtonGroup
            : !!props.onClose && (
                <Button variant="secondary" icon="xmark-large-regular" size="small" onClick={props.onClose} />
              )}
        </div>
      </DialogTitle>

      {tabbar ? tabbar : null}

      <MuiDialogContent ref={contentContainerRef}>
        <FormContainer id={formId} onSubmit={handleSubmit} $isOverflowing={isOverflowing}>
          {props.children}
        </FormContainer>
      </MuiDialogContent>

      {!props.allowEmptyFooter || props.primaryActionLabel || props.secondaryActionLabel || props.footerText ? (
        <DialogActions>
          {props.footerText ? (
            <Text
              css={css`
                flex: 1 1;
              `}
              variant="body2"
            >
              {props.footerText}
            </Text>
          ) : null}

          <DialogFooterButtons>
            {props.secondaryActionLabel ? (
              <FooterButton
                isBackoffice={isBackoffice}
                variant="secondary"
                disabled={props.secondaryActionDisabled}
                onClick={props.onSecondaryAction}
                isLoading={props.secondaryActionLoading}
                dataTestId="dialog-secondary-button"
              >
                {props.secondaryActionLabel}
              </FooterButton>
            ) : null}
            {props.primaryActionLabel ? (
              <FooterButton
                avatar={props.primaryActionAvatar}
                avatarPosition={props.primaryActionAvatarPosition}
                isBackoffice={isBackoffice}
                form={formId}
                type="submit"
                variant="primary"
                disabled={props.primaryActionDisabled}
                isLoading={props.primaryActionLoading}
                style={style}
              >
                {props.primaryActionLabel}
              </FooterButton>
            ) : null}
          </DialogFooterButtons>

          {!props.primaryActionLabel && !props.secondaryActionLabel && screenType === 'desktop' ? (
            <DesktopEmptyFooterFiller />
          ) : null}
        </DialogActions>
      ) : null}
    </StyledMuiDialog>
  )
})

const StyledMuiDialog = styled(MuiDialog, { shouldForwardProp })<{
  size: DialogSize
  $blurOverlay?: boolean | undefined
  $withoutContentMargin?: boolean | undefined
  $isOverflowing: boolean
}>`
  & .MuiDialog-container {
    ${({ $blurOverlay = false }) =>
      $blurOverlay
        ? css`
            backdrop-filter: blur(12px);
          `
        : ``}

    ${({ size, $isOverflowing, $withoutContentMargin }) => css`
      & .MuiPaper-root {
        width: 100%;
        box-shadow: ${shadow.bottom[30]};
        border: ${borderStroke[20]} solid ${colorTokens['color-stroke-base-basic']};
        ${desktopMediaQuery} {
          border-radius: ${borderRadius[30]};
          ${size === 'xlarge'
            ? css`
                min-width: calc(100vw - 2 * ${spacing['60']});
                min-height: calc(100vh - 2 * ${spacing['60']});
              `
            : css`
                max-width: ${desktopDialogSizes[size]};
              `}
        }
      }

      & .MuiDialogContent-root {
        display: flex;
        height: 100%;
        padding: ${$withoutContentMargin ? '0' : `0 ${spacing['60']}`};
        border-top: ${$isOverflowing ? `${borderStroke[20]} solid ${colorTokens['color-stroke-base-basic']}` : 'none'};
        border-bottom: ${$isOverflowing
          ? `${borderStroke[20]} solid ${colorTokens['color-stroke-base-basic']}`
          : 'none'};
      }
    `}
  }
`

const FormContainer = styled('form', { shouldForwardProp })<{ $isOverflowing: boolean }>`
  display: flex;
  flex-direction: row;
  min-height: 100%;
  width: 100%;
  padding-top: ${({ $isOverflowing }) => ($isOverflowing ? spacing['60'] : '0')};
  padding-bottom: ${spacing['60']};
`

const DialogTitle = styled.div`
  display: flex;
  padding: ${spacing['60']};
  align-items: center;
  gap: ${spacing['40']};
`

const DialogActions = styled(MuiDialogActions)`
  display: flex;
  padding: ${spacing['60']};
  justify-content: flex-end;
  gap: ${spacing['40']};
  align-items: stretch;
  flex-flow: column wrap;

  ${largeBackOfficeMediaQuery} {
    align-items: center;
    flex-flow: row wrap;
  }
`
