import { createFileRoute } from '@tanstack/react-router'

import { TechnicalError } from '@orus.eu/error'
import PlatformSearchV2Page from '../../../components/pages/backoffice/platform/platform-searchV2-page'
import { PermissionChecker } from '../../../components/templates/permission-checker'
import { useBackofficeUser } from '../../../lib/use-permissions'

export const Route = createFileRoute('/bo/searchV2')({
  component: function Component() {
    const backofficeUser = useBackofficeUser()

    if (!backofficeUser) {
      throw new TechnicalError('There should have been a backoffice user at this point')
    }

    return (
      <PermissionChecker requiredPermissions={['search']}>
        {backofficeUser.type === 'platform' ? <PlatformSearchV2Page /> : null}
      </PermissionChecker>
    )
  },
})
