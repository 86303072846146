import { amountToString, isAmount, rateToPercentString } from '@orus.eu/amount'
import { TechnicalError } from '@orus.eu/error'
import { IntlMessageFormat } from 'intl-messageformat'
import type { ArgumentValue, KeyParameters } from './generated/types'
import es from './translations/es/no_filename.json'
import fr from './translations/fr/no_filename.json'
export type { ArgumentValue, KeyParameters } from './generated/types'

export type TranslationKey = keyof typeof es & keyof typeof fr

export const languages = ['fr', 'es'] as const

export type Language = (typeof languages)[number]

export function isLanguage(value: string): value is Language {
  const languagesStrings: readonly string[] = languages
  return languagesStrings.includes(value)
}

export function assertIsLanguage(value: string): asserts value is Language {
  if (!isLanguage(value)) {
    throw new TechnicalError('Invalid language', { context: { value } })
  }
}

export type Translations = Record<TranslationKey, string>

/**
 * Translations for all languages
 * Note that we don't expose this object directly to force the use of the translate / getIntlMessages
 * functions. This will allow use to control how the translations are used in the future
 * (e.g. value formatting etc...)
 */
const translations: Record<Language, Translations> = {
  es,
  fr,
}

export type ParameterlessTranslationKey = Exclude<TranslationKey, keyof KeyParameters>

export type TranslationKeyParameters<Key extends TranslationKey> = Key extends keyof KeyParameters
  ? KeyParameters[Key]
  : undefined

export type LocalizableMessage<Key extends TranslationKey = TranslationKey> = Key extends ParameterlessTranslationKey
  ? ParameterlessTranslationKey
  : {
      key: Key
      parameters: TranslationKeyParameters<Key>
    }

/**
 * Translates a simple message without any parameters
 * @param key
 * @param language
 */
export function translate(key: ParameterlessTranslationKey, language: Language): string
/**
 * Translates a localizable message that wraps a translation key and its parameters
 * @param localizableMessage
 * @param language
 */
export function translate<Key extends TranslationKey>(
  localizableMessage: LocalizableMessage<Key>,
  language: Language,
): string
/**
 * Translates a message with parameters
 * @param key
 * @param language
 * @param parameters
 */
export function translate<Key extends keyof KeyParameters>(
  key: Key,
  language: Language,
  parameters: KeyParameters[Key],
): string
export function translate<Key extends TranslationKey>(
  firstArg: Key | LocalizableMessage<Key>,
  language: Language,
  thridArg: Record<string, string> | undefined = undefined,
): string {
  if (typeof firstArg === 'string' && !thridArg) {
    // first signature : parameterless translation key, we can just return the key
    return translations[language][firstArg as ParameterlessTranslationKey]
  }

  const { key, parameters } =
    typeof firstArg === 'object'
      ? // second signature : localizable message
        firstArg
      : // thrird signature : key and parameters
        {
          key: firstArg as TranslationKey,
          parameters: thridArg,
        }

  const messageFormat = new IntlMessageFormat(translations[language][key], language)
  return messageFormat.format(formatArgumentValues(parameters)) as string
}

/**
 * Create from the type of translate from @orus.eu/translations, but without the language parameter.
 * Used in contexts where we are going to translate a lot of strings using the
 * same language (like in the hook useTranslate, but not only)
 */
export type TranslateInSpecificLanguageFunction = {
  (key: ParameterlessTranslationKey): string
  <Key extends TranslationKey>(localizableMessage: LocalizableMessage<Key>): string
  <Key extends keyof KeyParameters>(key: Key, parameters: KeyParameters[Key]): string
}

function createTranslateInSpecificLanguageFunction(language: Language): TranslateInSpecificLanguageFunction {
  // @ts-expect-error I don't know how to type this correctly, but cast is ok because we are in a well tested frameork function
  return (key, parameters) => translate(key, language, parameters).replaceAll('\\n', '\n')
}

export const translateIn: Record<Language, TranslateInSpecificLanguageFunction> = {
  es: createTranslateInSpecificLanguageFunction('es'),
  fr: createTranslateInSpecificLanguageFunction('fr'),
}

/**
 * Exposes the translation messages for a given language, to be provided to the react IntlProvider
 * on frontend code.
 */
export function getIntlMessages(language: Language): Record<string, string> {
  return translations[language]
}

function formatArgumentValues(values: Record<string, ArgumentValue> | undefined): Record<string, string> | undefined {
  if (!values) return values
  return Object.fromEntries(Object.entries(values).map(([key, value]) => [key, formatArgumentValue(value)]))
}

function formatArgumentValue(value: ArgumentValue): string {
  switch (typeof value) {
    case 'string':
      return value
    case 'number':
      return value.toFixed(0)
    default:
      return isAmount(value)
        ? amountToString(value, { addCurrency: true, displayDecimals: 'when-amount-has-cents' })
        : rateToPercentString(value, 0, true)
  }
}
