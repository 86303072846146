import { createFileRoute } from '@tanstack/react-router'

import PlatformAdminTimePage from '../../../../components/pages/backoffice/platform/platform-time-admin-page/PlatformAdminTimePage'
import { PermissionChecker } from '../../../../components/templates/permission-checker'

export const Route = createFileRoute('/bo/admin/time')({
  component: () => (
    <PermissionChecker requiredPermissions={['tech.configurations']}>
      <PlatformAdminTimePage />
    </PermissionChecker>
  ),
})
