import { createFileRoute } from '@tanstack/react-router'
import DetailedSheetPage from '../../../../../../../components/pages/backoffice/detailed-sheet-page/DetailedSheetPage'
import { PermissionChecker } from '../../../../../../../components/templates/permission-checker'

export const Route = createFileRoute('/bo/contracts/$subscriptionId/detailed-sheet/versions/$versionId')({
  component: function Component() {
    const { subscriptionId, versionId } = Route.useParams()
    return (
      <PermissionChecker requiredPermissions={['contracts.read']}>
        <DetailedSheetPage subscriptionId={subscriptionId} versionId={versionId} endorsementId={undefined} />
      </PermissionChecker>
    )
  },
})
