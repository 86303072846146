import { createFileRoute, Navigate } from '@tanstack/react-router'

export const Route = createFileRoute('/(redirects)/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet')({
  component: () => (
    <Navigate
      replace
      search
      from="/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet"
      to="/bo/subscriptions/$subscriptionId/detailed-sheet"
    />
  ),
})
