import { memo } from 'react'

export const OrusHorizontalLogoImage = memo<{
  width: number
  height: number
  color?: string | undefined
  className?: string | undefined
}>(function OrusHorizontalLogo(props) {
  const { width, height, color, className } = props

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 442 137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M234.01 37.1954V98.4407C234.01 110.433 237.116 119.23 242.988 125.202C248.88 131.059 257.189 134.118 268.231 134.118C275.421 134.118 281.657 133.19 286.974 131.384C292.391 129.543 296.95 127.2 300.687 124.379L304.342 121.62V134.662H331.569V37.1954H303.021V102.234C303.021 103.772 302.446 105.267 301.307 106.3C298.936 108.45 295.972 110.545 292.971 111.923C289.114 113.694 285.028 114.579 280.739 114.579C275.094 114.579 270.423 113.286 267.117 110.326L267.072 110.286L267.03 110.243C263.835 107.065 262.466 102.355 262.466 96.6047V37.1954H234.01Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M154.427 36.6287V132.962H182.292L182.268 73.8104L183.356 73.1507C187.781 70.468 193.212 68.1053 199.608 66.0409C205.367 64.0613 211.139 62.7401 216.922 62.0831V37.5525C211.653 38.1897 206.375 39.4157 201.084 41.2358L201.063 41.243L201.042 41.2499C194.871 43.24 189.782 45.4721 185.737 47.9242L182.26 50.0322L182.288 36.6287H154.427Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M393.768 37.3088C378.202 37.3088 367.307 39.8854 360.646 44.6363L360.64 44.641C354.197 49.2019 350.935 55.7528 350.935 64.6222C350.935 72.3524 353.056 77.8492 356.957 81.5102C361.301 85.3139 367.755 88.7875 376.469 91.8564C384.111 94.4359 390.101 96.3996 394.447 97.7507C399.033 99.1764 402.608 101.723 402.608 106.034C402.608 107.697 402.131 109.268 400.99 110.517C399.879 111.732 398.352 112.409 396.687 112.742C393.84 113.337 389.401 113.605 383.515 113.605C378.431 113.605 372.692 113.043 366.31 111.939C361.211 111.057 356.561 109.863 352.37 108.346V128.538C356.097 129.854 360.976 130.998 367.054 131.947C373.897 133.015 381.161 133.552 388.846 133.552C403.545 133.552 414.289 130.991 421.401 126.191C428.395 121.469 431.884 114.779 431.884 105.83C431.884 100.476 431.029 96.2154 429.444 92.9415C427.894 89.7383 425.229 86.9122 421.284 84.5067L421.262 84.493L421.24 84.4788C417.252 81.9195 411.53 79.4772 403.973 77.1951L403.956 77.1899L403.939 77.1845C396.707 74.8765 391.227 73.2628 387.474 72.3292L387.437 72.32L387.4 72.3096C385.292 71.7103 383.465 70.8411 382.139 69.5688C380.748 68.235 380.006 66.5458 380.006 64.6222C380.006 62.8284 380.707 61.2463 382.101 60.0816C383.391 59.003 385.119 58.4115 387.015 58.1057C390.747 57.3947 395.195 57.0515 400.329 57.0515C404.446 57.0515 409.017 57.4748 414.032 58.3063C417.807 58.9322 421.3 59.8337 424.503 61.02V41.4131C421.066 40.4256 416.792 39.5211 411.659 38.7087C405.764 37.7756 399.8 37.3088 393.768 37.3088Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.9607 2.62872C44.6959 2.62872 29.0195 7.85198 18.3845 17.9164C7.8316 27.903 2.27832 44.627 2.27832 68.7021C2.27832 91.3651 7.8073 107.589 18.4052 117.876C29.0416 128.199 44.7099 133.551 66.9607 133.551C88.0916 133.551 104.889 128.19 115.516 117.876C126.114 107.589 131.643 91.3651 131.643 68.7021C131.643 44.627 126.09 27.903 115.537 17.9164C104.911 7.86097 88.1052 2.62872 66.9607 2.62872ZM39.4746 36.2317C45.2904 29.3798 55.4406 26.2474 66.9607 26.2474C78.3357 26.2474 88.3554 29.3757 94.0382 36.2332C99.7776 42.8615 102.366 53.8969 102.366 68.7021C102.366 82.3776 99.7423 92.9275 94.0689 99.9097C88.3934 106.927 78.3606 110.137 66.9607 110.137C55.4263 110.137 45.2703 106.929 39.4588 99.9297L39.4417 99.9091L39.4251 99.8881C33.9025 92.9089 31.3501 82.3688 31.3501 68.7021C31.3501 53.8902 33.8732 42.8586 39.4716 36.2352L39.4746 36.2317Z"
        fill={color}
      />
    </svg>
  )
})
