import { LicenseInfo } from '@mui/x-license'

/**
 * This license key is a Pro license valid from 2025-03-18 to 2026-03-18 included.
 * It allows access to some paid components.
 * It's not private as it's in the public bundle, so it's hardcoded here.
 *
 * Note that there's no urge to update the license before it expires:
 *
 * - Production usage is perpetual, so once expired, it still works fine on production
 * - In development, however, a watermark is added
 *
 */
const MUI_X_LICENSE_KEY =
  'c4b5da111168d6d10db1ac1a5c8d65c2Tz0xMDk5MjgsRT0xNzczOTY0Nzk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI='

let licensesSetup = false

export function setupLicenses(): void {
  if (licensesSetup) return

  LicenseInfo.setLicenseKey(MUI_X_LICENSE_KEY)
  licensesSetup = true
}
