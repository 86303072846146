import styled from '@emotion/styled'
import { memo } from 'react'
import { PIXELS_PER_ICON_SIZE, type IconSize } from '../icon'
import { illustration, type IllustrationName } from './list'

export type IllustrationProps = {
  name: IllustrationName

  /**
   * Defaults to {@link DEFAULT_SIZE}
   */
  size?: IconSize | undefined
}

export const Illustration = memo<IllustrationProps>(function Illustration({ name, size = DEFAULT_SIZE }) {
  return <StyledIllustration $size={size} src={illustration[name]} />
})

const StyledIllustration = styled.img<{ $size: IconSize }>`
  width: ${(props) => PIXELS_PER_ICON_SIZE[props.$size]};
  height: ${(props) => PIXELS_PER_ICON_SIZE[props.$size]};
`

const DEFAULT_SIZE: IconSize = '130'
