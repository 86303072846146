import { createFileRoute } from '@tanstack/react-router'

import { InvoiceDetailPage } from '../../../../components/pages/backoffice/platform/invoice-detail-page'
import { PermissionChecker } from '../../../../components/templates/permission-checker'

export const Route = createFileRoute('/bo/invoicing/$invoiceId')({
  component: () => (
    <PermissionChecker requiredPermissions={['invoices.read']}>
      <InvoiceDetailPage />
    </PermissionChecker>
  ),
})
