import { createFileRoute } from '@tanstack/react-router'

import { PendingSubscriptionPaymentPage } from '../../../../../components/pages/backoffice/common/backoffice-pending-subscriptions/pending-subscription-payment-page'
import { PermissionChecker } from '../../../../../components/templates/permission-checker'

export const Route = createFileRoute('/bo/subscriptions/$subscriptionId/payment')({
  component: function Compoonent() {
    const { subscriptionId } = Route.useParams()

    return (
      <PermissionChecker requiredPermissions={['contracts.sign']}>
        <PendingSubscriptionPaymentPage subscriptionId={subscriptionId} />
      </PermissionChecker>
    )
  },
})
