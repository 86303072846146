import styled from '@emotion/styled'
import { CircularProgress } from '@mui/material'
import { memo } from 'react'
import { colorTokens } from '../../foundations'
import { PIXELS_PER_ICON_SIZE, type IconSize } from '../../foundations/icon/sizes.js'

export type SpinnerProps = {
  size?: IconSize
  className?: string | undefined
  strokeColor?: string
}

export const Spinner = memo<SpinnerProps>(function Spinner({ size = '70', className, strokeColor }) {
  return (
    <StyledSpinner
      size={PIXELS_PER_ICON_SIZE[size] as IconSize}
      {...(className ? { className } : {})}
      {...(strokeColor ? { strokeColor } : {})}
    />
  )
})

const StyledSpinner = styled(CircularProgress, {
  shouldForwardProp: (prop) => prop !== 'strokeColor',
})<SpinnerProps>`
  width: 100px;

  & .MuiCircularProgress-circle {
    stroke-linecap: round;
    stroke: ${({ strokeColor }) => (strokeColor ? strokeColor : colorTokens['color-fg-base-active-inverse'])};
  }
`
