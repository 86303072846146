import { createFileRoute, Navigate } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/(redirects)/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet',
)({
  component: () => (
    <Navigate
      replace
      search
      from="/bak/contracts/$subscriptionId/endorsement/$endorsementId/detailed-sheet"
      to="/bo/contracts/$subscriptionId/endorsements/$endorsementId/detailed-sheet"
    />
  ),
})
