import { createFileRoute } from '@tanstack/react-router'

import PlatformAdminImaPage from '../../../../components/pages/backoffice/platform/platform-admin-ima-page'
import { PermissionChecker } from '../../../../components/templates/permission-checker'

export const Route = createFileRoute('/bo/admin/ima')({
  component: () => (
    <PermissionChecker requiredPermissions={['tech.configurations']}>
      <PlatformAdminImaPage />
    </PermissionChecker>
  ),
})
