import { createFileRoute } from '@tanstack/react-router'

import { TechnicalError } from '@orus.eu/error'
import PartnerUserPage from '../../../../components/pages/backoffice/partners/partners-user-page'
import PlatformUserPage from '../../../../components/pages/backoffice/platform/platform-user-page'
import { PermissionChecker } from '../../../../components/templates/permission-checker'
import { useBackofficeUser } from '../../../../lib/use-permissions'

export const Route = createFileRoute('/bo/users/$userId')({
  component: function Component() {
    const { userId } = Route.useParams()
    const backofficeUser = useBackofficeUser()

    if (!backofficeUser) {
      throw new TechnicalError('There should have been a backoffice user at this point')
    }

    return (
      <PermissionChecker requiredPermissions={['users.read']}>
        {backofficeUser.type === 'platform' ? (
          <PlatformUserPage />
        ) : (
          <PartnerUserPage userId={userId} organization={backofficeUser.organization} />
        )}
      </PermissionChecker>
    )
  },
})
