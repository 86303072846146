import { createFileRoute, Navigate } from '@tanstack/react-router'

export const Route = createFileRoute('/(redirects)/bak/v2-pending-subscriptions/$subscriptionId/')({
  component: () => (
    <Navigate
      replace
      search
      from="/bak/v2-pending-subscriptions/$subscriptionId"
      to="/bo/subscriptions/$subscriptionId"
    />
  ),
})
