import advisor_big from '../../code-only/assets/illustrations_iconpack_svg/advisor_big.svg'
import bank from '../../code-only/assets/illustrations_iconpack_svg/bank.svg'
import box_big from '../../code-only/assets/illustrations_iconpack_svg/box_big.svg'
import broken_small from '../../code-only/assets/illustrations_iconpack_svg/broken_small.svg'
import calculator_big from '../../code-only/assets/illustrations_iconpack_svg/calculator_big.svg'
import chainlink_big from '../../code-only/assets/illustrations_iconpack_svg/chainlink_big.svg'
import chrono_big from '../../code-only/assets/illustrations_iconpack_svg/chrono_big.svg'
import chrono_dark_big from '../../code-only/assets/illustrations_iconpack_svg/chrono_dark_big.svg'
import claim_small from '../../code-only/assets/illustrations_iconpack_svg/claim_small.svg'
import cyber_small from '../../code-only/assets/illustrations_iconpack_svg/cyber_small.svg'
import derogation_big from '../../code-only/assets/illustrations_iconpack_svg/derogation_big.svg'
import derogation_small from '../../code-only/assets/illustrations_iconpack_svg/derogation_small.svg'
import email_small from '../../code-only/assets/illustrations_iconpack_svg/email_small.svg'
import embedded_big from '../../code-only/assets/illustrations_iconpack_svg/embedded_big.svg'
import folder_big from '../../code-only/assets/illustrations_iconpack_svg/folder_big.svg'
import guest_big from '../../code-only/assets/illustrations_iconpack_svg/guest_big.svg'
import guide_big from '../../code-only/assets/illustrations_iconpack_svg/guide_big.svg'
import help_big from '../../code-only/assets/illustrations_iconpack_svg/help_big.svg'
import hourglass_big from '../../code-only/assets/illustrations_iconpack_svg/hourglass_big.svg'
import im_small from '../../code-only/assets/illustrations_iconpack_svg/im_small.svg'
import invoice_big from '../../code-only/assets/illustrations_iconpack_svg/invoice_big.svg'
import lodger_small from '../../code-only/assets/illustrations_iconpack_svg/lodger_small.svg'
import login_big from '../../code-only/assets/illustrations_iconpack_svg/login_big.svg'
import man_office_worker from '../../code-only/assets/illustrations_iconpack_svg/man_office_worker.svg'
import manager_small from '../../code-only/assets/illustrations_iconpack_svg/manager_small.svg'
import map_big from '../../code-only/assets/illustrations_iconpack_svg/map_big.svg'
import message_small from '../../code-only/assets/illustrations_iconpack_svg/message_small.svg'
import mrp_big from '../../code-only/assets/illustrations_iconpack_svg/mrp_big.svg'
import mrp_small from '../../code-only/assets/illustrations_iconpack_svg/mrp_small.svg'
import mutuelle_big from '../../code-only/assets/illustrations_iconpack_svg/mutuelle_big.svg'
import mutuelle_small from '../../code-only/assets/illustrations_iconpack_svg/mutuelle_small.svg'
import office_small from '../../code-only/assets/illustrations_iconpack_svg/office_small.svg'
import partner_big from '../../code-only/assets/illustrations_iconpack_svg/partner_big.svg'
import past_small from '../../code-only/assets/illustrations_iconpack_svg/past_small.svg'
import pe_small from '../../code-only/assets/illustrations_iconpack_svg/pe_small.svg'
import phone_small from '../../code-only/assets/illustrations_iconpack_svg/phone_small.svg'
import pj_big from '../../code-only/assets/illustrations_iconpack_svg/pj_big.svg'
import pj_small from '../../code-only/assets/illustrations_iconpack_svg/pj_small.svg'
import podium_big from '../../code-only/assets/illustrations_iconpack_svg/podium_big.svg'
import pool_small from '../../code-only/assets/illustrations_iconpack_svg/pool_small.svg'
import premium_big from '../../code-only/assets/illustrations_iconpack_svg/premium_big.svg'
import prevoyance_big from '../../code-only/assets/illustrations_iconpack_svg/prevoyance_big.svg'
import prevoyance_small from '../../code-only/assets/illustrations_iconpack_svg/prevoyance_small.svg'
import rcal_small from '../../code-only/assets/illustrations_iconpack_svg/rcal_small.svg'
import rcd_big from '../../code-only/assets/illustrations_iconpack_svg/rcd_big.svg'
import rcd_small from '../../code-only/assets/illustrations_iconpack_svg/rcd_small.svg'
import rce_small from '../../code-only/assets/illustrations_iconpack_svg/rce_small.svg'
import rcp_big from '../../code-only/assets/illustrations_iconpack_svg/rcp_big.svg'
import rcp_small from '../../code-only/assets/illustrations_iconpack_svg/rcp_small.svg'
import rcpr_big from '../../code-only/assets/illustrations_iconpack_svg/rcpr_big.svg'
import rct_small from '../../code-only/assets/illustrations_iconpack_svg/rct_small.svg'
import referral_big from '../../code-only/assets/illustrations_iconpack_svg/referral_big.svg'
import search_big from '../../code-only/assets/illustrations_iconpack_svg/search_big.svg'
import shield_crystal_small from '../../code-only/assets/illustrations_iconpack_svg/shield_crystal_small.svg'
import shield_red_small from '../../code-only/assets/illustrations_iconpack_svg/shield_red_small.svg'
import tdmi_small from '../../code-only/assets/illustrations_iconpack_svg/tdmi_small.svg'
import umbrella_big from '../../code-only/assets/illustrations_iconpack_svg/umbrella_big.svg'
import welcome_big from '../../code-only/assets/illustrations_iconpack_svg/welcome_big.svg'
import world_small from '../../code-only/assets/illustrations_iconpack_svg/world_small.svg'

export const illustration = {
  advisor_big,
  bank,
  box_big,
  broken_small,
  calculator_big,
  chainlink_big,
  chrono_big,
  chrono_dark_big,
  claim_small,
  cyber_small,
  derogation_big,
  derogation_small,
  email_small,
  embedded_big,
  folder_big,
  guest_big,
  guide_big,
  help_big,
  hourglass_big,
  im_small,
  invoice_big,
  lodger_small,
  login_big,
  man_office_worker,
  manager_small,
  map_big,
  message_small,
  mrp_big,
  mrp_small,
  mutuelle_big,
  mutuelle_small,
  office_small,
  partner_big,
  past_small,
  pe_small,
  phone_small,
  pj_big,
  pj_small,
  podium_big,
  pool_small,
  premium_big,
  prevoyance_big,
  prevoyance_small,
  rcal_small,
  rcd_big,
  rcd_small,
  rce_small,
  rcp_big,
  rcp_small,
  rcpr_big,
  rct_small,
  referral_big,
  search_big,
  shield_crystal_small,
  shield_red_small,
  tdmi_small,
  umbrella_big,
  welcome_big,
  world_small,
} as const

export type IllustrationName = keyof typeof illustration
