import {
  type FrontEndTrackingMessage,
  type ViewedMessage,
} from '@orus.eu/backend/src/services/tracking/tracking-messages'
import { ensureError } from '@orus.eu/error'
import { isFailure } from '@orus.eu/result'
import { useMatches } from '@tanstack/react-router'
import { useEffect, useRef } from 'react'
import { trpc } from '../../client'
import { isProductionEnvironment } from '../env-utils'
import { assert } from '../errors'
import { ExclusiveOperationsQueue } from '../exclusive-operations-queue'
import { logger } from '../logger'
import { getPageNameAndResourceIdFromRoute, type PathParamName, type RouteId } from './viewed-event-util'

export function useTracking(ignoredRouteIds: Set<string>): void {
  const leafMatch = useMatches({
    select: (matches) => matches.at(-1),
  })
  const lastPageViewRef = useRef<{ pathname: string; updatedAt: number } | undefined>(undefined)

  useEffect(() => {
    const alreadyTracked =
      lastPageViewRef.current &&
      leafMatch &&
      lastPageViewRef.current.updatedAt === leafMatch.updatedAt &&
      lastPageViewRef.current.pathname === leafMatch.pathname

    if (
      !leafMatch ||
      leafMatch.globalNotFound ||
      leafMatch.status !== 'success' ||
      ignoredRouteIds.has(leafMatch.id) ||
      alreadyTracked
    ) {
      return
    }

    lastPageViewRef.current = {
      pathname: leafMatch.pathname,
      updatedAt: leafMatch.updatedAt,
    }
    handlePageView({
      routeId: leafMatch.routeId,
      path: leafMatch.pathname,
      params: leafMatch.params,
    })
  }, [leafMatch, ignoredRouteIds])
}

export function sendMessage(message: FrontEndTrackingMessage): void {
  sendMessageToBackend(message)
  sendMessageToGTM(message)
}

function handlePageView({
  routeId,
  path,
  params,
}: {
  routeId: RouteId
  path: string
  params: Partial<Record<PathParamName, string>>
}): void {
  const parsingResult = getPageNameAndResourceIdFromRoute({ routeId, params })
  if (isFailure(parsingResult)) {
    // cannot send the tracking event when the parsing has failed
    return
  }
  const { pageName, resourceId } = parsingResult.output
  const message: ViewedMessage = {
    event: 'viewed',
    name: pageName,
    resourceId,
    title: document.title,
    // We put only the path on purpose to avoir accidentally transmitting sensitive data in query string or hash.
    // If some specific attributes have to be sent, they have to be processed another way.
    // The parameter is still called "url" for consistency with the pages from the public site
    url: path,
  }
  sendMessage(message)
}

const backendTrackingQueue = new ExclusiveOperationsQueue()
function sendMessageToBackend(message: FrontEndTrackingMessage) {
  backendTrackingQueue
    .run(async () => {
      await trpc.tracking.sendTrackingMessage.mutate(message)
    })
    .catch((err: unknown) => {
      logger.warning('Error while sending tracking message to backend', {
        errStack: ensureError(err).stack ?? null,
      })
    })
}

function sendMessageToGTM(message: FrontEndTrackingMessage): void {
  const { event, ...properties } = message

  // The typescript magic we do for tracking message combined by the type transformation
  // done by trpc, gives a type that's wider than a string.
  // I didn't fully understand it, but I guess we've hit an edge case.
  // So we need this assertion to keep the code compiling
  assert(typeof event === 'string', 'event must be a string')

  let eventName: string = event

  if (event === 'viewed' && 'name' in properties) {
    // special treatement of the Viewed event : we want to name of the event to include the name of the page
    eventName = 'Viewed ' + properties['name']
  }

  const gtmEvent = { event: eventName, ...properties }

  if (isProductionEnvironment()) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(gtmEvent)
  } else {
    logger.info('In production, we would have sent this event to GTM: ', gtmEvent)
  }
}
