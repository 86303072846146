import { createFileRoute } from '@tanstack/react-router'

import { TechnicalError } from '@orus.eu/error'
import PartnerUsersPage from '../../../components/pages/backoffice/partners/partners-users-page'
import PlatformUsersPage from '../../../components/pages/backoffice/platform/platform-users-page'
import { PermissionChecker } from '../../../components/templates/permission-checker'
import { useBackofficeUser } from '../../../lib/use-permissions'

export const Route = createFileRoute('/bo/users/')({
  component: function Component() {
    const backofficeUser = useBackofficeUser()

    if (!backofficeUser) {
      throw new TechnicalError('There should have been a backoffice user at this point')
    }

    return (
      <PermissionChecker requiredPermissions={['users.read']}>
        {backofficeUser.type === 'platform' ? (
          <PlatformUsersPage />
        ) : (
          <PartnerUsersPage organization={backofficeUser.organization} />
        )}
      </PermissionChecker>
    )
  },
})
