import { createRouter } from '@tanstack/react-router'

import { NotFound } from './components/organisms/not-found'
import { RootErrorBoundary } from './components/templates/error-boundary'
import { routeTree } from './routeTree.gen'

export const router = createRouter({
  routeTree,
  defaultNotFoundComponent: () => <NotFound />,
  defaultErrorComponent: RootErrorBoundary,
})

declare module '@tanstack/react-router' {
  // eslint-disable-next-line @orus.eu/no-interfaces
  interface Register {
    router: typeof router
  }
}
